import { useCallback, useMemo, useState } from 'react';

type ReturnType = [number, () => void];

export default function useRetry(): ReturnType {
  // If multiple components are re-fetching the same query, and using this hook,
  // and if we start the key with 0, then all components will have the same key.
  // So, if one component re-fetches, the key will be 1, when the other component re-fetches,
  // the key will be 1 for that component as well and relay will not fetch the query again
  // for that second component since the key will be the same for the same query.
  // We want to make sure that the key is initialized with a different value
  // for each component to avoid one component's retry
  // causing another component's retry not to work.
  // So we are initializing and updating it with a random number.
  const [fetchKey, setFetchKey] = useState(Math.random());
  const retry = useCallback(() => setFetchKey(Math.random()), []);
  return useMemo(() => [fetchKey, retry], [fetchKey, retry]);
}
