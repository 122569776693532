import styled from '@emotion/styled';
import type { ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { graphql, useQuery } from 'relay-hooks';

import { FeedbackComponent } from 'ms-components/Feedback/FeedbackComponent';
import {
  CheckInTaskCompletionScreen,
  PointsCard,
} from 'ms-experiments/gamification/TaskCompletionScreen';
import {
  Card,
  CardDeck,
} from 'ms-experiments/gamification/TaskCompletionScreen/primitives';
import {
  PageTimeErrorThrower,
  PageTimeRecorder,
} from 'ms-helpers/PageTimeTracker';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import {
  SkillProficiencyIndicator,
  SkillProficiencyIndicatorLoading,
  SkillProficiencyIndicatorLocked,
} from 'ms-pages/Lantern/components/SkillProficiencyIndicator';
import StudentSkillsDemonstrated, {
  StudentSingleSkillDemonstrated,
} from 'ms-pages/Lantern/components/StudentSkillsDemonstrated';
import { useSubstrandProficiency } from 'ms-pages/Lantern/components/useSubstrandProficiency';
import { Button } from 'ms-pages/Lantern/primitives/Button';
import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';
import Main from 'ms-pages/Lantern/primitives/Main';
import { HStack, VSpacer, VStack } from 'ms-pages/Lantern/primitives/Stack';
import {
  BodyM,
  HeadingL,
  HeadingM,
  HeadingS,
} from 'ms-pages/Lantern/primitives/Typography';
import {
  getCheckInStrandDiagnosticUrl,
  getStudentSkillUrl,
} from 'ms-pages/Lantern/utils/urls';
import { useStudentContext } from 'ms-pages/Lantern/views/Student';
import { CheckInFooterWrapper } from 'ms-pages/Lantern/views/Student/CheckIn/CheckInFooterWrapper';
import StudentSkillsMapCurriculumStrand from 'ms-pages/Lantern/views/Student/StudentSkillsMap/StudentSkillsMapCurriculum/StudentSkillsMapCurriculumStrand';
import { PROFICIENCY_INDICATOR_SIZE } from 'ms-pages/Lantern/views/Student/StudentSkillsMap/StudentSkillsMapCurriculum/StudentSkillsMapCurriculumStrandSubstrands';
import SubstrandProficiencyIndicator from 'ms-pages/Lantern/views/Student/StudentSkillsMap/SubstrandProficiencyIndicator';
import {
  ContainerVStack,
  GridItemTitle,
  GroupVStack,
} from 'ms-pages/Lantern/views/Student/StudentSkillsMap/styles';
import {
  isStrandLocked,
  getGrowthStartDate,
} from 'ms-pages/Lantern/views/Student/StudentSkillsMap/utils';
import Milo from 'ms-pages/StudentDashboard/SunflowerStudentDashboard/Milo';
import { HSpacer } from 'ms-ui-primitives/Stack';
import { InvariantViolation, NotFoundError } from 'ms-utils/app-logging';
import getProficiencyForOutcome from 'ms-utils/getProficiencyForOutcome';
import { SANA_PROFICIENCY_BANDS } from 'ms-utils/masteryLevel';
import { getStartOfWeek } from 'ms-utils/time';
import { assertUnreachable } from 'ms-utils/typescript-utils';
import { dashboardRedirectUrl } from 'ms-utils/urls';

import RecommendationsSlide from './RecommendationsSlide';
import type {
  CheckInCompleteQuery,
  CheckInCompleteQueryResponse,
  LanternCheckInType,
} from './__generated__/CheckInCompleteQuery.graphql';
import type {
  CheckInCompleteSubstrandsUserStatusesQuery,
  CheckInCompleteSubstrandsUserStatusesQueryResponse,
} from './__generated__/CheckInCompleteSubstrandsUserStatusesQuery.graphql';
import finalSlide from './assets/final_slide.svg';
import { BottomSeparator } from './styles';
import { CheckInHeader } from '../CheckInHeader';
import CheckInProgress from '../CheckInProgress';

type SkillLevelledUp = {
  id: string;
  title: string;
};
type SkillsLevelledUp = ReadonlyArray<SkillLevelledUp>;
type Checkin = NonNullable<CheckInCompleteQueryResponse['lantern']['checkIn']>;
export type Skill = NonNullable<Checkin['gradedSkill']>['skill'];
export type SkillsDemonstrated = Checkin['skillsDemonstrated'];
export default function CheckInComplete({ checkInId }: { checkInId: string }) {
  const { props, error } = useQuery<CheckInCompleteQuery>(
    graphql`
      query CheckInCompleteQuery(
        $checkInId: ID!
        $previewingWithProblemData: Boolean!
        $growthPeriod: Int!
      ) {
        lantern {
          checkIn(id: $checkInId) {
            type
            numberOfQuestions
            questionsCompleted
            questionsCorrect
            endedAt
            startedAt
            strand {
              id
              title
              substrands {
                id
                title
                badgeUrl
                strand {
                  id
                }
                gradeSubstrands {
                  id
                  gradeStrand {
                    grade {
                      id
                      order
                    }
                  }
                  outcomes {
                    id
                    title
                  }
                }
              }
            }
            substrand {
              id
              badgeUrl
              title
              strand {
                id
              }
            }
            gradedSkill(
              previewingWithProblemData: $previewingWithProblemData
              growthPeriod: $growthPeriod
            ) {
              skill {
                id
                title
                substrand {
                  id
                  title
                  badgeUrl
                }
                outcome {
                  id
                  gradeSubstrand {
                    substrand {
                      id
                      strand {
                        id
                      }
                    }
                  }
                }
              }
              proficiencyAfter
            }
            skillsDemonstrated {
              id
              title
            }
            skillsLevelledUp(
              previewingWithProblemData: $previewingWithProblemData
              growthPeriod: $growthPeriod
            ) {
              id
              title
            }
          }
        }
      }
    `,
    {
      checkInId,
      previewingWithProblemData: false,
      growthPeriod: 120,
    },
  );
  if (error != null) {
    return (
      <PageTimeErrorThrower
        pageName="StudentCheckInComplete"
        componentName="StudentCheckInComplete"
        error={error}
      />
    );
  }
  if (props == null) return <LoadingSpinner />;
  const { checkIn } = props.lantern;
  if (checkIn == null) {
    throw new NotFoundError('CheckIn not found');
  }
  const skillInfo = (si => {
    if (si == null) return null;
    const {
      skill: { id, title, substrand, outcome },
      proficiencyAfter: proficiency,
    } = si;
    return { id, title, substrand, outcome, proficiency };
  })(checkIn.gradedSkill);
  return (
    <PageTimeRecorder
      pageName="StudentSkillsMapCurriculum"
      componentName="StudentSkillsMapCurriculumStrands"
    >
      <CheckInCompletePresentational
        checkInId={checkInId}
        checkInType={checkIn.type}
        numberOfQuestions={checkIn.numberOfQuestions}
        questionsCompleted={checkIn.questionsCompleted}
        questionsCorrect={checkIn.questionsCorrect}
        skillsDemonstrated={checkIn.skillsDemonstrated.map(({ id, title }) => ({
          id,
          title,
        }))}
        skillsLevelledUp={checkIn.skillsLevelledUp.map(({ id, title }) => ({
          id,
          title,
        }))}
        substrandId={
          checkIn.gradedSkill?.skill.outcome.gradeSubstrand?.substrand.id ||
          checkIn.substrand?.id ||
          checkIn.strand?.substrands[0]?.id
        }
        substrand={checkIn.substrand}
        skillInfo={skillInfo}
        strand={checkIn.strand}
        checkInEndedAt={checkIn.endedAt}
        checkInStartedAt={checkIn.startedAt}
      />
    </PageTimeRecorder>
  );
}
type Strand = Checkin['strand'];
type Substrand = Checkin['substrand'];
type CheckInEndedAt = Checkin['endedAt'];
type CheckInStartedAt = Checkin['startedAt'];
export type SkillInfo = Skill & {
  proficiency: number;
};
type CheckInCompletePresentationalProps = {
  checkInType: LanternCheckInType;
  numberOfQuestions: number;
  questionsCompleted: number;
  questionsCorrect: number;
  skillInfo?: SkillInfo | null | undefined;
  checkInId: string;
  skillsDemonstrated: SkillsDemonstrated;
  skillsLevelledUp: SkillsLevelledUp;
  substrandId?: string | null | undefined;
  strand: Strand;
  substrand: Substrand;
  checkInEndedAt: CheckInEndedAt;
  checkInStartedAt: CheckInStartedAt;
  points?: number;
};
export function CheckInCompletePresentational({
  checkInType,
  numberOfQuestions,
  questionsCompleted,
  questionsCorrect,
  skillInfo,
  skillsDemonstrated,
  skillsLevelledUp,
  substrand,
  substrandId,
  checkInId,
  strand,
  checkInEndedAt,
  checkInStartedAt,
  // Points have been defaulted to `0` while back-end work is still pending. We
  // expect this field to be non-nullable upon work being completed.
  // See: https://app.clickup.com/t/6924803/ELE-92
  points = 0,
}: CheckInCompletePresentationalProps) {
  const { trackStructEvent } = useSnowplow();
  // Track the number of skills the student has discovered so that we can
  // compare this to the number of check-ins with no skills discovered.
  // That is, we want to also know how often students are doing check-ins
  // without demonstrating any new understanding.
  useEffect(() => {
    trackStructEvent({
      category: 'student_check_in',
      action: 'discovered_skills',
      value: skillsDemonstrated.length,
    });
  }, [skillsDemonstrated.length, trackStructEvent]);
  switch (checkInType) {
    case 'STRAND_DIAGNOSTIC_CHECKIN': {
      return (
        <StrandDiagnosticCheckInComplete
          checkInId={checkInId}
          numberOfQuestions={numberOfQuestions}
          questionsCompleted={questionsCompleted}
          questionsCorrect={questionsCorrect}
          strand={strand}
          checkInEndedAt={checkInEndedAt}
          points={points}
        />
      );
    }
    case 'STRAND_GROWTH_CHECKIN':
    case 'HISTORIC_GROWTH_CHECKIN': {
      return (
        <GrowthCheckInComplete
          checkInId={checkInId}
          questionsCompleted={questionsCompleted}
          questionsCorrect={questionsCorrect}
          skillsDemonstrated={skillsDemonstrated}
        />
      );
    }
    case 'SUBSTRAND_GROWTH_CHECKIN': {
      return (
        <>
          {
            // These are nullable in Props, however they will never be `null`
            // for `SUBSTRAND_GROWTH_CHECKIN` type.
            substrand != null && substrandId != null && (
              <SubstrandGrowthCheckInComplete
                checkInId={checkInId}
                questionsCompleted={questionsCompleted}
                questionsCorrect={questionsCorrect}
                skillsDemonstrated={skillsDemonstrated}
                substrandId={substrandId}
                substrand={substrand}
                checkInStartedAt={checkInStartedAt}
                points={points}
              />
            )
          }
        </>
      );
    }
    case 'SKILL_CHECKIN': {
      if (skillInfo == null) {
        throw new Error(`Skill can not be null in the skill check-in`);
      }
      return (
        <SkillCheckInComplete
          questionsCompleted={questionsCompleted}
          questionsCorrect={questionsCorrect}
          skillsLevelledUp={skillsLevelledUp}
          skillInfo={skillInfo}
          checkInId={checkInId}
          points={points}
        />
      );
    }
    case 'PRE_TOPIC_TEST_CHECKIN':
    case 'POST_TOPIC_TEST_CHECKIN':
    case 'HISTORIC_DIAGNOSTIC_CHECKIN':
    case 'ALL_STRANDS_DIAGNOSTIC_CHECKIN': {
      return (
        <DiagnosticCheckInComplete
          checkInId={checkInId}
          questionsCompleted={questionsCompleted}
          questionsCorrect={questionsCorrect}
          skillsDemonstrated={skillsDemonstrated}
        />
      );
    }
    default: {
      assertUnreachable(
        checkInType,
        `Unknown check-in type: ${JSON.stringify(checkInType)}`,
      );
    }
  }
}
function DiagnosticCheckInComplete({
  questionsCompleted,
  questionsCorrect,
  skillsDemonstrated,
  checkInId,
}: {
  questionsCompleted: number;
  questionsCorrect: number;
  skillsDemonstrated: SkillsDemonstrated;
  checkInId: string;
}) {
  const [slide, setSlide] = useState(1);
  const { trackStructEvent } = useSnowplow();
  // Tracking which slide a student has seen and any other
  // side effects
  useEffect(() => {
    if (slide === 1) {
      trackStructEvent({
        category: 'student_diagnostic_check_in_success',
        action: 'seen_slide_1',
      });
    }
    if (slide === 2) {
      trackStructEvent({
        category: 'student_diagnostic_check_in_success',
        action: 'seen_slide_2',
      });
    }
    if (slide === 3) {
      trackStructEvent({
        category: 'student_diagnostic_check_in_success',
        action: 'seen_slide_3',
      });
    }
    if (slide === 4) {
      trackStructEvent({
        category: 'student_diagnostic_check_in_success',
        action: 'seen_slide_4',
      });
    }
  }, [slide, trackStructEvent]);
  const hasDemonstratedSkills = skillsDemonstrated.length > 0;
  const checkInAgainSlide = (
    <>
      <VSpacer height={16} grow />
      <img src={finalSlide} role="presentation" alt="Final Slide" />
      <VSpacer height={56} />
      <HeadingM>Check in again?</HeadingM>
      <VSpacer height={24} />
      <BodyM>
        If you&#39;re feeling up to it, checking in more frequently will help to
        demonstrate what you know much faster.
      </BodyM>
      <BottomSeparator />
      <HStack css={{ justifyContent: 'center' }}>
        <Button
          onClick={() => {
            trackStructEvent({
              category: 'student_diagnostic_check_in_success',
              action: 'clicked_dashboard_link',
            });
            window.location.assign(dashboardRedirectUrl);
          }}
          type="primary"
          size="lanternMedium"
        >
          Back to dashboard
        </Button>
      </HStack>
    </>
  );
  return (
    <>
      <VStack
        css={{
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          textAlign: 'center',
        }}
      >
        {slide === 1 && (
          <CheckInCompleteSlide
            title="More practice can help you grow!"
            questionsCompleted={questionsCompleted}
            questionsCorrect={questionsCorrect}
            primaryButton={{
              label: 'Continue',
              onClick: () => setSlide(s => s + 1),
            }}
            checkInId={checkInId}
          />
        )}
        {hasDemonstratedSkills ? (
          <>
            {slide === 2 && (
              <StudentSkillsDemonstrated
                checkInId={checkInId}
                skills={skillsDemonstrated}
                questionsCorrect={questionsCorrect}
                questionsCompleted={questionsCompleted}
                primaryButton={{
                  label: 'Continue',
                  onClick: () => setSlide(s => s + 1),
                }}
              />
            )}
            {slide === 3 && checkInAgainSlide}
          </>
        ) : (
          <>{slide === 2 && checkInAgainSlide}</>
        )}
      </VStack>
    </>
  );
}
type StrandDiagnosticCheckInCompleteProps = {
  numberOfQuestions: number;
  questionsCompleted: number;
  questionsCorrect: number;
  checkInId: string;
  strand: Strand;
  checkInEndedAt: CheckInEndedAt;
  points: number;
};
function StrandDiagnosticCheckInComplete({
  numberOfQuestions,
  questionsCompleted,
  questionsCorrect,
  checkInId,
  strand,
  checkInEndedAt,
  points,
}: StrandDiagnosticCheckInCompleteProps) {
  const slide = 1;
  const { trackStructEvent } = useSnowplow();
  // This feature flag has been defaulted to `false` while back-end work is
  // still pending.
  // See: https://app.clickup.com/t/6924803/ELE-92
  const gamificationEnablePointsCheckins = false;
  // Tracking which slide a student has seen and any other
  // side effects
  useEffect(() => {
    if (slide === 1) {
      trackStructEvent({
        category: 'student_diagnostic_check_in_success',
        action: 'seen_slide_1',
      });
    }
  }, [slide, trackStructEvent]);
  const continueToDashboard = () => {
    trackStructEvent({
      category: 'student_diagnostic_check_in_success',
      action: 'clicked_dashboard_link',
    });
    window.location.assign(dashboardRedirectUrl);
  };
  if (gamificationEnablePointsCheckins) {
    return (
      <CheckInTaskCompletionScreen
        checkInId={checkInId}
        onNext={continueToDashboard}
      >
        <PointsCard points={points} />
      </CheckInTaskCompletionScreen>
    );
  }
  return (
    <StudentSubstrandsGrowthSlide
      checkInId={checkInId}
      checkInType="STRAND_DIAGNOSTIC_CHECKIN"
      strand={strand}
      checkInEndedAt={checkInEndedAt}
      heading="Discovery check-in"
      subheading="This is your starting mastery. Make progress by completing check-ins through the school year."
      numberOfQuestions={numberOfQuestions}
      questionsCorrect={questionsCorrect}
      questionsCompleted={questionsCompleted}
      primaryButton={{
        label: 'Continue and go to the dashboard',
        onClick: continueToDashboard,
      }}
    />
  );
}
function GrowthCheckInComplete({
  questionsCompleted,
  questionsCorrect,
  skillsDemonstrated,
  checkInId,
}: {
  questionsCompleted: number;
  questionsCorrect: number;
  skillsDemonstrated: SkillsDemonstrated;
  checkInId: string;
}) {
  const [slide, setSlide] = useState(1);
  const { trackStructEvent } = useSnowplow();
  const hasDemonstratedSkills = skillsDemonstrated.length > 0;
  // Tracking which slide a student has seen and any other
  // side effects
  useEffect(() => {
    if (slide === 1) {
      trackStructEvent({
        category: 'student_growth_check_in_success',
        action: 'seen_slide_1',
      });
    }
    if (slide === 2) {
      trackStructEvent({
        category: 'student_growth_check_in_success',
        action: 'seen_slide_2',
      });
    }
  }, [slide, trackStructEvent]);
  const continueToDashboard = () => {
    trackStructEvent({
      category: 'student_growth_check_in_success',
      action: 'clicked_dashboard_link',
    });
    window.location.assign(dashboardRedirectUrl);
  };
  const checkInCompleteSlide = (
    <CheckInCompleteSlide
      title="Check in again soon!"
      description="You can check in again at any time."
      questionsCompleted={questionsCompleted}
      questionsCorrect={questionsCorrect}
      primaryButton={{
        label: 'Back to dashboard',
        onClick: continueToDashboard,
      }}
      checkInId={checkInId}
    />
  );
  const studentSkillDemonstratedSlide = (
    <StudentSkillsDemonstrated
      checkInId={checkInId}
      skills={skillsDemonstrated}
      questionsCorrect={questionsCorrect}
      questionsCompleted={questionsCompleted}
      primaryButton={{
        label: 'Continue',
        onClick: () => setSlide(s => s + 1),
      }}
    />
  );
  return (
    <>
      <VStack
        css={{
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          textAlign: 'center',
        }}
      >
        {hasDemonstratedSkills ? (
          <>
            {slide === 1 && studentSkillDemonstratedSlide}
            {slide === 2 && checkInCompleteSlide}
          </>
        ) : (
          <>{slide === 1 && checkInCompleteSlide}</>
        )}
      </VStack>
    </>
  );
}
function SubstrandGrowthCheckInComplete({
  questionsCompleted,
  questionsCorrect,
  skillsDemonstrated,
  substrandId,
  checkInId,
  checkInStartedAt,
  substrand,
  points,
}: {
  questionsCompleted: number;
  questionsCorrect: number;
  skillsDemonstrated: SkillsDemonstrated;
  substrandId: string;
  checkInId: string;
  checkInStartedAt: string;
  substrand: NonNullable<Substrand>;
  points: number;
}) {
  const [slide, setSlide] = useState(1);
  const { trackStructEvent } = useSnowplow();
  // This feature flag has been defaulted to `false` while back-end work is
  // still pending.
  // See: https://app.clickup.com/t/6924803/ELE-92
  const gamificationEnablePointsCheckins = false;
  const hasDemonstratedSkills = skillsDemonstrated.length > 0;
  // Tracking which slide a student has seen and any other
  // side effects
  useEffect(() => {
    if (slide === 1) {
      trackStructEvent({
        category: 'student_growth_check_in_success',
        action: 'seen_slide_1',
      });
    }
    if (slide === 2) {
      trackStructEvent({
        category: 'student_growth_check_in_success',
        action: 'seen_slide_2',
      });
    }
  }, [slide, trackStructEvent]);
  const continueToDashboard = () => {
    trackStructEvent({
      category: 'student_growth_check_in_success',
      action: 'clicked_dashboard_link',
    });
    window.location.assign(dashboardRedirectUrl);
  };
  const checkInCompleteSlide = gamificationEnablePointsCheckins ? (
    <CheckInTaskCompletionScreen
      checkInId={checkInId}
      onNext={() => setSlide(s => s + 1)}
    >
      <PointsCard points={points} />
    </CheckInTaskCompletionScreen>
  ) : (
    <CheckInCompleteSlide
      title="More practice can help you grow!"
      questionsCompleted={questionsCompleted}
      questionsCorrect={questionsCorrect}
      primaryButton={{
        label: 'Continue',
        onClick: () => setSlide(s => s + 1),
      }}
      checkInId={checkInId}
    />
  );
  const recommendationsSlide = (
    <RecommendationsSlide
      onDismiss={continueToDashboard}
      substrandId={substrandId}
    />
  );
  const { proficiency, pastProficiency } = useSubstrandProficiency(
    substrand.id,
    checkInStartedAt,
  );
  return (
    <>
      <VStack
        css={{
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          textAlign: 'center',
        }}
      >
        {hasDemonstratedSkills ? (
          <>
            {slide === 1 && (
              <>
                {gamificationEnablePointsCheckins ? (
                  <CheckInTaskCompletionScreen
                    checkInId={checkInId}
                    onNext={() => setSlide(2)}
                  >
                    <CardDeck>
                      <Card>
                        <SubstrandProficiencyIndicator
                          currentProficiency={proficiency ?? 0}
                          pastProficiency={pastProficiency ?? 0}
                          locked={false}
                          size={64}
                          badgeUrl={substrand.badgeUrl}
                          animated
                        />
                        <p>{substrand.title}</p>
                      </Card>
                      <PointsCard points={points} />
                    </CardDeck>
                  </CheckInTaskCompletionScreen>
                ) : (
                  <SubstrandGrowthCheckinProgress
                    substrandId={substrandId}
                    checkInStartedAt={checkInStartedAt}
                    questionsCorrect={questionsCorrect}
                    questionsCompleted={questionsCompleted}
                    substrand={substrand}
                    checkInId={checkInId}
                    onContinue={() => setSlide(2)}
                  />
                )}
              </>
            )}
            {slide === 2 && recommendationsSlide}
          </>
        ) : (
          <>
            {slide === 1 && checkInCompleteSlide}
            {slide === 2 && recommendationsSlide}
          </>
        )}
      </VStack>
    </>
  );
}
function SubstrandGrowthCheckinProgress({
  substrandId,
  checkInStartedAt,
  questionsCorrect,
  questionsCompleted,
  substrand,
  checkInId,
  onContinue,
}: {
  questionsCompleted: number;
  questionsCorrect: number;
  substrandId: string;
  checkInId: string;
  checkInStartedAt: string;
  substrand: NonNullable<Substrand>;
  onContinue: () => void;
}) {
  const { proficiency, pastProficiency } = useSubstrandProficiency(
    substrandId,
    checkInStartedAt,
  );
  return (
    <>
      <VSpacer height={36} />
      <HeadingM>You've grown in the topic</HeadingM>
      <VSpacer height={16} />
      <BodyM>
        {questionsCorrect} of {questionsCompleted} questions correct
      </BodyM>
      <VSpacer height={16} />
      <GroupVStack css={{ width: 'auto' }}>
        <SubstrandProficiencyIndicator
          locked={false}
          animated
          key={proficiency}
          currentProficiency={proficiency ?? 0}
          pastProficiency={pastProficiency ?? 0}
          size={PROFICIENCY_INDICATOR_SIZE}
          badgeUrl={substrand.badgeUrl}
        />

        <VSpacer height={12} />
        <GridItemTitle>
          <BodyM color="shuttleGray">{substrand.title}</BodyM>
        </GridItemTitle>
      </GroupVStack>
      <CheckInFooterWrapper>
        <VSpacer height={24} />
        <Button onClick={onContinue} type="primary" size="lanternMedium">
          Find some practice
        </Button>

        <VSpacer height={16} />
        <FeedbackComponent
          feedbackType="CHECKIN_COMPLETE"
          checkInId={checkInId}
        />
        <VSpacer height={24} />
      </CheckInFooterWrapper>
    </>
  );
}
function SkillCheckInComplete({
  questionsCompleted,
  questionsCorrect,
  skillsLevelledUp,
  skillInfo,
  checkInId,
  strandId,
  points,
}: {
  questionsCompleted: number;
  questionsCorrect: number;
  skillsLevelledUp: SkillsLevelledUp;
  skillInfo: SkillInfo;
  checkInId: string;
  strandId?: string | undefined;
  points: number;
}) {
  const { trackStructEvent } = useSnowplow();
  const [slide, setSlide] = useState(1);
  // This feature flag has been defaulted to `false` while back-end work is
  // still pending.
  // See: https://app.clickup.com/t/6924803/ELE-92
  const gamificationEnablePointsCheckins = false;
  // Tracking which slide a student has seen and any other
  // side effects
  useEffect(() => {
    if (slide === 1) {
      trackStructEvent({
        category: 'student_skill_check_in_success',
        action: 'seen_slide_1',
      });
    }
    if (slide === 2) {
      trackStructEvent({
        category: 'student_skill_check_in_success',
        action: 'seen_slide_2',
      });
    }
  }, [slide, trackStructEvent]);
  const continueToDashboard = () => {
    trackStructEvent({
      category: 'student_skill_check_in_success',
      action: 'clicked_dashboard_link',
    });
    window.location.assign(dashboardRedirectUrl);
  };
  const checkInSkillDemonstrated = skillsLevelledUp.find(
    element => element.id === skillInfo.id,
  );
  function handleNext() {
    const skillMastered =
      skillInfo.proficiency != null &&
      skillInfo.proficiency >= SANA_PROFICIENCY_BANDS.mastered;

    if (skillMastered) {
      trackStructEvent({
        category: 'student_diagnostic_check_in_success',
        action: 'clicked_skills_map_link',
      });
      window.location.assign(
        getStudentSkillUrl({
          substrandId: skillInfo.outcome.gradeSubstrand.substrand.id,
          strandId: skillInfo.outcome.gradeSubstrand.substrand.strand.id,
          outcomeId: skillInfo.outcome.id,
        }),
      );
    } else {
      setSlide(s => s + 1);
    }
  }
  return (
    <VStack
      css={{
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        textAlign: 'center',
      }}
    >
      <>
        {slide === 1 && (
          <>
            {gamificationEnablePointsCheckins ? (
              <CheckInTaskCompletionScreen
                checkInId={checkInId}
                onNext={handleNext}
              >
                <CardDeck>
                  <Card>
                    <SkillProficiencyIndicator
                      value={skillInfo.proficiency}
                      width={64}
                      didGrow={!!checkInSkillDemonstrated}
                      shouldAnimate
                    />
                    <p>{skillInfo.title}</p>
                  </Card>
                  <PointsCard points={points} />
                </CardDeck>
              </CheckInTaskCompletionScreen>
            ) : (
              <StudentSingleSkillDemonstrated
                skillDemonstrated={!!checkInSkillDemonstrated}
                handleProgress={() => setSlide(s => s + 1)}
                skillInfo={skillInfo}
                questionsCorrect={questionsCorrect}
                questionsCompleted={questionsCompleted}
                checkInId={checkInId}
              />
            )}
          </>
        )}
        {slide === 2 &&
          (strandId ? (
            <SkillIsPartOfTopicSlide
              substrandBadgeUrl={skillInfo.substrand.badgeUrl}
              substrandTitle={skillInfo.substrand.title}
              strandId={strandId}
            />
          ) : (
            <RecommendationsSlide
              skillId={skillInfo.id}
              onDismiss={continueToDashboard}
            />
          ))}
      </>
    </VStack>
  );
}
export function SkillIsPartOfTopicSlide({
  strandId,
  substrandBadgeUrl,
  substrandTitle,
}: {
  substrandTitle: string;
  substrandBadgeUrl: string;
  strandId: string;
}) {
  const history = useHistory();
  const returnToDiagnosticCheckIn = () => {
    history.push(getCheckInStrandDiagnosticUrl({ strandId }));
  };
  return (
    <>
      <HeadingM>This skill is part of a topic</HeadingM>
      <VSpacer height={8} />
      <BodyM color="grey90">Collect skills to progress topics</BodyM>
      <VSpacer height={32} />
      <GroupVStack css={{ width: 'auto' }}>
        <SubstrandProficiencyIndicator
          locked={false}
          animated
          currentProficiency={0.1}
          pastProficiency={0}
          size={PROFICIENCY_INDICATOR_SIZE}
          badgeUrl={substrandBadgeUrl}
        />

        <VSpacer height={12} />
        <GridItemTitle>
          <BodyM color="shuttleGray">{substrandTitle}</BodyM>
        </GridItemTitle>
      </GroupVStack>
      <CheckInFooterWrapper>
        <VSpacer height={24} />
        <Button
          onClick={returnToDiagnosticCheckIn}
          type="primary"
          size="lanternMedium"
          isWide
        >
          Continue
        </Button>
        <VSpacer height={24} />
      </CheckInFooterWrapper>
    </>
  );
}
function CheckInCompleteSlide({
  title,
  description,
  questionsCorrect,
  questionsCompleted,
  primaryButton,
  secondaryButton,
  checkInId,
}: {
  title: ReactNode;
  description?: string | undefined;
  questionsCorrect: number;
  questionsCompleted: number;
  primaryButton: {
    label: string;
    onClick: () => void;
  };
  secondaryButton?:
    | {
        label: string;
        onClick: () => void;
      }
    | undefined;
  checkInId: string;
}) {
  return (
    <>
      <Milo variant="pencil" />
      <VSpacer height={36} />
      <HeadingM>{title}</HeadingM>
      <VSpacer height={16} />
      <BodyM>
        {questionsCorrect} of {questionsCompleted} questions correct
      </BodyM>
      <VSpacer height={36} />
      {description && (
        <>
          <BodyM>{description}</BodyM>
          <VSpacer height={36} />
        </>
      )}
      <CheckInFooterWrapper>
        <VSpacer height={24} />
        <Button
          onClick={primaryButton.onClick}
          type="primary"
          size="lanternMedium"
        >
          {primaryButton.label}
        </Button>
        {secondaryButton != null && (
          <>
            <VSpacer height={16} />
            <Button
              onClick={secondaryButton.onClick}
              isInline
              type="tertiary"
              size="lanternSmall"
            >
              {secondaryButton.label}
            </Button>
          </>
        )}
        <VSpacer height={16} />
        <FeedbackComponent
          feedbackType="CHECKIN_COMPLETE"
          checkInId={checkInId}
        />
        <VSpacer height={24} />
      </CheckInFooterWrapper>
    </>
  );
}
const USER_STATUSES_QUERY = graphql`
  query CheckInCompleteSubstrandsUserStatusesQuery(
    $filters: [UserStatusFilterInput!]!
    $timestamp: DateTime
    $strandId: ID!
    $previewingWithProblemData: Boolean!
    $growthPeriod: Int!
  ) {
    lantern {
      viewer {
        __typename
        ... on LanternStudent {
          strandStatus(strandId: $strandId) {
            id
            status
          }
          userStatuses(
            filters: $filters
            previewingWithProblemData: $previewingWithProblemData
            growthPeriod: $growthPeriod
          ) {
            trueProficiency
            userStatusFilter {
              curriculumNodeIds
            }
          }
          previousStatuses: userStatuses(
            filters: $filters
            timestamp: $timestamp
            previewingWithProblemData: $previewingWithProblemData
            growthPeriod: $growthPeriod
          ) {
            trueProficiency
          }
        }
      }
    }
  }
`;
const CenterText = styled.div`
  text-align: center;
`;
function StudentSubstrandsGrowthSlide({
  checkInId,
  checkInType,
  strand,
  checkInEndedAt,
  heading,
  subheading,
  numberOfQuestions,
  questionsCompleted,
  primaryButton,
}: {
  checkInId: string;
  checkInType: LanternCheckInType;
  strand: Strand;
  checkInEndedAt: CheckInEndedAt;
  heading: string;
  subheading?: string | undefined;
  numberOfQuestions: number;
  questionsCorrect: number;
  questionsCompleted: number;
  primaryButton: {
    label: string;
    onClick: () => void;
  };
}) {
  const { selfReportedGradeOrder } = useStudentContext();
  if (strand == null)
    throw new InvariantViolation('No valid strand was available');
  const substrands =
    strand?.substrands.filter(
      substrand =>
        substrand.gradeSubstrands.filter(
          gs => gs.gradeStrand.grade.order <= selfReportedGradeOrder,
        ).length > 0,
    ) ?? [];
  const startOfWeek = useMemo(getStartOfWeek, []);
  const previousStatusTimestamp = getGrowthStartDate({
    startOfWeek,
    checkInCompletedAt: checkInEndedAt,
  });
  const filters = substrands
    .flatMap(substrand => substrand.gradeSubstrands.flatMap(gs => gs.outcomes))
    .map(outcome => ({ curriculumNodeIds: [outcome.id] }));
  const { props, error } = useQuery<CheckInCompleteSubstrandsUserStatusesQuery>(
    USER_STATUSES_QUERY,
    {
      filters,
      timestamp: previousStatusTimestamp,
      strandId: strand?.id,
      previewingWithProblemData: false,
      growthPeriod: 120,
    },
  );
  if (error != null) {
    return (
      <PageTimeErrorThrower
        pageName="StudentCheckInComplete"
        componentName="StudentSubstrandsGrowthSlide"
        error={error}
      />
    );
  }
  if (props == null) {
    return <LoadingSpinner />;
  }
  const { viewer } = props.lantern;
  if (viewer === null || viewer.__typename !== 'LanternStudent') {
    throw new InvariantViolation('The logged in user must be a LanternStudent');
  }
  return (
    <Main>
      <ContainerVStack>
        <CheckInHeader title={heading} description={subheading ?? ''} />
        {checkInType !== 'STRAND_DIAGNOSTIC_CHECKIN' && (
          <CheckInProgress
            questionsCompleted={questionsCompleted}
            questionsTotal={numberOfQuestions}
          />
        )}
        <VSpacer height={32} />
        <HStack style={{ justifyContent: 'center' }}>
          <Milo variant="collecting" height={168} />
        </HStack>
        <VSpacer height={32} />
        <CenterText>
          <HeadingL>Check out all your skills!</HeadingL>
          <VSpacer height={16} />
          <BodyM>
            Collect more skills through the year by completing check-ins
          </BodyM>
        </CenterText>
        <VSpacer height={32} />

        <StrandContainer>
          <StudentSkillsMapCurriculumStrand
            strandId={strand.id}
            strandTitle={strand.title}
          />
        </StrandContainer>

        {substrands.map(substrand => (
          <SubstrandView
            key={substrand.id}
            viewer={viewer}
            substrand={substrand}
          />
        ))}

        <CheckInFooterWrapper>
          <VSpacer height={25} />
          <Button
            onClick={primaryButton.onClick}
            type="primary"
            size="lanternMedium"
            trackingId="CheckInSession/CheckInComplete/StudentSubstrandsGrowthSlide/Primary"
          >
            {primaryButton.label}
          </Button>
          <VSpacer height={24} />
          <FeedbackComponent
            feedbackType="CHECKIN_COMPLETE"
            checkInId={checkInId}
          />
          <VSpacer height={24} />
        </CheckInFooterWrapper>
      </ContainerVStack>
    </Main>
  );
}
const SKILL_BADGE_SIZE = 40;
const StrandContainer = styled.div`
  padding: 30px;
  background: white;
  border-radius: 16px;
  margin-bottom: 20px;
`;
const SubstrandRoot = styled.div``;
const SubstrandTitle = styled(HeadingS)({
  textAlign: 'center',
  marginTop: 30,
  marginBottom: 20,
});
type LanternStudentViewer = Extract<
  CheckInCompleteSubstrandsUserStatusesQueryResponse['lantern']['viewer'],
  {
    __typename: 'LanternStudent';
  }
>;
function SubstrandView({
  viewer,
  substrand,
}: {
  substrand: NonNullable<Strand>['substrands'][number];
  viewer: LanternStudentViewer;
}) {
  return (
    <SubstrandRoot key={substrand?.id}>
      <SubstrandTitle>{substrand?.title}</SubstrandTitle>
      <Skills
        viewer={viewer}
        substrand={substrand}
        userStatuses={viewer.userStatuses}
      />
    </SubstrandRoot>
  );
}
const SkillRoot = styled.div`
  padding: 30px;
  background: white;
  border-radius: 16px;
`;
const SkillItem = styled.div`
  display: flex;
  :not(:last-child) {
    margin-bottom: 12px;
  }
`;
function Skills({
  viewer,
  substrand,
  userStatuses,
}: {
  viewer: LanternStudentViewer;
  substrand: NonNullable<Strand>['substrands'][number];
  userStatuses: LanternStudentViewer['userStatuses'];
}) {
  const outcomes = substrand.gradeSubstrands.flatMap(gs => gs.outcomes);

  const {
    featureFlags: { enableOptInDiscoveryCheckIn },
  } = useViewer();

  if (userStatuses == null)
    throw new InvariantViolation(
      '`userStatuses` was empty. `userStatuses must be supplied to show skill proficiency`',
    );

  return (
    // eslint-disable-next-line react/jsx-key
    <SkillRoot>
      {outcomes.map(outcome => {
        const proficiency = getProficiencyForOutcome({
          userStatuses,
          outcomeId: outcome.id,
        });
        return (
          <SkillItem key={outcome.id}>
            {viewer == null ? (
              <SkillProficiencyIndicatorLoading width={SKILL_BADGE_SIZE} />
            ) : viewer?.strandStatus &&
              isStrandLocked(
                viewer.strandStatus,
                enableOptInDiscoveryCheckIn,
              ) &&
              proficiency == null ? (
              <SkillProficiencyIndicatorLocked
                hidePadlock
                width={SKILL_BADGE_SIZE}
              />
            ) : (
              <SkillProficiencyIndicator
                value={proficiency}
                width={SKILL_BADGE_SIZE}
              />
            )}
            <HSpacer width={20} />
            <div
              style={{
                // title of the skill needs to be vertically centered
                // based on the CIRCLE height of the proficiency indicator
                // not the whole height of the proficiency indicator
                // otherwise the title will be too low for locked skill badges
                // because locked skill badges have a larger height
                height: SKILL_BADGE_SIZE,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {outcome.title}
            </div>
          </SkillItem>
        );
      })}
    </SkillRoot>
  );
}
