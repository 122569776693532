import type { Props } from 'ms-components/icons/types';
import Icon from 'ms-ui-primitives/Icon';

export function SkillActivity({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) {
  return (
    <Icon
      color={color}
      size={size}
      aphroditeStyles={aphroditeStyles}
      title={title}
      titleId={titleId}
      viewBoxHeight={20}
      viewBoxWidth={20}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.85714 18.75C8.85714 19.0815 8.73674 19.3995 8.52241 19.6339C8.30808 19.8683 8.01739 20 7.71429 20C7.41118 20 7.12049 19.8683 6.90616 19.6339C6.69184 19.3995 6.57143 19.0815 6.57143 18.75V5C6.57143 4.66848 6.69184 4.35054 6.90616 4.11612C7.12049 3.8817 7.41118 3.75 7.71429 3.75C8.01739 3.75 8.30808 3.8817 8.52241 4.11612C8.73674 4.35054 8.85714 4.66848 8.85714 5V18.75ZM4.28571 18.75C4.28571 19.0815 4.16531 19.3995 3.95098 19.6339C3.73665 19.8683 3.44596 20 3.14286 20C2.83975 20 2.54906 19.8683 2.33474 19.6339C2.12041 19.3995 2 19.0815 2 18.75V10C2 9.66848 2.12041 9.35054 2.33474 9.11612C2.54906 8.8817 2.83975 8.75 3.14286 8.75C3.44596 8.75 3.73665 8.8817 3.95098 9.11612C4.16531 9.35054 4.28571 9.66848 4.28571 10V18.75ZM11.1429 18.75V7.5C11.1429 7.16848 11.2633 6.85054 11.4776 6.61612C11.6919 6.3817 11.9826 6.25 12.2857 6.25C12.5888 6.25 12.8795 6.3817 13.0938 6.61612C13.3082 6.85054 13.4286 7.16848 13.4286 7.5V18.75C13.4286 19.0815 13.3082 19.3995 13.0938 19.6339C12.8795 19.8683 12.5888 20 12.2857 20C11.9826 20 11.6919 19.8683 11.4776 19.6339C11.2633 19.3995 11.1429 19.0815 11.1429 18.75ZM15.7143 1.25C15.7143 0.918479 15.8347 0.600537 16.049 0.366117C16.2633 0.131696 16.554 0 16.8571 0C17.1602 0 17.4509 0.131696 17.6653 0.366117C17.8796 0.600537 18 0.918479 18 1.25V18.75C18 19.0815 17.8796 19.3995 17.6653 19.6339C17.4509 19.8683 17.1602 20 16.8571 20C16.554 20 16.2633 19.8683 16.049 19.6339C15.8347 19.3995 15.7143 19.0815 15.7143 18.75V1.25Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default SkillActivity;
