/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AssignedTaskStatus = "COMPLETED" | "COMPLETED_LATE" | "INCOMPLETE_ENDED_BY_STUDENT" | "INCOMPLETE_EXPIRED" | "IN_PROGRESS" | "NOT_STARTED";
export type ProblemOutcome = "CORRECT" | "INCORRECT" | "PARTIAL_CORRECT" | "STARTED" | "UNSTARTED";
export type StatusEnum = "ENDED_COMPLETE_MARKED" | "ENDED_INCOMPLETE_MARKED" | "ENDED_INCOMPLETE_NOT_MARKED" | "ENDED_NOT_STARTED_MARKED" | "ENDED_NOT_STARTED_NOT_MARKED" | "IN_PROGRESS" | "READY";
export type TaskTypes = "ADAPTIVE_TASK" | "CHECK_IN_TASK" | "CUSTOM_TASK" | "ENGAGE_TASK" | "LESSON_TASK" | "STRAND_DIAGNOSTIC_CHECK_IN_TASK" | "WORKSHEET_TASK";
export type AssignedTaskCard_assignedTask = {
    readonly id: string;
    readonly type: TaskTypes;
    readonly dueDate: string;
    readonly status: AssignedTaskStatus;
    readonly task: {
        readonly __typename: "CustomTask";
        readonly id: string;
        readonly isAccessible: boolean;
        readonly title: string;
    } | {
        readonly __typename: "LessonTask";
        readonly subtopic: {
            readonly topic: {
                readonly badgeUrl: string;
                readonly badgeBackground: string | null;
                readonly id: string;
                readonly syllabus: {
                    readonly id: string;
                };
            };
            readonly id: string;
        };
        readonly isAccessible: boolean;
        readonly title: string;
    } | {
        readonly __typename: "WorksheetTask";
        readonly subtopic: {
            readonly topic: {
                readonly badgeUrl: string;
                readonly badgeBackground: string | null;
                readonly id: string;
                readonly syllabus: {
                    readonly id: string;
                };
            };
            readonly id: string;
        };
        readonly title: string;
        readonly isAccessible: boolean;
    } | {
        readonly __typename: "NewAdaptiveExperienceTask";
        readonly subtopic: {
            readonly topic: {
                readonly badgeUrl: string;
                readonly badgeBackground: string | null;
            };
        };
        readonly id: string;
        readonly isAccessible: boolean;
        readonly title: string;
    } | {
        readonly __typename: "AdaptiveTask";
        readonly subtopic: {
            readonly topic: {
                readonly badgeUrl: string;
                readonly badgeBackground: string | null;
            };
        };
        readonly title: string;
        readonly isAccessible: boolean;
    } | {
        readonly __typename: "EngageTask";
        readonly subtopic: {
            readonly topic: {
                readonly badgeUrl: string;
                readonly badgeBackground: string | null;
            };
        };
        readonly title: string;
        readonly isAccessible: boolean;
    } | {
        readonly __typename: "CheckInTask";
        readonly strand: {
            readonly __typename: string;
            readonly id: string;
            readonly title: string;
        };
        readonly title: string;
        readonly isAccessible: boolean;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    };
    readonly workout: ({
        readonly __typename: "AdaptiveWorkout";
        readonly id: string;
        readonly status: StatusEnum;
        readonly gamifiedMastery: number;
    } | {
        readonly __typename: "NewAdaptiveExperienceWorkout";
        readonly id: string;
        readonly status: StatusEnum;
        readonly gamifiedMastery: number;
    } | {
        readonly __typename: "CustomWorkout";
        readonly id: string;
        readonly status: StatusEnum;
        readonly problems: ReadonlyArray<{
            readonly outcome: ProblemOutcome;
        }>;
        readonly percentDisplay: string | null;
    } | {
        readonly __typename: "EngageWorkout";
        readonly id: string;
        readonly status: StatusEnum;
    } | {
        readonly __typename: "CheckIn";
        readonly id: string;
        readonly status: StatusEnum;
        readonly numberOfQuestions: number;
        readonly questionsCompleted: number;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }) | null;
    readonly " $refType": "AssignedTaskCard_assignedTask";
};
export type AssignedTaskCard_assignedTask$data = AssignedTaskCard_assignedTask;
export type AssignedTaskCard_assignedTask$key = {
    readonly " $data"?: AssignedTaskCard_assignedTask$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AssignedTaskCard_assignedTask">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAccessible",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "badgeUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "badgeBackground",
  "storageKey": null
},
v7 = {
  "alias": "subtopic",
  "args": null,
  "concreteType": "Subtopic",
  "kind": "LinkedField",
  "name": "areaOfStudy",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v6/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Syllabus",
          "kind": "LinkedField",
          "name": "syllabus",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": "subtopic",
  "args": null,
  "concreteType": "Subtopic",
  "kind": "LinkedField",
  "name": "areaOfStudy",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  (v8/*: any*/),
  (v4/*: any*/),
  (v3/*: any*/)
],
v10 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "gamifiedMastery",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignedTaskCard_assignedTask",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "type": "CustomTask",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v7/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "type": "LessonTask",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v7/*: any*/),
            (v4/*: any*/),
            (v3/*: any*/)
          ],
          "type": "WorksheetTask",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v8/*: any*/),
            (v0/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "type": "NewAdaptiveExperienceTask",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v9/*: any*/),
          "type": "AdaptiveTask",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v9/*: any*/),
          "type": "EngageTask",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "strand",
              "args": null,
              "concreteType": "Strand",
              "kind": "LinkedField",
              "name": "areaOfStudy",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v0/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            (v4/*: any*/),
            (v3/*: any*/)
          ],
          "type": "CheckInTask",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "workout",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": (v10/*: any*/),
          "type": "AdaptiveWorkout",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v10/*: any*/),
          "type": "NewAdaptiveExperienceWorkout",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Problem",
              "kind": "LinkedField",
              "name": "problems",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "outcome",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentDisplay",
              "storageKey": null
            }
          ],
          "type": "CustomWorkout",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "type": "EngageWorkout",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numberOfQuestions",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "questionsCompleted",
              "storageKey": null
            }
          ],
          "type": "CheckIn",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AssignedTask",
  "abstractKey": null
};
})();
(node as any).hash = '2253b4c4f87e2c22fcbba61d6b908dc5';
export default node;
