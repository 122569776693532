import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import type { SelectedSubtopic } from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelector';

import { getInitialState } from './State';
import type {
  Updater,
  TaskName,
  TaskStartDate,
  TaskDueDate,
  State,
} from './State';

export const updateTaskName: Updater<[TaskName]> = newTaskName => state => ({
  ...state,
  taskName: newTaskName,
});

export const updateTaskStartDate: Updater<[TaskStartDate]> =
  newDate => state => ({
    ...state,
    taskStartDate: newDate,
  });

export const updateTaskDueDate: Updater<[TaskDueDate]> = newDate => state => ({
  ...state,
  taskDueDate: newDate,
});

export const selectClass: Updater<[ClassSelectionPayload]> =
  klass => state => ({
    ...state,
    selectedClasses: [...state.selectedClasses, klass],
  });

export const selectStudent: Updater<[StudentSelectionPayload]> =
  student => state => ({
    ...state,
    selectedStudents: [...state.selectedStudents, student],
  });

export const setSelectedClasses: Updater<
  [ReadonlyArray<ClassSelectionPayload>]
> = klasses => state => ({
  ...state,
  selectedClasses: klasses,
});

export const setSelectedStudents: Updater<
  [ReadonlyArray<StudentSelectionPayload>]
> = students => state => ({
  ...state,
  selectedStudents: students,
});

export const setExcludedStudents: Updater<
  [ReadonlyArray<StudentSelectionPayload>]
> = students => state => ({
  ...state,
  excludedStudents: students,
});

export const setActiveSubtopic: Updater<
  [SelectedSubtopic | null | undefined]
> = activeSubtopic => state => ({
  ...state,
  activeSubtopic,
  // When select a new subtopic, set the subtopic title as default task name
  taskName: activeSubtopic ? activeSubtopic.title : '',
});

export const removeClass: Updater<[string]> = classId => state => ({
  ...state,
  selectedClasses: state.selectedClasses.filter(s => s.id !== classId),
});

export const removeStudent: Updater<[string]> = studentId => state => ({
  ...state,
  selectedStudents: state.selectedStudents.filter(s => s.id !== studentId),
});

export const resetState: Updater<[State]> = nextState => () => nextState;
export const clearState: Updater<[]> = () => () => getInitialState();
