import { EgyptianCatSvg } from 'ms-experiments/gamification/EgyptianCatSvg';
import { FoxSvg } from 'ms-experiments/gamification/FoxSvg';
import { PandaSvg } from 'ms-experiments/gamification/PandaSvg';

import type { MonthYear } from './types';

const ITEM_STYLE = { width: 61, height: 61 };

const AVAILABLE_MONTHS = ['11-2024', '12-2024', '01-2025'] as const;

export type AvailableMonth = (typeof AVAILABLE_MONTHS)[number];

export const DESTINATION_CONFIG = {
  Tokyo: {
    longName: 'Tokyo, Japan',
    backgroundName: 'Tokyo streets',
    itemName: 'Kitsune',
    itemJsxElement: <FoxSvg style={ITEM_STYLE} />,
    riveAssetDestinationInput: 'DESTINATION_IS_JAPAN',
  },
  Cairo: {
    longName: 'Cairo, Egypt',
    backgroundName: 'Giza landscape',
    itemName: 'Bastet',
    itemJsxElement: <EgyptianCatSvg style={ITEM_STYLE} />,
    riveAssetDestinationInput: 'DESTINATION_IS_EGYPT',
  },
  Beijing: {
    longName: 'Beijing, China',
    backgroundName: 'Pagoda',
    itemName: 'Panda',
    itemJsxElement: <PandaSvg style={ITEM_STYLE} />,
    riveAssetDestinationInput: 'DESTINATION_IS_CHINA',
  },
} as const;

export type Destination = keyof typeof DESTINATION_CONFIG;

export const DESTINATIONS_MONTH_MAP: Partial<Record<MonthYear, Destination>> = {
  '11-2024': 'Tokyo',
  '12-2024': 'Cairo',
  '01-2025': 'Beijing',
};
