import { motion } from 'framer-motion';
import { useContext, useState } from 'react';
import type { ReactNode } from 'react';
import { Route, useParams, Switch } from 'react-router-dom';

import { CUSTOM_TASK_PREVIEW_SCREEN } from 'ms-pages/Teacher/components/CreateTask';
import CreateTaskModal from 'ms-pages/Teacher/components/CreateTaskModal';
import type { Subtopic, Topic } from 'ms-pages/Teacher/components/SkillsGrid';
import {
  SkillsGrid,
  FADE_DURATION,
  useGridAnimation,
  SkillsGridPreview,
} from 'ms-pages/Teacher/components/SkillsGrid';
import { StateContext as ProblemsCollectionState } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state';
import { QuestionsCounter } from 'ms-pages/Textbooks/components/ProblemCollectionSidebar';
import { BookTypeTabsTop } from 'ms-pages/Textbooks/components/TextbookTypesLayout/TextbookTypeTabs';
import { colors } from 'ms-styles/colors';
import { HStack } from 'ms-ui-primitives/Stack';
import { hexStringToRgbaString } from 'ms-utils/colors';
import { usePushToHistoryPreservingSearch } from 'ms-utils/hooks/usePushToHistoryPreservingSearch';
import useQueryParam from 'ms-utils/hooks/useQueryParam';
import {
  contentBrowsingBasePath,
  contentBrowsingEmptyViewPath,
  contentBrowsingSubtopicDetailViewPath,
  getContentBrowsingSubtopicDetailViewUrl,
} from 'ms-utils/urls';

export function SkillsBookLayout({ children }: { children: ReactNode }) {
  return (
    <Switch>
      <Route
        path={[
          contentBrowsingBasePath,
          contentBrowsingEmptyViewPath,
          contentBrowsingSubtopicDetailViewPath,
        ]}
        exact
        render={() => <SkillsBookRender>{children}</SkillsBookRender>}
      />
    </Switch>
  );
}

function SkillsBookRender({ children }: { children: ReactNode }) {
  const { syllabusId, subtopicId } = useParams<{
    syllabusId: string;
    subtopicId: string | undefined;
  }>();

  const {
    state: previewOpen,
    setState: setPreviewOpen,
    animationMoving: previewMoving,
    fadeOut: previewFadeOut,
  } = useGridAnimation<boolean>(subtopicId !== undefined);

  const { problemsCollection } = useContext(ProblemsCollectionState);

  const showPreview = previewOpen && !previewMoving;

  const [selectedSubtopicId, setSelectedSubtopicId] = useState<string | null>(
    subtopicId ?? null,
  );

  const [searchQueryString, onSearchQueryStringChange] = useQueryParam<string>(
    'searchString',
    '',
    (v): v is string => typeof v === 'string',
  );

  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);

  const pushToHistoryPreservingSearch = usePushToHistoryPreservingSearch();

  const onSelectSubtopic = ({
    subtopic,
    topic,
  }: {
    subtopic: Subtopic;
    topic: Topic;
  }) => {
    pushToHistoryPreservingSearch(
      getContentBrowsingSubtopicDetailViewUrl({
        syllabusId,
        topicId: topic.id,
        subtopicId: subtopic.id,
      }),
    );
    setSelectedSubtopicId(subtopic.id);
  };

  return (
    <HStack height="100%" style={{ isolation: 'isolate' }}>
      <motion.div
        animate={{
          boxShadow: `0 0 48px ${hexStringToRgbaString(
            colors.cloudBurst,
            0.15 * (previewFadeOut || !showPreview ? 0 : 1),
          )}`,
        }}
        transition={{
          boxShadow: { duration: FADE_DURATION },
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          ...(showPreview ? { width: 312 } : { flexGrow: 1 }),
          zIndex: 3, // must beat sticky textbook header local_modules/ms-pages/Textbooks/components/SubtopicDetailView/SubtopicDetailHeader.tsx
        }}
      >
        <BookTypeTabsTop />
        <SkillsGrid
          syllabusId={syllabusId}
          previewOpen={previewOpen}
          setPreviewOpen={setPreviewOpen}
          previewFadeOut={previewFadeOut}
          activeSubtopicId={selectedSubtopicId}
          onClickSubtopic={onSelectSubtopic}
          searchQueryString={searchQueryString}
          onSearchQueryStringChange={onSearchQueryStringChange}
        />
      </motion.div>
      <SkillsGridPreview shown={showPreview} fadeOut={previewFadeOut}>
        {children}
      </SkillsGridPreview>

      <div
        style={{ zIndex: 1, width: 0, height: '100%', position: 'relative' }}
      >
        <QuestionsCounter
          open={false}
          onClick={() => setIsTaskModalOpen(true)}
          length={problemsCollection.length}
        />
      </div>

      {isTaskModalOpen && (
        <CreateTaskModal
          isOpen={isTaskModalOpen}
          onClose={() => setIsTaskModalOpen(false)}
          preselection={CUSTOM_TASK_PREVIEW_SCREEN}
          trackingEventOnCreation={{
            category: 'textbook',
            action: 'create_task',
            property: 'CUSTOM_TASK',
          }}
        />
      )}
    </HStack>
  );
}
