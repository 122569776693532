/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LanternCheckInType = "ALL_STRANDS_DIAGNOSTIC_CHECKIN" | "HISTORIC_DIAGNOSTIC_CHECKIN" | "HISTORIC_GROWTH_CHECKIN" | "POST_TOPIC_TEST_CHECKIN" | "PRE_TOPIC_TEST_CHECKIN" | "SKILL_CHECKIN" | "STRAND_DIAGNOSTIC_CHECKIN" | "STRAND_GROWTH_CHECKIN" | "SUBSTRAND_GROWTH_CHECKIN";
export type CheckInStrandCheckIns = {
    readonly checkIns: ReadonlyArray<{
        readonly type: LanternCheckInType;
        readonly strand: {
            readonly id: string;
        } | null;
    }>;
    readonly " $refType": "CheckInStrandCheckIns";
};
export type CheckInStrandCheckIns$data = CheckInStrandCheckIns;
export type CheckInStrandCheckIns$key = {
    readonly " $data"?: CheckInStrandCheckIns$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CheckInStrandCheckIns">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CheckInStrandCheckIns",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckIn",
      "kind": "LinkedField",
      "name": "checkIns",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Strand",
          "kind": "LinkedField",
          "name": "strand",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LanternStudent",
  "abstractKey": null
};
(node as any).hash = '49425547d35b78303fe7f987741c059d';
export default node;
