import styled from '@emotion/styled';
import { useEffect, useMemo, useRef, useState } from 'react';

import ChevronBottomIcon from 'ms-components/icons/ChevronBottom';
import ChevronRightIcon from 'ms-components/icons/ChevronRight';
import Checkbox from 'ms-components/math/MultipleChoice/Option/Checkbox';
import useUpdateTeacherPreference from 'ms-pages/Teacher/TeacherContext/UpdateTeacherPreference';
import type { CombinedState } from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state';
import { NEW_ADAPTIVE_EXPERIENCE_NO_TARGET_MASTERY_VALUE } from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state';
import type { BulkAdaptiveTaskStateFactory } from 'ms-pages/Teacher/components/CreateTask/CreateBulkAdaptiveTask/state';
import {
  Asterisk,
  FieldWrapper,
  Label,
  TaskCreationFormWrapper,
} from 'ms-pages/Teacher/components/CreateTask/components/FormComponents';
import type { SelectedSubtopic } from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelector/index';
import TaskAssignment from 'ms-pages/Teacher/components/CreateTask/components/TaskAssignment';
import TaskDatePicker from 'ms-pages/Teacher/components/CreateTask/components/TaskDatePicker';
import { fontFamily, fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import CheckboxUi from 'ms-ui-primitives/Checkbox';
import Select from 'ms-ui-primitives/Select';
import { tappable } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

import TaskNameInput from '../components/TaskNameInput';

type Props = {
  state: CombinedState;
  noTitleField?: boolean | undefined;
};

const Title = styled.div({
  fontFamily: fontFamily.body,
  fontSize: fontSize.small,
});

const CheckboxLabel = Title;

const Row = styled.div({
  display: 'flex',
  alignItems: 'center',
  ...tappable,
});

const CheckboxWrapper = styled.div({
  marginRight: 2 * BASE_UNIT,
});

const RightAligned = styled.div({
  marginLeft: 'auto',
});

const AdvancedOptionsButton = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 10 * BASE_UNIT,
  color: colors.cloudBurst,
  fontSize: fontSize.small,
  fontFamily: fontFamily.body,
  fontWeight: fontWeight.semibold,
  ...tappable,
});

export default function AdaptiveTaskDetailsForm({
  state,
  noTitleField,
}: Props) {
  const {
    updateTaskStartDate,
    updateTaskDueDate,
    updateTaskName,
    setSelectedClasses,
    updateTargetMastery,
    setSelectedStudents,
    setExcludedStudents,
    setStartsWithLesson,
    toggleHideCalculator,
  } = state.updaters;
  const [updateTeacherPreference] = useUpdateTeacherPreference();
  const updateTeacherPreferenceTargetMastery = (targetMastery: number) => {
    updateTeacherPreference({
      createTaskTargetMastery: targetMastery,
    });
  };

  const getTargetMasteryOptions = () => {
    return [
      {
        label: 'No target mastery (Recommended)',
        value: NEW_ADAPTIVE_EXPERIENCE_NO_TARGET_MASTERY_VALUE,
      },
      { label: 'Familiar (50)', value: 50 },
      { label: 'Proficient (75)', value: 75 },
      { label: 'Mastered (100)', value: 100 },
    ];
  };

  const isAdvancedOptionsVisible = useBoolean();

  return (
    <TaskCreationFormWrapper>
      {noTitleField !== true && (
        <FieldWrapper>
          <Label>
            Title<Asterisk> *</Asterisk>
          </Label>
          <TaskNameInput
            placeholder="Task Name"
            value={state.values.taskName}
            onChange={updateTaskName}
          />
        </FieldWrapper>
      )}
      <FieldWrapper>
        <Label>
          Start date / Due date<Asterisk> *</Asterisk>
        </Label>
        <TaskDatePicker
          onChange={([startDate, endDate]) => {
            updateTaskStartDate(startDate);
            updateTaskDueDate(endDate);
          }}
          value={[state.values.taskStartDate, state.values.taskDueDate]}
        />
      </FieldWrapper>
      <FieldWrapper>
        <TaskAssignment
          onChangeStudents={setSelectedStudents}
          onChangeClasses={setSelectedClasses}
          onChangeExcludedStudents={setExcludedStudents}
          selectedClasses={state.values.selectedClasses}
          selectedStudents={state.values.selectedStudents}
          excludedStudents={state.values.excludedStudents}
        />
      </FieldWrapper>

      <FieldWrapper>
        <AdvancedOptionsButton onClick={isAdvancedOptionsVisible.toggle}>
          Advanced Options
          <RightAligned>
            {isAdvancedOptionsVisible.value ? (
              <ChevronBottomIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </RightAligned>
        </AdvancedOptionsButton>
      </FieldWrapper>

      {isAdvancedOptionsVisible.value && (
        <>
          <FieldWrapper>
            <Label>
              Target Mastery<Asterisk> *</Asterisk>
            </Label>
            <Select
              block
              onChange={nextValue => {
                updateTargetMastery(nextValue);
                updateTeacherPreferenceTargetMastery(nextValue);
              }}
              value={state.values.targetMastery}
              options={getTargetMasteryOptions()}
            />
          </FieldWrapper>
          <FieldWrapper>
            <CheckboxUi
              checked={state.values.disableCalculator}
              onChange={() => {
                toggleHideCalculator();
              }}
              label="Hide calculator tools"
            />
          </FieldWrapper>
        </>
      )}
      {/* TODO we want to try enabling the lesson first for adaptive tasks or removing
      it completely, but for the moment we just explicity hide the option  */}
      {false && (
        <FieldWrapper>
          <Label>Lesson</Label>
          <Row
            onClick={() => setStartsWithLesson(!state.values.startsWithLesson)}
          >
            <CheckboxWrapper>
              <Checkbox
                size={20}
                checked={state.values.startsWithLesson}
                color={colors.eggplant}
              />
            </CheckboxWrapper>
            <CheckboxLabel> Students start by reading lesson</CheckboxLabel>
          </Row>
        </FieldWrapper>
      )}
    </TaskCreationFormWrapper>
  );
}

type MultipleAdaptiveTaskDetailsFormProps = {
  activeSubtopic: SelectedSubtopic;
  subtopics: ReadonlyArray<SelectedSubtopic>;
  stateFactory: BulkAdaptiveTaskStateFactory;
  onFormCompletionChange?: ((areFormsComplete: boolean) => void) | undefined;
};

export function MultipleAdaptiveTaskDetailsForm({
  activeSubtopic,
  subtopics,
  stateFactory,
  onFormCompletionChange,
}: MultipleAdaptiveTaskDetailsFormProps) {
  const [useSameDetailsForAllTasks, setUseSameDetailsForAllTasks] =
    useState<boolean>(true);

  const bulkState = useMemo(() => {
    return subtopics.map(subtopic => {
      return stateFactory(subtopic.id, {
        taskName: subtopic.title,
        subtopicId: subtopic.id,
        edited: false,
      });
    });
  }, [subtopics, stateFactory]);

  const activeSubtopicState = useMemo(() => {
    if (bulkState.length === 0) {
      return null;
    }
    return (
      bulkState.find(state => state.values.subtopicId === activeSubtopic.id) ??
      null
    );
  }, [activeSubtopic, bulkState]);

  useEffect(() => {
    if (onFormCompletionChange != null) {
      const areFormsComplete =
        subtopics.length > 0 &&
        activeSubtopicState != null &&
        bulkState.every(subtopicState => {
          return (
            subtopicState.values.taskName != null &&
            subtopicState.values.taskName.length > 0 &&
            (subtopicState.values.selectedClasses.length > 0 ||
              subtopicState.values.selectedStudents.length > 0)
          );
        });

      onFormCompletionChange(areFormsComplete);
    }
  }, [
    activeSubtopicState,
    bulkState,
    onFormCompletionChange,
    subtopics.length,
  ]);

  // This is for keeping the previous state of the active subtopic
  // so that we can use it to stop infinite loops when we update
  // the other subtopics with the same details
  // We only set this to the current active subtopic state in the below useEffect
  const activeSubtopicStateRef = useRef<CombinedState | null>(null);

  // This is for updating the other subtopics with the same details
  useEffect(() => {
    if (
      useSameDetailsForAllTasks &&
      activeSubtopicState != null &&
      bulkState != null &&
      bulkState.length > 1 &&
      activeSubtopicStateRef.current != null &&
      JSON.stringify(activeSubtopicStateRef.current.values) !==
        JSON.stringify(activeSubtopicState.values)
    ) {
      bulkState.forEach(state => {
        if (state.values.subtopicId !== activeSubtopicState.values.subtopicId) {
          state.updaters.setSelectedClasses(
            activeSubtopicState.values.selectedClasses,
          );
          state.updaters.setSelectedStudents(
            activeSubtopicState.values.selectedStudents,
          );
          state.updaters.setExcludedStudents(
            activeSubtopicState.values.excludedStudents,
          );
          state.updaters.updateTaskStartDate(
            activeSubtopicState.values.taskStartDate,
          );
          state.updaters.updateTaskDueDate(
            activeSubtopicState.values.taskDueDate,
          );
          state.updaters.updateTargetMastery(
            activeSubtopicState.values.targetMastery,
          );
          if (
            activeSubtopicState.values.disableCalculator !==
            state.values.disableCalculator
          ) {
            state.updaters.toggleHideCalculator();
          }
        }
      });
      activeSubtopicStateRef.current = activeSubtopicState;
    }
  }, [activeSubtopicState, bulkState, useSameDetailsForAllTasks]);

  // Sets the initial value of the activeSubtopicStateRef only when it's null
  useEffect(() => {
    if (activeSubtopicStateRef.current == null) {
      activeSubtopicStateRef.current = activeSubtopicState;
    }
  }, [activeSubtopicState]);

  return (
    <>
      {subtopics.length > 1 && (
        <FieldWrapper>
          <CheckboxUi
            checked={useSameDetailsForAllTasks}
            onChange={() => setUseSameDetailsForAllTasks(prev => !prev)}
            label="Use same details for all tasks (excluding title)"
          />
        </FieldWrapper>
      )}
      {activeSubtopicState && (
        <AdaptiveTaskDetailsForm state={activeSubtopicState} />
      )}
    </>
  );
}
