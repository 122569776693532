/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TaskTypes = "ADAPTIVE_TASK" | "CHECK_IN_TASK" | "CUSTOM_TASK" | "ENGAGE_TASK" | "LESSON_TASK" | "STRAND_DIAGNOSTIC_CHECK_IN_TASK" | "WORKSHEET_TASK";
export type TaskDetail_task = {
    readonly __typename: string;
    readonly id: string;
    readonly title: string;
    readonly type: TaskTypes;
    readonly expiryDate: string;
    readonly startDate: string;
    readonly dueDate: string;
    readonly areaOfStudy: {
        readonly __typename: "Syllabus";
        readonly id: string;
        readonly title: string;
    } | {
        readonly __typename: "Topic";
        readonly id: string;
        readonly title: string;
        readonly syllabus: {
            readonly id: string;
        };
    } | {
        readonly __typename: "Subtopic";
        readonly id: string;
        readonly title: string;
        readonly topic: {
            readonly id: string;
            readonly syllabus: {
                readonly id: string;
            };
        };
    } | {
        readonly __typename: "Strand";
        readonly id: string;
        readonly title: string;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    };
    readonly targetMastery?: number | undefined;
    readonly newAdaptiveTargetMastery?: number | null | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TaskBadge_task">;
};
export type TaskDetail_task$data = TaskDetail_task;
export type TaskDetail_task$key = {
    readonly " $data"?: TaskDetail_task$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TaskDetail_task">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Syllabus",
  "kind": "LinkedField",
  "name": "syllabus",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "TaskDetail_task",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiryDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "areaOfStudy",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "Syllabus",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v4/*: any*/)
          ],
          "type": "Topic",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Topic",
              "kind": "LinkedField",
              "name": "topic",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "Subtopic",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "Strand",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "targetMastery",
          "storageKey": null
        }
      ],
      "type": "AdaptiveTask",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "newAdaptiveTargetMastery",
          "args": null,
          "kind": "ScalarField",
          "name": "targetMastery",
          "storageKey": null
        }
      ],
      "type": "NewAdaptiveExperienceTask",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaskBadge_task"
    }
  ],
  "type": "TaskInterface",
  "abstractKey": "__isTaskInterface"
};
})();
(node as any).hash = '1372fac87240ec6e275e05f95299d743';
export default node;
