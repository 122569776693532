/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NavbarPopover_NavbarElement_user = {
    readonly points: {
        readonly current: number;
    } | null;
    readonly " $refType": "NavbarPopover_NavbarElement_user";
};
export type NavbarPopover_NavbarElement_user$data = NavbarPopover_NavbarElement_user;
export type NavbarPopover_NavbarElement_user$key = {
    readonly " $data"?: NavbarPopover_NavbarElement_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"NavbarPopover_NavbarElement_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavbarPopover_NavbarElement_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PointsObject",
      "kind": "LinkedField",
      "name": "points",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "current",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'f940d479b30daea60dfef65a8b0b5cc4';
export default node;
