import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { graphql } from 'react-relay';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'relay-hooks';

import TaskBadge from 'ms-components/TaskBadge';
import Icons from 'ms-components/icons';
import ChevronLeftIcon from 'ms-components/icons/ChevronLeft';
import EngageTaskIcon from 'ms-components/icons/EngageTask';
import PaperSheetIcon from 'ms-components/icons/PaperSheet';
import TextbookTeacherIcon from 'ms-components/icons/TextbookTeacher';
import TriangleDownIcon from 'ms-components/icons/TriangleDown';
import {
  GameModal,
  type GameModalContent,
} from 'ms-experiments/gamification/GameModal';
import { PageTimeErrorThrower } from 'ms-helpers/PageTimeTracker';
import PageTitle from 'ms-helpers/PageTitle';
import { useSnowplow } from 'ms-helpers/Snowplow';
import useFeatureFlags from 'ms-helpers/useFeatureFlags';
import useFeatureFlagsV2 from 'ms-helpers/useFeatureFlagsV2';
import { taskTypeLabel } from 'ms-pages/Tasks/utils';
import AssignedClassPicker from 'ms-pages/Teacher/TaskReport/components/TaskReportHeader/AssignedClassPicker';
import DeleteTaskFlyout from 'ms-pages/Teacher/TaskReport/components/TaskReportManagementFlyout';
import { ResponsiveContainer as Container } from 'ms-pages/Teacher/components/Container';
import CopyLinkToClipboard from 'ms-pages/Teacher/components/CopyLinkToClipboard';
import ReassignTaskModal from 'ms-pages/Teacher/components/CreateTask/ReassignTaskModal';
import { TASK_TEMPLATE_PREVIEW_SCREEN } from 'ms-pages/Teacher/components/CreateTask/state/createTaskState';
import CreateTaskModal from 'ms-pages/Teacher/components/CreateTaskModal';
import EditTaskFlyout from 'ms-pages/Teacher/components/EditTaskFlyout';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import SolutionModal from 'ms-pages/Textbooks/components/SolutionModal';
import {
  borderRadiusUI,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import type { ButtonStyleProps } from 'ms-ui-primitives/Button';
import Button from 'ms-ui-primitives/Button';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import Center from 'ms-ui-primitives/Center';
import DropdownMenu from 'ms-ui-primitives/DropdownMenu';
import Popover from 'ms-ui-primitives/Popover';
import Stack from 'ms-ui-primitives/Stack';
import { InvariantViolation } from 'ms-utils/app-logging';
import { styled } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import { useRouter } from 'ms-utils/hooks/useRouter';
import { getMasteryLevelTitle } from 'ms-utils/masteryLevel';
import { assert, assertUnreachable } from 'ms-utils/typescript-utils';
import {
  getTaskReportExportUrl,
  teacherClassBaseRedirectorPath,
  getContentBrowsingEmptyViewUrl as syllabusUrl,
  getContentBrowsingSubtopicDetailViewUrl as subtopicUrl,
  getContentBrowsingSubtopicRedirectorUrl as topicUrl,
  getTextbookTheoryUrl,
  getTextbookWorksheetUrl,
  getTaskShareableUrl as taskShareableUrl,
  getTeacherPrintTaskPath as printUrl,
  getTaskReportQuestionsWorkedSolutionsPrintUrl,
  teacherClassActivityLiveUrl,
} from 'ms-utils/urls';

import type {
  TaskDetailQuery,
  TaskTypes,
} from './__generated__/TaskDetailQuery.graphql';
import type { TaskDetailWorkedSolutionsModalContainerQuery } from './__generated__/TaskDetailWorkedSolutionsModalContainerQuery.graphql';
import type { TaskDetail_assignedEntities as AssignedEntities } from './__generated__/TaskDetail_assignedEntities.graphql';
import type { TaskDetail_task as Task } from './__generated__/TaskDetail_task.graphql';
import { LIVE_MODE_DURATION_MIN } from '../TeacherClassReport/views/TeacherClassReportActivity/constants';
import useTeacherContext from '../TeacherContext/useTeacherContext';

export const MOMENT_FORMAT_DATE = 'ddd DD MMM h:mma';
type Props = {
  taskId: string;
};
const TASK_TYPES_WITHOUT_CSV_DOWNLOAD: TaskTypes[] = [
  'ENGAGE_TASK',
  'LESSON_TASK',
  'WORKSHEET_TASK',
  'CHECK_IN_TASK',
  'STRAND_DIAGNOSTIC_CHECK_IN_TASK',
];
const TASK_TYPES_NOT_TRYABLE: TaskTypes[] = [
  'ENGAGE_TASK',
  'LESSON_TASK',
  'WORKSHEET_TASK',
  'CHECK_IN_TASK',
  'STRAND_DIAGNOSTIC_CHECK_IN_TASK',
];
const getLessonPreviewLink = (
  subtopic: Extract<
    Task['areaOfStudy'],
    {
      __typename: 'Subtopic';
    }
  >,
) => {
  return getTextbookTheoryUrl({
    syllabusId: subtopic.topic.syllabus.id,
    topicId: subtopic.topic.id,
    subtopicId: subtopic.id,
  });
};
const getWorksheetPreviewLink = (
  subtopic: Extract<
    Task['areaOfStudy'],
    {
      __typename: 'Subtopic';
    }
  >,
) => {
  return getTextbookWorksheetUrl({
    syllabusId: subtopic.topic.syllabus.id,
    topicId: subtopic.topic.id,
    subtopicId: subtopic.id,
  });
};
const getAreaOfStudyLink = ({ task }: { task: Task }) => {
  const typename = task.areaOfStudy.__typename;
  switch (typename) {
    case 'Syllabus':
      return syllabusUrl({
        syllabusId: task.areaOfStudy.id,
      });
    case 'Topic':
      return topicUrl({
        syllabusId: task.areaOfStudy.syllabus.id,
        topicId: task.areaOfStudy.id,
      });
    case 'Subtopic':
      return subtopicUrl({
        syllabusId: task.areaOfStudy.topic.syllabus.id,
        topicId: task.areaOfStudy.topic.id,
        subtopicId: task.areaOfStudy.id,
      });
    case 'Strand':
      return null;
    default: {
      if (typename === '%other') throw Error(); // type narrowing
      assertUnreachable(
        typename,
        `A task areaOfStudy should be either Syllabus, Topic or Subtopic or Strand. Got ${typename}`,
      );
    }
  }
};
const getAreaOfStudyLabel = (task: Task) => {
  const typename = task.areaOfStudy.__typename;
  switch (typename) {
    case 'Syllabus':
    case 'Topic':
    case 'Subtopic':
    case 'Strand':
      return task.areaOfStudy.title;
    default: {
      if (typename === '%other') throw Error(); // type narrowing
      assertUnreachable(
        typename,
        `A task areaOfStudy should be either Syllabus, Topic, Subtopic or Strand. Got ${typename}`,
      );
    }
  }
};
// __typename typings weak here (string) because of the way we're constructing the query. Thus, it's better to use the TaskType enum instead of the __typename string
const getTaskIcon = (type: Task['type']) => {
  const ExerciseTaskIcon = PaperSheetIcon;
  const TheoryIcon = TextbookTeacherIcon;
  switch (type) {
    case 'CUSTOM_TASK':
      return <ExerciseTaskIcon />;
    case 'ADAPTIVE_TASK':
      return <ExerciseTaskIcon />;
    case 'LESSON_TASK':
      return <TheoryIcon />;
    case 'WORKSHEET_TASK':
      return <TheoryIcon />;
    case 'ENGAGE_TASK':
      return <EngageTaskIcon />;
    case 'CHECK_IN_TASK':
      // no icon because it's very similar to the badge
      return null;
    case 'STRAND_DIAGNOSTIC_CHECK_IN_TASK':
      // no icon because it's very similar to the badge
      return null;
    default: {
      assertUnreachable(type, `A task has an unrecognized type. Got ${type}`);
    }
  }
};
export const Root = styled({
  display: 'flex',
  alignItems: 'center',
  fontFamily: fontFamily.body,
  fontSize: fontSize.medium,
  backgroundColor: colors.white,
  lineHeight: lineHeight.body,
  paddingBottom: 0,
  width: '100%',
});
const TOP_ROW_SIZE = 12 * BASE_UNIT;
const Row = styled({
  display: 'flex',
  alignItems: 'center',
});
const FilterAndActionsWrapper = styled({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
});
export const Title = styled({
  fontSize: 20,
  fontWeight: fontWeight.semibold,
  lineHeight: '26px',
  color: colors.cloudBurst,
});
const TitleDetails = styled({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginLeft: 4 * BASE_UNIT,
  marginRight: 4 * BASE_UNIT,
});
const Details = styled({
  display: 'flex',
  width: '100%',
  gap: 8 * BASE_UNIT,
});
const Detail = styled({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
});
const VerticalDivider = styled({
  width: 1,
  background: colors.cloudBurst,
  opacity: 0.3,
  height: '100%',
  margin: '0 20px',
});
export const DetailLabel = styled({
  fontSize: fontSize.small,
  color: colors.shuttleGray,
  marginBottom: 2 * BASE_UNIT,
});
export const DetailValue = styled({
  color: colors.cloudBurst,
  fontSize: fontSize.medium,
});
const AreaOfStudyLink = styled(
  {
    display: 'flex',
    alignItems: 'center',
    color: colors.eggplant,
    textDecoration: 'none',
    fontWeight: fontWeight.semibold,
  },
  'a',
);
const TaskDate = styled({
  display: 'flex',
  alignItems: 'center',
});
const AlignToRight = styled({
  display: 'flex',
  marginLeft: 'auto',
  alignItems: 'center',
});
export const ActionMenuWrapper = styled({
  position: 'relative',
  marginRight: 24,
});
export const PopoverContentWrapper = styled({
  width: 85 * BASE_UNIT,
  borderRadius: 4,
  boxShadow: '0px 2px 17px rgba(0, 0, 0, 0.15)',
  background: colors.white,
  padding: 6 * BASE_UNIT,
  maxHeight: 100 * BASE_UNIT,
});
const ShareTaskTitle = styled({
  fontSize: fontSize.medium,
  fontWeight: fontWeight.semibold,
  color: colors.cloudBurst,
  marginBottom: 4 * BASE_UNIT,
  lineHeight: lineHeight.heading,
});
const Horizontal = styled({
  display: 'flex',
});
const HorizontalSpace = styled({
  width: '4px',
});
const Label = styled({
  fontSize: fontSize.medium,
  color: colors.shuttleGray,
  lineHeight: '18px',
});
const TaskTypeDetails = styled({
  display: 'flex',
  gap: 4,
  color: colors.shuttleGray,
});
const ActiveBadge = styled({
  default: {
    color: colors.matisse,
    backgroundColor: colors.tropicalBlue,
    fontSize: fontSize.small,
    fontWeight: fontWeight.semibold,
    padding: '4px 8px',
    borderRadius: borderRadiusUI,
  },
  past: {
    color: colors.shuttleGray,
    backgroundColor: colors.seashell,
  },
});
export default function TaskDetailView({ taskId }: Props) {
  const workedSolutionsModalOpen = useBoolean();
  const workedSolutionsModalReady = useBoolean();
  const { props, error } = useQuery<TaskDetailQuery>(
    graphql`
      query TaskDetailQuery($taskId: ID!) {
        task(id: $taskId) {
          type
          ...TaskDetail_task @relay(mask: false)
          ...TaskDetail_customTask @relay(mask: false)
          ...TaskDetail_assignedEntities @relay(mask: false)
          ...TugOfWarInstructions_task
          ...GameSetup_task
          assignedStudentsClasses {
            ...AssignedClassPicker_assignedStudentsClasses
          }
        }
      }
    `,
    {
      taskId,
    },
  );
  const [{ advancedReports, templates }] = useFeatureFlags();
  const [{ gamificationEnableLiveModePromote, gamificationEnableTugOfWar }] =
    useFeatureFlagsV2();
  const [isReassigningTask, setIsReassigningTask] = useState(false);
  const [isDeletingTask, setIsDeletingTask] = useState(false);
  const [isEditingTask, setIsEditingTask] = useState(false);
  const [isCreatingTaskTemplate, setIsCreatingTaskTemplate] =
    React.useState(false);
  const [gameModalContent, setGameModalContent] =
    useState<GameModalContent | null>(null);
  const { trackStructEvent } = useSnowplow();
  const [isShareTaskPopoverOpen, setShareTaskPopoverOpen] =
    React.useState(false);
  const closeShareTaskPopover = React.useCallback(() => {
    setShareTaskPopoverOpen(false);
  }, []);
  const { history } = useRouter();
  const { state } = useLocation();
  const modalIsOpen = state instanceof Object && 'background' in state;
  const actionMenuButtonRef = React.useRef(null);
  const [isActionMenuOpen, setActionMenuOpen] = React.useState(false);
  const { classId } = useTeacherContext();
  const closeActionMenu = React.useCallback(() => {
    setActionMenuOpen(false);
  }, []);
  const openActionMenu = React.useCallback(() => {
    setActionMenuOpen(true);
  }, []);

  const destructuredResponse = React.useMemo(() => {
    if (props == null || props.task == null) return null;
    const {
      assignedStudents,
      assignedClasses,
      assignedProblems,
      assignedStudentsClasses,
      type,
      ...rest
    } = props.task;
    const task: Task = { ...rest, type };
    const customTask = { assignedProblems };
    const assignedEntities: AssignedEntities = {
      assignedStudents,
      assignedClasses,
    };
    return {
      task,
      customTask,
      assignedEntities,
      type,
      assignedStudentsClasses,
    };
  }, [props]);
  if (error != null)
    return (
      <PageTimeErrorThrower
        pageName="TeacherTaskReport"
        componentName="TaskDetails"
        error={error}
      />
    );
  if (props == null)
    return (
      <Root>
        <Center>
          <MinorSpinner />
        </Center>
      </Root>
    );
  if (props.task == null || destructuredResponse == null)
    throw new InvariantViolation('Unable to load requested task');
  const { task, customTask, assignedEntities, assignedStudentsClasses } =
    destructuredResponse;
  const expiryDate = task.expiryDate;
  const isExpired = moment(expiryDate).isBefore(moment());
  const canCreateTaskTemplate = templates && task.type === 'CUSTOM_TASK';
  const isTryable = !isExpired && !TASK_TYPES_NOT_TRYABLE.includes(task.type);
  const isLessonPreviewable = task.type === 'LESSON_TASK';
  const isWorksheetPreviewable = task.type === 'WORKSHEET_TASK';
  const canDownloadCsv = !TASK_TYPES_WITHOUT_CSV_DOWNLOAD.includes(task.type);
  const hasPrintView = task.type === 'CUSTOM_TASK';
  const hasWorkedSolution =
    task.type === 'CUSTOM_TASK' || task.type === 'ADAPTIVE_TASK';
  const showWorkedSolutionMenuItem =
    hasWorkedSolution && workedSolutionsModalReady.value;
  const isShareable =
    task.type !== 'ENGAGE_TASK' &&
    task.type !== 'CHECK_IN_TASK' &&
    task.type !== 'STRAND_DIAGNOSTIC_CHECK_IN_TASK';
  const shouldRenderClassGameButton =
    gamificationEnableTugOfWar && task.type === 'CUSTOM_TASK' && !isExpired;
  const shouldRenderLiveModeLink =
    advancedReports &&
    gamificationEnableLiveModePromote &&
    (task.type === 'ADAPTIVE_TASK' || task.type === 'CUSTOM_TASK');

  const dropdownItems = [
    ...(hasPrintView
      ? [
          {
            key: 'print',
            label: 'Print task',
            link: printUrl(task.id),
            action: () => {},
            openLinkInNewTab: true,
            // We are showing the print dialog on load of the print view.
            // And we are using window.print() to show the print dialog,
            // and it blocks the page and any page with the same window object.
            // noopener is used here to prevent the opened window
            // from having access to the window that opened it,
            // so that no page other than the print view page is blocked
            // when print dialog is opened with window.print().
            windowFeatures: 'noopener',
          },
        ]
      : []),
    ...(canCreateTaskTemplate
      ? [
          {
            key: 'create-task-template',
            label: 'Create task template',
            action: () => {
              setIsCreatingTaskTemplate(true);
            },
          },
        ]
      : []),
    ...(isShareable
      ? [
          {
            key: 'share',
            label: 'View share link',
            action: () => {
              setShareTaskPopoverOpen(true);
            },
          },
        ]
      : []),
    ...(isLessonPreviewable && task.areaOfStudy.__typename === 'Subtopic'
      ? [
          {
            key: 'preview-lesson',
            label: 'Preview lesson',
            action: () => {
              if (task.areaOfStudy.__typename === 'Subtopic')
                trackStructEvent({
                  category: 'task_report',
                  action: 'clicked_on_preview_lesson',
                  property: 'subtopic_id',
                  label: task.areaOfStudy.id,
                });
            },
            openLinkInNewTab: true,
            link: getLessonPreviewLink(task.areaOfStudy),
          },
        ]
      : []),
    ...(isWorksheetPreviewable && task.areaOfStudy.__typename === 'Subtopic'
      ? [
          {
            key: 'preview-worksheet',
            label: 'Preview worksheet',
            action: () => {
              if (task.areaOfStudy.__typename === 'Subtopic')
                trackStructEvent({
                  category: 'task_report',
                  action: 'clicked_on_preview_worksheet',
                  property: 'subtopic_id',
                  label: task.areaOfStudy.id,
                });
            },
            openLinkInNewTab: true,
            link: getWorksheetPreviewLink(task.areaOfStudy),
          },
        ]
      : []),
    ...(canDownloadCsv
      ? [
          {
            key: 'download',
            label: 'Export report to CSV',
            link: getTaskReportExportUrl({ taskId: task.id }),
            action: () => {},
            openLinkInNewTab: true,
          },
        ]
      : []),
    ...(showWorkedSolutionMenuItem
      ? [
          {
            key: 'worked-solutions',
            label: 'Worked Solutions',
            action: workedSolutionsModalOpen.setTrue,
          },
        ]
      : []),
    {
      key: 'delete-task',
      label: 'Delete task',
      action: () => setIsDeletingTask(true),
    },
  ];
  const fullShareTaskUrl = `${window.location.origin}${taskShareableUrl({
    taskId: task.id,
  })}`;
  const now = moment();
  const startDate = task.startDate;
  const dueDate = task.dueDate;
  const isActive =
    moment(startDate).isBefore(now) && moment(dueDate).isAfter(now);
  const isPast = moment(dueDate).isBefore(now);
  return (
    <Root>
      <PageTitle subtitles={[task.title, 'Task Report']} />
      <Container withFullWidthTable>
        <Stack.V gap={24}>
          <Row style={{ height: TOP_ROW_SIZE }}>
            {window.history.length >= 2 && !modalIsOpen && (
              <>
                <Button
                  size="extraLarge"
                  padding={0}
                  onClick={() => {
                    window.history.back();
                  }}
                  label="Go back"
                >
                  <ChevronLeftIcon />
                </Button>

                <VerticalDivider />
              </>
            )}

            <TaskBadge task={task} size={TOP_ROW_SIZE} />
            <TitleDetails>
              <Title>{task.title}</Title>
              <TaskTypeDetails>
                {getTaskIcon(task.type)}
                <Label>{taskTypeLabel(task.type)}</Label>
              </TaskTypeDetails>
            </TitleDetails>
          </Row>
          <FilterAndActionsWrapper>
            {shouldRenderClassGameButton && (
              <TaskDetailActionButton
                action={() => {
                  setGameModalContent('GameSelector');
                  trackStructEvent({
                    category: 'gamification_tug_of_war',
                    action: 'started_tug_of_war',
                  });
                }}
                type="primary"
              >
                Start class game
              </TaskDetailActionButton>
            )}

            {shouldRenderLiveModeLink && (
              <TaskDetailActionButton
                link={teacherClassActivityLiveUrl({ classId })}
                type={gamificationEnableTugOfWar ? undefined : 'primary'}
              >
                <Icons.Live />
                Live mode (Last {LIVE_MODE_DURATION_MIN} mins)
              </TaskDetailActionButton>
            )}

            <AssignedClassPicker
              taskId={task.id}
              assignedStudentsClasses={assignedStudentsClasses}
            />

            <TaskDetailActionButton
              action={() => {
                trackStructEvent({
                  category: 'task_report',
                  action: 'edited_task',
                  label: task.id,
                });
                setIsEditingTask(true);
              }}
            >
              <Icons.Pencil size={18} />
              {/** it should be 14px but there's some padding that makes it look smaller, so 18px is ideal */}
              Edit task
            </TaskDetailActionButton>

            {isTryable && (
              <TaskDetailActionButton
                link={taskShareableUrl({ taskId: task.id })}
              >
                Try task
              </TaskDetailActionButton>
            )}

            <TaskDetailActionButton
              action={() => {
                trackStructEvent({
                  action: 'begin_create_task',
                  category: 'task_report',
                  label: 'reassign_task_option',
                });
                setIsReassigningTask(true);
              }}
              label="Reassign task"
            >
              Reassign
            </TaskDetailActionButton>

            <ActionMenuWrapper ref={actionMenuButtonRef}>
              <TaskDetailActionButton
                action={openActionMenu}
                label="Open action menu"
              >
                <Horizontal>
                  More
                  <HorizontalSpace />
                  <TriangleDownIcon />
                </Horizontal>
              </TaskDetailActionButton>
              {isActionMenuOpen && (
                <DropdownMenu
                  anchorRef={actionMenuButtonRef}
                  menuPosition="bottom-left"
                  anchorOrigin="right"
                  items={dropdownItems}
                  onDismiss={closeActionMenu}
                />
              )}
              {isShareTaskPopoverOpen && (
                <Popover
                  anchorElementRef={actionMenuButtonRef}
                  popoverPosition="bottom-left"
                  anchorOrigin="right"
                  shouldDismissOnTapOut
                  shouldDismissOnScroll={false}
                  onDismiss={closeShareTaskPopover}
                >
                  <PopoverContentWrapper>
                    <ShareTaskTitle>Share this task</ShareTaskTitle>
                    <CopyLinkToClipboard fullTextBookLink={fullShareTaskUrl} />
                  </PopoverContentWrapper>
                </Popover>
              )}

              <WorkedSolutionModalContainer
                taskId={task.id}
                isOpen={workedSolutionsModalOpen.value}
                onClose={workedSolutionsModalOpen.setFalse}
                setIsReady={workedSolutionsModalReady.setTrue}
              />
            </ActionMenuWrapper>
          </FilterAndActionsWrapper>
          <Row>
            <Details>
              <Detail>
                <DetailLabel>Task Duration</DetailLabel>
                <DetailValue>
                  <TaskDate>
                    {isActive && (
                      <>
                        <ActiveBadge past={!isActive}>Active</ActiveBadge>
                        <HorizontalSpace />
                        <HorizontalSpace />
                      </>
                    )}
                    {isPast && (
                      <>
                        <ActiveBadge past>Past</ActiveBadge>
                        <HorizontalSpace />
                        <HorizontalSpace />
                      </>
                    )}
                    {moment(startDate).format(MOMENT_FORMAT_DATE)} –{' '}
                    {moment(dueDate).format(MOMENT_FORMAT_DATE)}
                  </TaskDate>
                </DetailValue>
              </Detail>

              <Detail>
                <DetailLabel>Expiry</DetailLabel>
                <DetailValue>
                  <TaskDate>
                    {moment(expiryDate).format(MOMENT_FORMAT_DATE)}
                  </TaskDate>
                </DetailValue>
              </Detail>

              <AlignToRight>
                {task.__typename === 'AdaptiveTask' &&
                  task.targetMastery != null && (
                    <Detail>
                      <DetailLabel>Target</DetailLabel>
                      <DetailValue>
                        {getMasteryLevelTitle(task.targetMastery)}
                      </DetailValue>
                    </Detail>
                  )}
                {task.__typename === 'NewAdaptiveExperienceTask' &&
                  task.newAdaptiveTargetMastery != null && (
                    <Detail>
                      <DetailLabel>Target</DetailLabel>
                      <DetailValue>
                        {getMasteryLevelTitle(task.newAdaptiveTargetMastery)}
                      </DetailValue>
                    </Detail>
                  )}
                {task.type !== 'CHECK_IN_TASK' &&
                  task.type !== 'STRAND_DIAGNOSTIC_CHECK_IN_TASK' && (
                    <Detail>
                      <DetailLabel>Area of Study</DetailLabel>
                      <DetailValue>
                        <AreaOfStudyLink
                          href={getAreaOfStudyLink({ task })}
                          target="_blank"
                          onClick={() =>
                            trackStructEvent({
                              category: 'task_report',
                              action: 'viewed_textbook',
                              label: task.id,
                            })
                          }
                        >
                          <>
                            <TextbookTeacherIcon />
                            <HorizontalSpace />
                            {getAreaOfStudyLabel(task)}
                          </>
                        </AreaOfStudyLink>
                      </DetailValue>
                    </Detail>
                  )}
              </AlignToRight>
            </Details>
          </Row>
        </Stack.V>
      </Container>

      {customTask.assignedProblems != null && (
        <CreateTaskModal
          isOpen={isCreatingTaskTemplate}
          onClose={() => setIsCreatingTaskTemplate(false)}
          preselection={TASK_TEMPLATE_PREVIEW_SCREEN}
          prefilledProblems={customTask.assignedProblems.map(problem => [
            problem.problemContent.id,
            problem.problemContent.estimatedCompletionTime,
            problem.problemContent.problemTemplateId,
            problem.problemContent.previewWorkoutCreationToken,
          ])}
          trackingEventOnCreation={{
            category: 'worksheet_template',
            action: 'create_template_from_task',
          }}
        />
      )}

      <ReassignTaskModal
        isOpen={isReassigningTask}
        onClose={() => setIsReassigningTask(false)}
        taskId={task.id}
        trackingEventOnCreation={{
          category: 'task_report',
          action: 'create_task',
          label: 'reassign_task_option',
        }}
      />

      <DeleteTaskFlyout
        assignedEntities={assignedEntities}
        isOpen={isDeletingTask}
        onClose={() => setIsDeletingTask(false)}
        task={task}
        onTaskMutated={() => {
          history.push(teacherClassBaseRedirectorPath);
        }}
      />
      <EditTaskFlyout
        isOpen={isEditingTask}
        onClose={() => setIsEditingTask(false)}
        taskId={task.id}
      />

      {gameModalContent !== null && (
        <GameModal
          content={gameModalContent}
          task={props.task}
          onClose={gameState =>
            setGameModalContent(prevContent => {
              assert(prevContent !== null);
              trackStructEvent({
                category: 'gamification_tug_of_war',
                action: 'closed_tug_of_war',
                label: prevContent,
                property: gameState,
              });
              return null;
            })
          }
          onContinue={newContent => {
            setGameModalContent(newContent);
            trackStructEvent({
              category: 'gamification_tug_of_war',
              action: 'continued_tug_of_war',
              label: newContent,
            });
          }}
        />
      )}
    </Root>
  );
}
graphql`
  fragment TaskDetail_task on TaskInterface @relay(mask: false) {
    __typename
    ... on AdaptiveTask {
      targetMastery
    }
    ... on NewAdaptiveExperienceTask {
      newAdaptiveTargetMastery: targetMastery
    }
    id
    title
    type
    ...TaskBadge_task
    expiryDate
    startDate
    dueDate
    areaOfStudy {
      __typename
      ... on Syllabus {
        id
        title
      }
      ... on Topic {
        id
        title
        syllabus {
          id
        }
      }
      ... on Subtopic {
        id
        title
        topic {
          id
          syllabus {
            id
          }
        }
      }
      ... on Strand {
        id
        title
      }
    }
  }
`;
graphql`
  fragment TaskDetail_assignedEntities on TaskInterface @relay(mask: false) {
    assignedStudents {
      id
      classes {
        edges {
          node {
            id
          }
        }
      }
      user {
        firstName
        lastName
        avatar
      }
    }
    assignedClasses {
      id
      title
    }
  }
`;
graphql`
  fragment TaskDetail_customTask on CustomTask {
    assignedProblems {
      problemContent {
        id
        estimatedCompletionTime
        problemTemplateId
        previewWorkoutCreationToken
      }
    }
  }
`;
const ACTION_BUTTON_CUSTOM_STYLE = {
  fontWeight: fontWeight.normal,
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  paddingLeft: 16,
  paddingRight: 16,
};

function TaskDetailActionButton({
  action,
  label,
  children,
  link,
  type = 'secondary',
}: {
  action?: () => void;
  label?: string;
  children: React.ReactNode;
  link?: string;
  type?: ButtonStyleProps['type'];
}) {
  const commonProps = {
    isRound: true,
    size: 'small',
    type,
    onClick: action,
    label,
    color: type === 'secondary' ? 'grey' : undefined,
    styles: ACTION_BUTTON_CUSTOM_STYLE,
  } as const;
  return link == null ? (
    <Button {...commonProps}>{children}</Button>
  ) : (
    <AnchorButton {...commonProps} href={link}>
      {children}
    </AnchorButton>
  );
}

function WorkedSolutionModalContainer({
  taskId,
  isOpen,
  onClose,
  setIsReady,
}: {
  taskId: string;
  isOpen: boolean;
  onClose: () => void;
  setIsReady: () => void;
}) {
  const { props } = useQuery<TaskDetailWorkedSolutionsModalContainerQuery>(
    graphql`
      query TaskDetailWorkedSolutionsModalContainerQuery($taskId: ID!) {
        task(id: $taskId) {
          __typename
          ... on CustomTask {
            title
            assignedProblems {
              problemContent {
                ...SolutionModal_problemContent
              }
            }
          }

          ... on AdaptiveTask {
            title
            assignedProblems {
              problemContent {
                ...SolutionModal_problemContent
              }
            }
          }

          ... on NewAdaptiveExperienceTask {
            title
            assignedProblems {
              problemContent {
                ...SolutionModal_problemContent
              }
            }
          }
        }
      }
    `,
    { taskId },
  );

  const task =
    props?.task != null && props.task.__typename !== '%other'
      ? props.task
      : null;

  const problemContents = task?.assignedProblems?.map(
    ({ problemContent }) => problemContent,
  );

  useEffect(() => {
    if (task != null) setIsReady();
  }, [task, setIsReady]);

  if (problemContents != null)
    return (
      <SolutionModal
        problemContentsKeys={problemContents}
        hintsVisibilityStrategy="toggleable"
        open={isOpen}
        onClose={onClose}
        printViewUrl={getTaskReportQuestionsWorkedSolutionsPrintUrl({ taskId })}
      />
    );
  return null;
}
