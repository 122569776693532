import { useCallback, useMemo, useState } from 'react';

import EyeIcon from 'ms-components/icons/Eye';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useUserPreference } from 'ms-helpers/UserPreferenceContext';
import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import type { StepNumber } from 'ms-pages/Teacher/components/CreateTask';
import AdaptiveTaskDetailsForm, {
  MultipleAdaptiveTaskDetailsForm,
} from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask';
import { NEW_ADAPTIVE_EXPERIENCE_NO_TARGET_MASTERY_VALUE } from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state';
import MultiSubtopicDisplay from 'ms-pages/Teacher/components/CreateTask/CreateBulkAdaptiveTask/MultiSubtopicDisplay';
import ReviewbulkAdaptiveTasks from 'ms-pages/Teacher/components/CreateTask/CreateBulkAdaptiveTask/ReviewBulkAdaptiveTasks';
import {
  Body,
  BODY_PADDING,
} from 'ms-pages/Teacher/components/CreateTask/components/CreateTaskLayout';
import SkillSelection from 'ms-pages/Teacher/components/CreateTask/components/SkillSelection';
import SubtopicNavbar from 'ms-pages/Teacher/components/CreateTask/components/SubtopicNavbar';
import type { SelectedSubtopic } from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelector';
import SubtopicTitle from 'ms-pages/Teacher/components/CreateTask/flows/BulkAdaptiveFlow/components/SubtopicTitle';
import {
  useBulkAdaptiveFlowState,
  usePreviewAndTaskDetailsState,
  useSaveBulkAdaptiveTasks,
} from 'ms-pages/Teacher/components/CreateTask/flows/BulkAdaptiveFlow/hooks';
import { SidebarAndPreview } from 'ms-pages/Teacher/components/CreateTask/flows/components/SidebarAndPreview';
import { useIsWithSkillCheckinStep } from 'ms-pages/Teacher/components/CreateTask/helpers';
import {
  STEP_NAME_MAPS,
  getStepHeaderInfo,
} from 'ms-pages/Teacher/components/CreateTask/state/createTaskState';
import {
  useCreateTaskDispatch,
  useCreateTaskState,
} from 'ms-pages/Teacher/components/CreateTask/state/useTaskState';
import CreateTaskModalHeader from 'ms-pages/Teacher/components/CreateTaskModal/components/CreateTaskModalHeader';
import { assignableSubtopicForAdaptivePractice } from 'ms-pages/Teacher/components/TopicSubtopicTree/subtopicFilters';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { styledVerticallyScrollable } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import { unwrap } from 'ms-utils/typescript-utils';

import { TaskPreviewDrawer } from '../components/TaskPreviewDrawer';

type Props = {
  prefilledClasses?: ReadonlyArray<ClassSelectionPayload> | undefined;
  prefilledStudents?: ReadonlyArray<StudentSelectionPayload> | undefined;
  prefilledExcludedStudents?:
    | ReadonlyArray<StudentSelectionPayload>
    | undefined;
  prefilledStartDate?: Date | undefined;
  prefilledDueDate?: Date | undefined;
  prefilledSubtopic?: SelectedSubtopic | null | undefined;
  prefilledTargetMastery?: number | null | undefined;
  preselectedStepNumber?: StepNumber | undefined;
  taskCreationCallback: () => void;
  currentSyllabusId: string;
  setCurrentSyllabusId: (syllabusId: string) => void;
};

export default function BulkAdaptiveFlow({
  prefilledClasses,
  prefilledStudents,
  prefilledExcludedStudents,
  prefilledStartDate,
  prefilledDueDate,
  prefilledSubtopic,
  prefilledTargetMastery,
  taskCreationCallback,
  currentSyllabusId,
  setCurrentSyllabusId,
}: Props) {
  // this is the parent flow context
  const {
    selectedSubtopics: [_selectedSubtopics, setSelectedSubtopics],
    bulkAdaptiveTask: [getState, createBulkStateInstance],
    createAdaptiveTask: [seedState],
  } = useBulkAdaptiveFlowState({
    prefilledClasses,
    prefilledStudents,
    prefilledExcludedStudents,
    prefilledStartDate,
    prefilledDueDate,
    prefilledSubtopic,
    prefilledTargetMastery:
      prefilledTargetMastery || NEW_ADAPTIVE_EXPERIENCE_NO_TARGET_MASTERY_VALUE,
  });

  const [firstSelectedSubtopic] = _selectedSubtopics;
  // Selected subtopic for subtopic selector
  const [activeSubtopic, setActiveSubtopic] = useState<
    SelectedSubtopic | null | undefined
  >(firstSelectedSubtopic ?? null);
  const { questionsCollapsed, setQuestionsCollapsed } = useUserPreference();

  const [selectedSubtopics, selectedSubtopicIds] = useMemo(() => {
    const ss =
      _selectedSubtopics.length > 0
        ? _selectedSubtopics
        : activeSubtopic
        ? [activeSubtopic]
        : [];

    const ssi = ss.map(s => s.id);
    return [ss, ssi];
  }, [_selectedSubtopics, activeSubtopic]);

  const withSkillCheckinStep = useIsWithSkillCheckinStep(selectedSubtopics);

  const { stepNumber, editingTaskId: isEditingTaskSubtopicId } =
    useCreateTaskState();
  const bulkStateInstance = useMemo(() => {
    return isEditingTaskSubtopicId != null
      ? createBulkStateInstance(isEditingTaskSubtopicId, {})
      : null;
  }, [createBulkStateInstance, isEditingTaskSubtopicId]);

  const dispatch = useCreateTaskDispatch();

  const { save, savedItems, saving, erroredItems } = useSaveBulkAdaptiveTasks({
    selectedSubtopicIds,
    getState,
    onAllSaved: useCallback(() => {
      dispatch({ type: 'create' });
      taskCreationCallback();

      // Neither of these functions need to be checked for changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  });

  const steps = STEP_NAME_MAPS.adaptive({
    withSkillCheckinStep,
  });

  const stepName = unwrap(steps[stepNumber]);

  const { value: isTaskPreviewOpen, toggle: toggleTaskPreview } =
    useBoolean(false);
  const { trackStructEvent } = useSnowplow();

  const [
    previewActiveSelectedSubtopic,
    setPreviewActiveSelectedSubtopic,
    isPreviewStepFloatingButtonEnabled,
    setIsPreviewStepFloatingButtonEnabled,
    previewActiveSelectedSubtopicIndex,
    handlePreviewActiveSelectedSubtopicIndexIncrease,
    handlePreviewActiveSelectedSubtopicIndexDecrease,
  ] = usePreviewAndTaskDetailsState({
    selectedSubtopics,
    stepName,
  });

  const nextButtonDisabledStates = {
    subtopic_selector: selectedSubtopicIds.length === 0,
    preview_and_task_details:
      !isPreviewStepFloatingButtonEnabled ||
      saving ||
      Object.keys(savedItems).length === selectedSubtopicIds.length,
  };

  let stepTitles = getStepHeaderInfo({
    withSkillCheckinStep,
    selectedFlow: 'adaptive',
  });

  return (
    <>
      <CreateTaskModalHeader
        // OCTO-10508 - Toggle question preview collapse
        // Change the title to 'Continue' while this test is running as it makes
        // more sense than 'Preview' as we're initially hiding the questions of the next step
        stepTitles={stepTitles.map(title =>
          title === 'Preview' ? 'Continue' : title,
        )}
        steps={steps}
        nextButtonDisabledStates={nextButtonDisabledStates}
        withSkillCheckinStep={withSkillCheckinStep}
        onLastStepAction={save}
        numberOfSelections={_selectedSubtopics.length}
        selectionTypeString={
          _selectedSubtopics.length > 1 ? 'subtopics' : 'subtopic'
        }
      />

      {stepName === 'subtopic_selector' && (
        <SidebarAndPreview
          taskType="adaptive"
          activeSubtopic={activeSubtopic ?? _selectedSubtopics[0] ?? null}
          setActiveSubtopic={setActiveSubtopic}
          syllabusId={currentSyllabusId}
          setSyllabusId={setCurrentSyllabusId}
          setSelectedSubtopics={setSelectedSubtopics}
          selectedSubtopics={_selectedSubtopics}
          subtopicFilter={assignableSubtopicForAdaptivePractice}
          enableMultipleSelection
          noHorizontalSubtopicBodyPadding
        >
          <MultiSubtopicDisplay
            selectedSubtopics={activeSubtopic ? [activeSubtopic.id] : []}
            hideDifficultyFilter
            isHeaderSticky
            stickyHeaderTopOffset={-BODY_PADDING}
            hasSelfHorizontalPadding
          />
        </SidebarAndPreview>
      )}
      {stepName === 'preview_and_task_details' && (
        <>
          {selectedSubtopics.length > 1 &&
            previewActiveSelectedSubtopic != null &&
            previewActiveSelectedSubtopicIndex >= 0 && (
              <SubtopicNavbar
                activeSubtopicIndex={previewActiveSelectedSubtopicIndex}
                numberOfTasks={selectedSubtopics.length}
                onRightArrowClick={
                  handlePreviewActiveSelectedSubtopicIndexIncrease
                }
                onLeftArrowClick={
                  handlePreviewActiveSelectedSubtopicIndexDecrease
                }
              />
            )}
          <Body
            noFooterSpaceAtBottom
            isVerticallyScrollable={false}
            isVerticalOverflowHidden
            whiteBackground={false}
            style={{
              backgroundColor: colors.seashell,
              padding: 0,
            }}
          >
            {previewActiveSelectedSubtopic != null &&
              previewActiveSelectedSubtopicIndex >= 0 && (
                <div
                  style={{
                    display: 'flex',
                    height: '100%',
                    overflowY: 'hidden', // to hand over the scrolling to child elements
                  }}
                >
                  <TaskPreviewDrawer
                    open={isTaskPreviewOpen}
                    styles={{
                      height: '100%',
                      backgroundColor: colors.white,
                    }}
                  >
                    <div style={{ padding: 16 }}>
                      <Button
                        styles={{ gap: 8 }}
                        isRound
                        type="secondary"
                        onClick={() => {
                          trackStructEvent({
                            category: 'create_task_from_anywhere',
                            action: 'toggled_questions_preview',
                            // We're not tracking the current state, but the teacher's intention i.e
                            // when the teacher clicks this button and the panel is closed they want to show the questions
                            label: isTaskPreviewOpen
                              ? 'hide_questions'
                              : 'show_questions',
                          });
                          toggleTaskPreview();
                        }}
                      >
                        <EyeIcon size={12} />
                        {isTaskPreviewOpen ? 'Hide task' : 'Preview task'}
                      </Button>
                    </div>

                    <div
                      style={{
                        display: isTaskPreviewOpen ? 'block' : 'none',
                      }}
                    >
                      <SubtopicTitle
                        title={previewActiveSelectedSubtopic.title}
                        showRemoveButton={selectedSubtopics.length > 1}
                        onRemoveSubtopic={() => {
                          const subtopicId = previewActiveSelectedSubtopic.id;
                          const subtopicIndex = selectedSubtopics.findIndex(
                            ({ id }) => id === subtopicId,
                          );

                          if (subtopicIndex > 0) {
                            setPreviewActiveSelectedSubtopic(
                              selectedSubtopics[subtopicIndex - 1],
                            );
                          } else if (
                            subtopicIndex === 0 &&
                            selectedSubtopics.length > 1
                          ) {
                            setPreviewActiveSelectedSubtopic(
                              selectedSubtopics[subtopicIndex + 1],
                            );
                          } else {
                            setPreviewActiveSelectedSubtopic(null);
                          }

                          setSelectedSubtopics(
                            selectedSubtopics.filter(
                              ({ id: subId }) => subId !== subtopicId,
                            ),
                          );
                        }}
                      />
                      <div
                        style={{
                          display: 'flex',
                          padding: '8px 16px 16px 16px',
                          width: '100%',
                        }}
                      >
                        <Button
                          size="regular"
                          type="tertiary"
                          onClick={() => {
                            trackStructEvent({
                              category: 'create_task_from_anywhere',
                              action: questionsCollapsed
                                ? 'clicked_expand_all_questions'
                                : 'clicked_collapse_all_questions',
                              label: 'bulk_adaptive_flow',
                              value: activeSubtopic?.id ?? '',
                            });
                            setQuestionsCollapsed(s => !s);
                          }}
                          padding={0}
                          height={22}
                        >
                          {questionsCollapsed ? 'Expand All' : 'Collapse All'}
                        </Button>
                      </div>
                    </div>
                    <div
                      style={{
                        flexGrow: 1,
                        width: '100%',
                        height: '100%',
                        display: isTaskPreviewOpen ? 'block' : 'none',
                        padding: '0 0 0 16px',
                        ...styledVerticallyScrollable,
                      }}
                    >
                      <MultiSubtopicDisplay
                        selectedSubtopics={
                          previewActiveSelectedSubtopic
                            ? [previewActiveSelectedSubtopic.id]
                            : []
                        }
                        hideExpandButton
                        questionsCollapsed={questionsCollapsed}
                        hideSubtopicHeader
                      />
                    </div>
                  </TaskPreviewDrawer>

                  <div
                    style={{
                      flexGrow: 1,
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        padding: '24px 16px',
                        ...styledVerticallyScrollable,
                        width: isTaskPreviewOpen ? '100%' : '50%',
                      }}
                    >
                      <MultipleAdaptiveTaskDetailsForm
                        activeSubtopic={previewActiveSelectedSubtopic}
                        subtopics={selectedSubtopics}
                        stateFactory={createBulkStateInstance}
                        onFormCompletionChange={
                          setIsPreviewStepFloatingButtonEnabled
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
          </Body>
        </>
      )}
      {stepName !== 'preview_and_task_details' &&
        stepName !== 'subtopic_selector' && (
          <Body
            noFooterSpaceAtBottom
            style={{
              width: '100%',
            }}
          >
            {stepName === 'edit_adaptive_bulk_details' && (
              <AdaptiveTaskDetailsForm state={seedState} noTitleField />
            )}
            {stepName === 'review_bulk_adaptive' &&
              isEditingTaskSubtopicId == null && (
                <ReviewbulkAdaptiveTasks
                  subtopicSaveDictionary={savedItems}
                  subtopicSaveErrorDictionary={erroredItems}
                  subtopics={selectedSubtopics}
                  stateFactory={createBulkStateInstance}
                />
              )}
            {stepName === 'bulk_adaptive_post_review_edit' &&
              isEditingTaskSubtopicId != null &&
              bulkStateInstance != null && (
                <AdaptiveTaskDetailsForm state={bulkStateInstance} />
              )}
            {stepName === 'skill_check_ins_selection' && (
              <SkillSelection
                subtopicId={unwrap(selectedSubtopics[0]).id}
                assignedCheckinSkillIds={
                  seedState.values.assignedCheckinSkillIds
                }
                setAssignedCheckinSkillIds={
                  seedState.updaters.setAssignedCheckinSkillIds
                }
                // save={save}
              />
            )}
          </Body>
        )}
    </>
  );
}
