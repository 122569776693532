import type { CustomMiloMonthlyChallengeState } from 'ms-pages/StudentDashboard/SunflowerStudentDashboard/SunflowerStudentDashboardHeader/SunflowerStudentDashboardHeader';

import {
  DESTINATIONS_MONTH_MAP,
  type Destination,
  type AvailableMonth,
} from './config';
import type { MonthYear } from './types';

export function getDestination(monthYear: MonthYear): Destination {
  return DESTINATIONS_MONTH_MAP[monthYear] ?? 'Tokyo';
}

export type CustomMiloMonthlyExpeditionItemsState = Record<
  AvailableMonth,
  {
    get: CustomMiloMonthlyChallengeState;
    set: (v: CustomMiloMonthlyChallengeState) => void;
  }
>;
