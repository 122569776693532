import moment from 'moment';
import { useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, graphql } from 'relay-hooks';

import { useRecordedLastStudentViewUrl } from 'ms-components/RecordLastStudentViewUrl';
import { useSnowplow } from 'ms-helpers/Snowplow';
import type { QuestionResult } from 'ms-helpers/Snowplow/Types/lantern/student';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { StudentCheckInLayout } from 'ms-pages/Lantern/components/StudentLayout';
import type { MathExpressionQuestionAnswer } from 'ms-pages/Lantern/components/shared-with-diagnostic-questions-editor/MathExpressionQuestion';
import type { MultipleChoiceQuestionAnswer } from 'ms-pages/Lantern/components/shared-with-diagnostic-questions-editor/MultipleChoiceQuestion';
import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';
import { DIAGNOSE_PHASE_QUESTION_LIMIT } from 'ms-pages/Lantern/utils/constants';
import { isDebugMode } from 'ms-pages/Lantern/utils/debug';
import { getCheckInStrandUrl } from 'ms-pages/Lantern/utils/urls';
import type { CheckinCurriculumInformation } from 'ms-pages/Lantern/views/Student/CheckIn/CheckInHeader';
import { NotFoundError } from 'ms-utils/app-logging';
import { sessionStorageDb } from 'ms-utils/localStorageDb';
import type { Writable } from 'ms-utils/typescript-utils';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import {
  CheckInSessionExitButton,
  CheckInSessionMainPresentational,
  DebugActions,
} from './CheckInSession';
import { ExitCheckInModal } from './ExitCheckInModal';
import type {
  CheckInQuery,
  CheckInQueryResponse,
  LanternCheckInType,
} from './__generated__/CheckInQuery.graphql';
import type { CheckInSessionMathExpressionQuestion } from './__generated__/CheckInSessionMathExpressionQuestion.graphql';
import type { CheckInSessionMultipleChoiceQuestion } from './__generated__/CheckInSessionMultipleChoiceQuestion.graphql';
import type { StudentAnswer } from './types';
import useNewStyleSkipQuestion from './useNewStyleSkipQuestion';
import useNewStyleSubmitCorrectAnswer from './useNewStyleSubmitCorrectAnswer';
import useNewStyleSubmitMathExpressionAnswer from './useNewStyleSubmitMathExpressionAnswer';
import useNewStyleSubmitMultipleChoiceAnswer from './useNewStyleSubmitMultipleChoiceAnswer';
import { getQuestionDuration } from './utils';

export const STRAND_DIAGNOSTIC_CHECKIN_TITLE = 'Discovery check-in';
export const STRAND_DIAGNOSTIC_CHECKIN_DESCRIPTION =
  "This first check-in will be a longer one, allowing you to find the skills that you're strong at and the ones you can grow in.";
export const SUBSTRAND_GROWTH_CHECKIN_TITLE = 'Growth check-in';
export const SUBSTRAND_GROWTH_CHECKIN_DESCRIPTION =
  'Growth check-ins find the mathematical skills you have grown in';
export const SKILL_CHECKIN_TITLE = 'Skill check-in';
export const SKILL_CHECKIN_DESCRIPTION =
  'Skill check-ins show your growth in a single mathematical skill';
export const LAST_CHECKIN_EXIT_KEY = '__LAST_CHECKIN_EXIT_KEY';
function getHeaderProps({ checkInType }: { checkInType: LanternCheckInType }): {
  title: string;
  description: string;
} {
  switch (checkInType) {
    case 'STRAND_DIAGNOSTIC_CHECKIN': {
      return {
        title: STRAND_DIAGNOSTIC_CHECKIN_TITLE,
        description: STRAND_DIAGNOSTIC_CHECKIN_DESCRIPTION,
      };
    }
    case 'SUBSTRAND_GROWTH_CHECKIN': {
      return {
        title: SUBSTRAND_GROWTH_CHECKIN_TITLE,
        description: SUBSTRAND_GROWTH_CHECKIN_DESCRIPTION,
      };
    }
    case 'SKILL_CHECKIN': {
      return {
        title: SKILL_CHECKIN_TITLE,
        description: SKILL_CHECKIN_DESCRIPTION,
      };
    }
    case 'PRE_TOPIC_TEST_CHECKIN': {
      return {
        title: 'Pre-topic test',
        description:
          'This check-in will help prepare you for the upcoming topic',
      };
    }
    case 'POST_TOPIC_TEST_CHECKIN': {
      return {
        title: 'Post-topic test',
        description:
          'This check-in will help you see how much you have learned from the topic',
      };
    }
    // Deprecated types of check-in
    case 'ALL_STRANDS_DIAGNOSTIC_CHECKIN':
    case 'HISTORIC_DIAGNOSTIC_CHECKIN':
    case 'HISTORIC_GROWTH_CHECKIN':
    case 'STRAND_GROWTH_CHECKIN': {
      return {
        title: 'Check-in',
        description:
          'Doing regular check-ins helps you track your growth across mathematical skills',
      };
    }
    default: {
      assertUnreachable(
        checkInType,
        `Unknown check-in type: ${JSON.stringify(checkInType)}`,
      );
    }
  }
}
// Longer check-ins will have their progress displayed in sets
// of questions of this size.
export const QUESTION_SET_SIZE = 10;
export type DemoInformation = {
  curriculum: CheckinCurriculumInformation;
};
type Props = {
  // Used by demo student accounts (try mathspace without an account)
  demoInformation?: DemoInformation | undefined;
};
function CheckIn({ demoInformation }: Props) {
  const { checkInId } = useParams<{
    checkInId: string;
  }>();
  const { props, error } = useQuery<CheckInQuery>(
    graphql`
      query CheckInQuery($checkInId: ID!) {
        lantern {
          checkIn(id: $checkInId) {
            id
            type
            strand {
              id
              title
            }
            substrand {
              title
            }
            currentQuestion {
              # If we remove unmasking (and request the id) the generated types won't be a union
              ...CheckInSessionQuestion @relay(mask: false)
            }
            endedAt
            numberOfQuestions
            questionsCompleted
          }
        }
      }
    `,
    { checkInId },
  );
  if (error != null) throw new Error(`CheckInQuery failed: ${error.message}`);
  if (props == null) return <LoadingSpinner />;
  const {
    lantern: { checkIn },
  } = props;
  if (checkIn == null) {
    throw new NotFoundError('CheckIn not found');
  }
  // TODO: Uncomment this and remove `|| DIAGNOSE_PHASE_QUESTION_LIMIT` below
  // when `numberOfQuestions` type is non-nullable (OCTO-7980).
  // `numberOfQuestions` is set for all resumable check-ins.
  // if (checkIn.numberOfQuestions == null) {
  //   throw new Error('Check-in does not have `numberOfQuestions` specified.');
  // }
  if (
    checkIn.currentQuestion == null ||
    checkIn.currentQuestion?.__typename === '%other'
  ) {
    throw new Error("Check-in's `currentQuestion` has an invalid type");
  }
  return (
    <StudentCheckInLayout backgroundColor="almond50">
      <CheckInSession
        checkInId={checkIn.id}
        checkInType={checkIn.type}
        checkInStrand={checkIn.strand}
        checkInEndedAt={checkIn.endedAt}
        currentQuestion={checkIn.currentQuestion}
        numberOfQuestions={
          checkIn.numberOfQuestions || DIAGNOSE_PHASE_QUESTION_LIMIT
        }
        questionsCompleted={checkIn.questionsCompleted}
        curriculumInformation={demoInformation?.curriculum}
      />
    </StudentCheckInLayout>
  );
}
type ErrorMessage = string;
type State = {
  studentAnswer: StudentAnswer;
  isSubmitting: boolean;
  errorMessage: ErrorMessage | null | undefined;
  isExitModalOpen: boolean;
  questionAtWhichSuccessScreenLastSeen: number;
};
const initialState = {
  studentAnswer: null,
  isSubmitting: false,
  errorMessage: null,
  isExitModalOpen: false,
  questionAtWhichSuccessScreenLastSeen: 0,
};
type Action =
  | {
      type: 'setStudentAnswer';
      studentAnswer: StudentAnswer;
    }
  | {
      type: 'startSubmitting';
    }
  | {
      type: 'stopSubmitting';
    }
  | {
      type: 'setErrorMessage';
      errorMessage: ErrorMessage;
    }
  | {
      type: 'openExitModal';
    }
  | {
      type: 'closeExitModal';
    }
  | {
      type: 'setQuestionAtWhichSuccessScreenLastSeen';
      questionNumber: number;
    };
function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'setStudentAnswer': {
      return { ...state, studentAnswer: action.studentAnswer };
    }
    case 'startSubmitting': {
      return {
        ...state,
        isSubmitting: true,
        errorMessage: null,
      };
    }
    case 'stopSubmitting': {
      return {
        ...state,
        isSubmitting: false,
      };
    }
    case 'setErrorMessage': {
      return { ...state, errorMessage: action.errorMessage };
    }
    case 'openExitModal': {
      return { ...state, isExitModalOpen: true };
    }
    case 'closeExitModal': {
      return { ...state, isExitModalOpen: false };
    }
    case 'setQuestionAtWhichSuccessScreenLastSeen': {
      return {
        ...state,
        questionAtWhichSuccessScreenLastSeen: action.questionNumber,
      };
    }
    default:
      assertUnreachable(
        action,
        `Exhaustiveness guarantee violated at runtime by action: ${JSON.stringify(
          action,
        )}`,
      );
  }
}
// An error could be of type `Error`, or a mutation response error,
// i.e. `{| +key: ErrorEnum, +message: string |}`
function makeErrorMessage(
  errors: ReadonlyArray<{
    readonly message: string;
  }>,
): string {
  return errors.map(e => e.message).join(' ');
}
const GENERIC_ERROR_MESSAGE = 'Something went wrong. Try again.';
function CheckInSession({
  checkInId,
  checkInType,
  checkInStrand,
  checkInEndedAt,
  currentQuestion,
  numberOfQuestions,
  questionsCompleted,
  curriculumInformation,
}: {
  checkInId: string;
  checkInType: LanternCheckInType;
  checkInStrand: NonNullable<
    CheckInQueryResponse['lantern']['checkIn']
  >['strand'];
  checkInEndedAt: NonNullable<
    CheckInQueryResponse['lantern']['checkIn']
  >['endedAt'];
  currentQuestion:
    | CheckInSessionMathExpressionQuestion
    | CheckInSessionMultipleChoiceQuestion;
  numberOfQuestions: number;
  questionsCompleted: number;
  // Used only for student demo purposes. Do not use in logged in contexts
  curriculumInformation?: CheckinCurriculumInformation | undefined;
}) {
  const {
    featureFlags: { demoCheckin },
    isTeacherStudent,
  } = useViewer();
  const { trackStructEvent } = useSnowplow();
  const history = useHistory();
  const questionId = currentQuestion.id;
  const canAccessDemoTools = isDebugMode || demoCheckin || isTeacherStudent;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [lastQuestionId, setLastQuestionId] = useState<string | null>(null);
  const [hasAnsweredQuestionsThisSession, setHasAnsweredQuestionsThisSession] =
    useState(false);
  const [lastInteractionTime, setLastInteractionTime] = useState(new Date());
  const [skipQuestion] = useNewStyleSkipQuestion();
  const [submitCorrectAnswer] = useNewStyleSubmitCorrectAnswer();
  const [submitMultipleChoiceAnswer] = useNewStyleSubmitMultipleChoiceAnswer();
  const [submitMathExpressionAnswer] = useNewStyleSubmitMathExpressionAnswer();
  useEffect(() => {
    setLastInteractionTime(new Date());
  }, [questionId]);
  useEffect(() => {
    if (lastQuestionId != null && questionId !== lastQuestionId) {
      setHasAnsweredQuestionsThisSession(true);
    }
    setLastQuestionId(questionId);
  }, [lastQuestionId, questionId]);
  const lastStudentViewUrl = useRecordedLastStudentViewUrl();
  function handleSkipQuestion() {
    const duration = getQuestionDuration(lastInteractionTime);
    dispatch({ type: 'startSubmitting' });
    dispatch({ type: 'setStudentAnswer', studentAnswer: null });
    skipQuestion({
      checkInId,
      questionId,
      duration,
      strandId: checkInStrand?.id || '',
      includeStrandStatus: checkInStrand?.id != null,
    })
      .then(({ newStyleSkipQuestion: { questionAnswer, errors } }) => {
        dispatch({ type: 'stopSubmitting' });
        if (questionAnswer?.checkIn?.endedAt != null) {
          trackStructEvent({
            category: 'student_check_in',
            action: 'completed_check_in',
          });
        }
        if (errors.length > 0) {
          dispatch({
            type: 'setErrorMessage',
            errorMessage: makeErrorMessage(errors),
          });
        }
      })
      .catch(() => {
        dispatch({ type: 'stopSubmitting' });
        dispatch({
          type: 'setErrorMessage',
          errorMessage: GENERIC_ERROR_MESSAGE,
        });
      });
    trackStructEvent({
      category: 'student_check_in',
      action: 'skip_question',
      label: questionId,
      value: duration / 1000,
    });
  }
  function handleSubmitCorrectAnswer() {
    dispatch({ type: 'startSubmitting' });
    submitCorrectAnswer({
      checkInId,
      questionId,
      strandId: checkInStrand?.id || '',
      includeStrandStatus: checkInStrand?.id != null,
    })
      .then(({ newStyleSubmitCorrectAnswer: { errors } }) => {
        dispatch({ type: 'stopSubmitting' });
        if (errors.length > 0) {
          dispatch({
            type: 'setErrorMessage',
            errorMessage: makeErrorMessage(errors),
          });
        }
      })
      .catch(errors => {
        dispatch({ type: 'stopSubmitting' });
        dispatch({
          type: 'setErrorMessage',
          errorMessage: makeErrorMessage(errors),
        });
      });
  }
  function handleSubmitAnswer() {
    const duration = getQuestionDuration(lastInteractionTime);
    dispatch({ type: 'startSubmitting' });
    const { __typename } = currentQuestion;
    switch (__typename) {
      case 'MathExpressionQuestion': {
        const answer = state.studentAnswer ?? '';
        assertIsMathExpressionAnswer(answer);
        submitMathExpressionAnswer({
          answer,
          checkInId,
          duration,
          questionId,
          strandId: checkInStrand?.id || '',
          includeStrandStatus: checkInStrand?.id != null,
        })
          .then(
            ({
              newStyleSubmitMathExpressionAnswer: { questionAnswer, errors },
            }) => {
              dispatch({ type: 'stopSubmitting' });
              if (questionAnswer != null) {
                dispatch({ type: 'setStudentAnswer', studentAnswer: null });
                const property = questionAnswer.result.toLowerCase();
                assertIsQuestionResultForSnowplow(property);
                trackStructEvent({
                  category: 'student_check_in',
                  action: 'submit_answer',
                  label: questionId,
                  value: duration / 1000,
                  property,
                });
                if (questionAnswer.checkIn?.endedAt != null) {
                  trackStructEvent({
                    category: 'student_check_in',
                    action: 'completed_check_in',
                  });
                }
              }
              if (errors.length > 0) {
                dispatch({
                  type: 'setErrorMessage',
                  errorMessage: makeErrorMessage(errors),
                });
              }
            },
          )
          .catch(() => {
            dispatch({ type: 'stopSubmitting' });
            dispatch({
              type: 'setErrorMessage',
              errorMessage: GENERIC_ERROR_MESSAGE,
            });
          });
        break;
      }
      case 'MultipleChoiceQuestion': {
        const answer = state.studentAnswer ?? [];
        assertIsMultipleChoiceAnswer(answer);
        // TODO should be able to remove this once we use 1st party
        // TS type generation for relay-compiler
        // @ts-expect-error
        const relayFixedAnswer: Writable<typeof answer> = answer;
        submitMultipleChoiceAnswer({
          answer: relayFixedAnswer,
          checkInId,
          duration,
          questionId,
          strandId: checkInStrand?.id || '',
          includeStrandStatus: checkInStrand?.id != null,
        })
          .then(
            ({
              newStyleSubmitMultipleChoiceAnswer: { questionAnswer, errors },
            }) => {
              dispatch({ type: 'stopSubmitting' });
              if (questionAnswer != null) {
                dispatch({ type: 'setStudentAnswer', studentAnswer: null });
                const property = questionAnswer.result.toLowerCase();
                assertIsQuestionResultForSnowplow(property);
                trackStructEvent({
                  category: 'student_check_in',
                  action: 'submit_answer',
                  label: questionId,
                  value: duration / 1000,
                  property,
                });
                if (questionAnswer.checkIn?.endedAt != null) {
                  trackStructEvent({
                    category: 'student_check_in',
                    action: 'completed_check_in',
                  });
                }
              }
              if (errors.length > 0) {
                dispatch({
                  type: 'setErrorMessage',
                  errorMessage: makeErrorMessage(errors),
                });
              }
            },
          )
          .catch(() => {
            dispatch({ type: 'stopSubmitting' });
            dispatch({
              type: 'setErrorMessage',
              errorMessage: GENERIC_ERROR_MESSAGE,
            });
          });
        break;
      }
      default:
        assertUnreachable(
          __typename,
          `Exhaustiveness guarantee violated at runtime by question type: ${JSON.stringify(
            __typename,
          )}`,
        );
    }
  }
  const canResumeCheckIn =
    checkInType === 'ALL_STRANDS_DIAGNOSTIC_CHECKIN' ||
    checkInType === 'STRAND_DIAGNOSTIC_CHECKIN';
  const exitCheckIn = () => {
    trackStructEvent({
      category: 'student_check_in',
      action: 'exit_checkin',
      value: questionsCompleted,
    });
    // Record details of this checkin upon exit, so that feedback can be sought
    // from the snackbar in the dashboard
    sessionStorageDb.set(LAST_CHECKIN_EXIT_KEY, {
      checkinExit: moment(),
      checkInId,
    });
    window.location.assign(lastStudentViewUrl);
  };
  // This condition has grown quite complex.  It means:
  // We want to show the question set success screen at the end of every question set
  // except on a freshly resumed check-in (nothing submitted yet this session), and
  // also to avoid showing it at the beginning of the check-in, or at the end (when the
  // full diagnostic success screens should be shown instead))
  const shouldShowQuestionSetComplete =
    questionsCompleted % QUESTION_SET_SIZE === 0 &&
    questionsCompleted !== numberOfQuestions &&
    state.questionAtWhichSuccessScreenLastSeen !== questionsCompleted &&
    hasAnsweredQuestionsThisSession;
  // Completed check-in: either a historic check-in or a new style check-in
  const shouldShowCheckInComplete = checkInEndedAt != null;
  const { title: headerTitle, description: headerDescription } = getHeaderProps(
    { checkInType },
  );
  return (
    <>
      <CheckInSessionExitButton
        onExit={
          demoCheckin
            ? undefined
            : canResumeCheckIn && !shouldShowCheckInComplete
            ? () => dispatch({ type: 'openExitModal' })
            : exitCheckIn
        }
      />
      <CheckInSessionMainPresentational
        checkInId={checkInId}
        question={currentQuestion}
        headerTitle={headerTitle}
        headerDescription={headerDescription}
        studentAnswer={state.studentAnswer}
        numberOfQuestions={numberOfQuestions}
        numberOfQuestionsCompleted={questionsCompleted}
        onSkip={handleSkipQuestion}
        onSubmit={handleSubmitAnswer}
        onStudentAnswerChange={value =>
          dispatch({ type: 'setStudentAnswer', studentAnswer: value })
        }
        isFetching={state.isSubmitting}
        errorMessage={state.errorMessage}
        onQuestionSetCompleteSeen={() => {
          if (
            checkInType === 'STRAND_DIAGNOSTIC_CHECKIN' &&
            checkInStrand != null
          ) {
            history.push(getCheckInStrandUrl({ strandId: checkInStrand.id }));
          } else
            dispatch({
              type: 'setQuestionAtWhichSuccessScreenLastSeen',
              questionNumber: questionsCompleted,
            });
        }}
        shouldShowQuestionSetComplete={shouldShowQuestionSetComplete}
        shouldShowCheckInComplete={shouldShowCheckInComplete}
        curriculumInformation={curriculumInformation}
      />
      {canAccessDemoTools &&
        !shouldShowQuestionSetComplete &&
        !shouldShowCheckInComplete && (
          <DebugActions
            onSkip={handleSkipQuestion}
            onSubmitCorrectAnswer={handleSubmitCorrectAnswer}
          />
        )}
      {canResumeCheckIn && (
        <ExitCheckInModal
          isOpen={state.isExitModalOpen}
          onSubmit={exitCheckIn}
          closeModal={() => dispatch({ type: 'closeExitModal' })}
        />
      )}
    </>
  );
}
export default CheckIn;
function assertIsMathExpressionAnswer(
  studentAnswer: StudentAnswer,
): asserts studentAnswer is MathExpressionQuestionAnswer {
  if (typeof studentAnswer !== 'string') {
    throw Error('studentAnswer is not a math expression answer');
  }
}
function assertIsMultipleChoiceAnswer(
  studentAnswer: StudentAnswer,
): asserts studentAnswer is MultipleChoiceQuestionAnswer {
  if (!Array.isArray(studentAnswer)) {
    throw Error('studentAnswer is not a multiple choice answer');
  }
}
function assertIsQuestionResultForSnowplow(
  questionAnswerResult: string,
): asserts questionAnswerResult is QuestionResult {
  const questionResultStrings: QuestionResult[] = ['correct', 'incorrect'];
  // TODO remove this and use `satisfies QuestionResult[]` on
  // questionResultStrings to enforce the strings are of the
  // correct type. Webpack/Jest/Storybook all choke on satisfies
  // syntax at the moment (Sep 2023)
  const _questionResultStrings: string[] = questionResultStrings;
  if (!_questionResultStrings.includes(questionAnswerResult))
    throw Error('questionAnswerResult is not a valid QuestionResult type');
}
