import styled from '@emotion/styled';
import React, { useRef, type ReactElement, type SyntheticEvent } from 'react';

import {
  calculatorKinds,
  type CalculatorKind,
} from 'ms-components/calculators/CalculatorPopover';
import DictionaryIcon from 'ms-components/icons/Dictionary';
import { GeometryCalculatorIcon } from 'ms-components/icons/GeometryCalculatorIcon';
import { GraphingCalculatorIcon } from 'ms-components/icons/GraphingCalculatorIcon';
import { ScientificCalculatorIcon } from 'ms-components/icons/ScientificCalculatorIcon';
import { StatisticsCalculatorIcon } from 'ms-components/icons/StatisticsCalculatorIcon';
import StudyNotesIcon from 'ms-components/icons/StudyNotes';
import { ThreeDCalculatorIcon } from 'ms-components/icons/ThreeDCalculatorIcon';
import ToolboxIcon from 'ms-components/icons/Toolbox';
import { font, fontFamily, lineHeight, borderRadiusUI } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { HEADER_SIZE } from 'ms-styles/theme/Numero';
import Popover from 'ms-ui-primitives/Popover/PopoverInternal'; // PopoverInternal has better API for positioning
import {
  useAccessibilityMode,
  accessibilityModeStyle,
} from 'ms-utils/accessibility';
import { tappable } from 'ms-utils/emotion';
import { useCanAccessNotes } from 'ms-utils/hooks/useCanAccessToolbox';
import keyDownMap from 'ms-utils/keyDownMap';

const TOOLBOX_ICON_SIZE = 24;
const TOOL_ICON_SIZE = 32;

const ToolboxIconWrapper = styled.div({
  display: 'flex',
});

const ToolboxPopoverRoot = styled.div<{ noPopover: boolean }>(
  ({ noPopover }) => ({
    background: colors.white,
    borderRadius: 8,
    boxShadow: '0px 8.75px 49px rgba(62, 62, 76, 0.15)',
    fontFamily: fontFamily.body,
    color: colors.cloudBurst,
    padding: '24px 20px',
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    ...(noPopover && {
      boxShadow: 'none',
    }),
  }),
);

const ToolboxTitle = styled.div(font.gilroyLargeHeading);

const CalculatorsTitle = styled.div({
  ...font.gilroyHeading,
  color: colors.cloudBurst,
});

const ToolGroup = styled.div({
  display: 'flex',
  gap: 10,
});

const ToolTappable = styled.div<{
  accessibilityMode: boolean;
}>(({ accessibilityMode }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 10,
  width: 80,
  ...tappable,
  ...(accessibilityMode && accessibilityModeStyle),
}));

const toolTappableClass = 'tool-tappable-13b0b9e6-40d9-44f3-a64d-a7f1a5350c4c';

function Tool(props: {
  label: string;
  icon: ReactElement<any>;
  isSelected?: boolean;
  onSelect: (event: SyntheticEvent<any>) => void;
}) {
  const [hasEnabledAccessibilityMode] = useAccessibilityMode();
  const [hovered, setHovered] = React.useState(false);
  return (
    <ToolTappable
      // Used as a convenient way to lookup the the first tappable
      // in the DOM, in order to focus it for accessibility
      className={toolTappableClass}
      tabIndex={0}
      accessibilityMode={hasEnabledAccessibilityMode}
      onKeyDown={keyDownMap({ ENTER: props.onSelect, SPACE: props.onSelect })}
      onClick={props.onSelect}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ToolIcon hovered={hovered} isSelected={props.isSelected}>
        {props.icon}
      </ToolIcon>
      <ToolLabel hovered={hovered} isSelected={props.isSelected}>
        {props.label}
      </ToolLabel>
    </ToolTappable>
  );
}

const ToolIcon = styled.div<{
  hovered: boolean;
  isSelected: boolean | undefined;
}>(({ hovered, isSelected }) => ({
  color: hovered || isSelected ? colors.eggplant : colors.shuttleGray,
}));

const ToolLabel = styled.div<{
  hovered: boolean;
  isSelected: boolean | undefined;
}>(({ hovered, isSelected }) => ({
  fontWeight: 'normal',
  lineHeight: lineHeight.body,
  color: colors.cloudBurst,
  width: '100%',
  textAlign: 'center',
  borderRadius: borderRadiusUI,
  ...(hovered && {
    color: colors.eggplant,
    fontWeight: 600,
  }),
  ...(isSelected && {
    color: colors.eggplant,
    fontWeight: 600,
    background: colors.eggplant10,
  }),
}));

export type Props = {
  isOpen: boolean;
  selectedCalculator: CalculatorKind | null;
  onGlossaryOpen: () => void;
  onCalculatorOpen: (calculatorKind: CalculatorKind) => void;
  onStudyNotesOpen: () => void;
  onClose: () => void;
  noPopover?: boolean | undefined;
};

export default function Toolbox({
  isOpen,
  selectedCalculator,
  onClose,
  onGlossaryOpen,
  onCalculatorOpen,
  onStudyNotesOpen,
  noPopover = false,
}: Props) {
  const anchorRef = useRef<HTMLDivElement>(null);
  const canAccessNotes = useCanAccessNotes();
  const [hasEnabledAccessibilityMode] = useAccessibilityMode();

  React.useEffect(() => {
    if (isOpen && hasEnabledAccessibilityMode) {
      // Focus the first tappable (in document order) for accessibility
      const firstTappable = document.querySelector(`.${toolTappableClass}`);
      if (firstTappable && firstTappable instanceof HTMLElement) {
        firstTappable.focus();
      }
    }
  }, [isOpen, hasEnabledAccessibilityMode]);

  const openGlossaryAction = React.useCallback(
    (e: SyntheticEvent<any>) => {
      e.stopPropagation();
      onGlossaryOpen();
      onClose();
    },
    [onClose, onGlossaryOpen],
  );

  const openCalculatorAction = React.useCallback(
    (e: SyntheticEvent<any>, kind: CalculatorKind) => {
      e.stopPropagation();
      onCalculatorOpen(kind);
      onClose();
    },
    [onClose, onCalculatorOpen],
  );

  const openStudyNotesAction = React.useCallback(
    (e: SyntheticEvent<any>) => {
      e.stopPropagation();
      onStudyNotesOpen();
      onClose();
    },
    [onClose, onStudyNotesOpen],
  );

  const ToolboxContents = () => {
    return (
      <ToolboxPopoverRoot noPopover={noPopover}>
        <ToolboxTitle>Toolbox</ToolboxTitle>
        <ToolGroup>
          <Tool
            label="Glossary"
            icon={<DictionaryIcon size={TOOL_ICON_SIZE} />}
            onSelect={openGlossaryAction}
          />
          {canAccessNotes && (
            <Tool
              label="Notes"
              icon={<StudyNotesIcon size={TOOL_ICON_SIZE} />}
              onSelect={openStudyNotesAction}
            />
          )}
        </ToolGroup>

        <CalculatorsTitle>Calculators</CalculatorsTitle>
        <CalculatorTools
          selectedCalculator={selectedCalculator}
          onSelectCalculator={openCalculatorAction}
        />
      </ToolboxPopoverRoot>
    );
  };

  if (noPopover) {
    return <ToolboxContents />;
  }

  return (
    <ToolboxIconWrapper ref={anchorRef}>
      <ToolboxIcon size={TOOLBOX_ICON_SIZE} />

      {isOpen && (
        <Popover
          onDismiss={onClose}
          anchorElementRef={anchorRef}
          popoverOrigin={['top', 'right']}
          anchorOrigin={['top', 'right']}
          shouldDismissOnTapOut
          shouldDismissOnOwnScroll
          shouldDismissOnScroll
          shouldDismissOnTab={false}
          shouldDismissOnEsc
          renderOverlay
          vOffset={HEADER_SIZE - 24} // This was manually picked
          hOffset={0}
        >
          <ToolboxContents />
        </Popover>
      )}
    </ToolboxIconWrapper>
  );
}

function CalculatorTools({
  selectedCalculator,
  onSelectCalculator,
}: {
  selectedCalculator: Props['selectedCalculator'];
  onSelectCalculator: (
    event: SyntheticEvent<any>,
    kind: CalculatorKind,
  ) => void;
}) {
  return (
    <ToolGroup>
      {calculatorKinds.map(kind => (
        <CalculatorTool
          key={kind}
          kind={kind}
          isSelected={selectedCalculator === kind}
          onSelectCalculator={onSelectCalculator}
        />
      ))}
    </ToolGroup>
  );
}

function CalculatorTool({
  kind,
  isSelected,
  onSelectCalculator,
}: {
  kind: CalculatorKind;
  isSelected: boolean;
  onSelectCalculator: (
    event: SyntheticEvent<any>,
    kind: CalculatorKind,
  ) => void;
}) {
  const { icon, label } = CalculatorToolConfig[kind];
  return (
    <Tool
      label={label}
      icon={icon}
      isSelected={isSelected}
      onSelect={event => onSelectCalculator(event, kind)}
    />
  );
}

const CalculatorToolConfig = {
  scientific: {
    icon: <ScientificCalculatorIcon />,
    label: 'Scientific',
  },
  graphing: {
    icon: <GraphingCalculatorIcon />,
    label: 'Graphing',
  },
  geometry: {
    icon: <GeometryCalculatorIcon />,
    label: 'Geometry',
  },
  '3d': {
    icon: <ThreeDCalculatorIcon />,
    label: '3d',
  },
  statistics: {
    icon: <StatisticsCalculatorIcon />,
    label: 'Statistics',
  },
};
