/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LanternCheckInType = "ALL_STRANDS_DIAGNOSTIC_CHECKIN" | "HISTORIC_DIAGNOSTIC_CHECKIN" | "HISTORIC_GROWTH_CHECKIN" | "POST_TOPIC_TEST_CHECKIN" | "PRE_TOPIC_TEST_CHECKIN" | "SKILL_CHECKIN" | "STRAND_DIAGNOSTIC_CHECKIN" | "STRAND_GROWTH_CHECKIN" | "SUBSTRAND_GROWTH_CHECKIN";
export type CheckInStartDiagnostic_CheckInPresentDiagnosticQueryVariables = {
    strandId: string;
};
export type CheckInStartDiagnostic_CheckInPresentDiagnosticQueryResponse = {
    readonly lantern: {
        readonly viewer: {
            readonly strandStatus?: {
                readonly diagnosticNumberOfQuestions: number;
                readonly diagnosticQuestionsCompleted: number;
                readonly lastWorkedOnDiagnosticCheckIn: {
                    readonly id: string;
                } | null;
                readonly strand: {
                    readonly title: string;
                };
            } | null | undefined;
            readonly checkIns?: ReadonlyArray<{
                readonly type: LanternCheckInType;
            }> | undefined;
        } | null;
    };
};
export type CheckInStartDiagnostic_CheckInPresentDiagnosticQuery = {
    readonly response: CheckInStartDiagnostic_CheckInPresentDiagnosticQueryResponse;
    readonly variables: CheckInStartDiagnostic_CheckInPresentDiagnosticQueryVariables;
};



/*
query CheckInStartDiagnostic_CheckInPresentDiagnosticQuery(
  $strandId: ID!
) {
  lantern {
    viewer {
      __typename
      ... on LanternStudent {
        strandStatus(strandId: $strandId) {
          diagnosticNumberOfQuestions
          diagnosticQuestionsCompleted
          lastWorkedOnDiagnosticCheckIn {
            id
          }
          strand {
            title
            id
          }
          id
        }
        checkIns {
          type
          id
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "strandId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "strandId",
    "variableName": "strandId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "diagnosticNumberOfQuestions",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "diagnosticQuestionsCompleted",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "CheckIn",
  "kind": "LinkedField",
  "name": "lastWorkedOnDiagnosticCheckIn",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckInStartDiagnostic_CheckInPresentDiagnosticQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v1/*: any*/),
                    "concreteType": "StudentStrandStatus",
                    "kind": "LinkedField",
                    "name": "strandStatus",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Strand",
                        "kind": "LinkedField",
                        "name": "strand",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CheckIn",
                    "kind": "LinkedField",
                    "name": "checkIns",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "LanternStudent",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckInStartDiagnostic_CheckInPresentDiagnosticQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v1/*: any*/),
                    "concreteType": "StudentStrandStatus",
                    "kind": "LinkedField",
                    "name": "strandStatus",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Strand",
                        "kind": "LinkedField",
                        "name": "strand",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CheckIn",
                    "kind": "LinkedField",
                    "name": "checkIns",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "LanternStudent",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dfab51c9689ee2e61d061e847d685928",
    "id": null,
    "metadata": {},
    "name": "CheckInStartDiagnostic_CheckInPresentDiagnosticQuery",
    "operationKind": "query",
    "text": "query CheckInStartDiagnostic_CheckInPresentDiagnosticQuery(\n  $strandId: ID!\n) {\n  lantern {\n    viewer {\n      __typename\n      ... on LanternStudent {\n        strandStatus(strandId: $strandId) {\n          diagnosticNumberOfQuestions\n          diagnosticQuestionsCompleted\n          lastWorkedOnDiagnosticCheckIn {\n            id\n          }\n          strand {\n            title\n            id\n          }\n          id\n        }\n        checkIns {\n          type\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '2a3ab07e59b64136020305b666681b59';
export default node;
