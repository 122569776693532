/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PointSourceType = "ANSWERED_QUESTION" | "COMPLETED_TASK_BY_DUE_DATE" | "COMPLETED_WORKOUT" | "DAILY_CHALLENGE_COMPLETE_A_TASK" | "DAILY_CHALLENGE_REQUEST_HINT" | "DAILY_CHALLENGE_THREE_QUESTIONS_STREAK" | "UNKNOWN" | "WEEKLY_CHALLENGE";
export type MonthlyExpeditionQueryVariables = {
    startOfMonth: string;
    endOfMonth: string;
};
export type MonthlyExpeditionQueryResponse = {
    readonly viewer: {
        readonly profile: {
            readonly __typename: "Student";
            readonly studentPreference: {
                readonly hasUnlockedTokyoStreetsBackground: boolean;
                readonly hasUnlockedCairoEgyptBackground: boolean;
                readonly hasUnlockedBeijingChinaBackground: boolean;
            };
            readonly gamification: {
                readonly points: {
                    readonly total: number;
                };
            } | null;
            readonly leaderboardClass: {
                readonly class: {
                    readonly displayName: string | null;
                    readonly title: string;
                    readonly gamification: {
                        readonly points: {
                            readonly total: number;
                            readonly contributingStudents: number;
                            readonly transactions: {
                                readonly edges: ReadonlyArray<{
                                    readonly node: {
                                        readonly id: string;
                                        readonly amount: number;
                                        readonly timestamp: string;
                                        readonly sourceType: PointSourceType;
                                        readonly student: {
                                            readonly firstName: string;
                                            readonly avatar: string;
                                        };
                                    };
                                }>;
                            };
                        };
                        readonly totalStudentsInClass: number;
                    } | null;
                };
            } | null;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        };
        readonly " $fragmentRefs": FragmentRefs<"NavbarPopover_NavbarElement_user">;
    };
};
export type MonthlyExpeditionQuery = {
    readonly response: MonthlyExpeditionQueryResponse;
    readonly variables: MonthlyExpeditionQueryVariables;
};



/*
query MonthlyExpeditionQuery(
  $startOfMonth: DateTime!
  $endOfMonth: DateTime!
) {
  viewer {
    ...NavbarPopover_NavbarElement_user
    profile {
      __typename
      ... on Student {
        studentPreference {
          hasUnlockedTokyoStreetsBackground
          hasUnlockedCairoEgyptBackground
          hasUnlockedBeijingChinaBackground
          id
        }
        gamification {
          points(startDate: $startOfMonth, endDate: $endOfMonth) {
            total
          }
        }
        leaderboardClass {
          class {
            displayName
            title
            gamification {
              points(startDate: $startOfMonth, endDate: $endOfMonth) {
                total
                contributingStudents
                transactions(first: 100, orderBy: TIMESTAMP_DESCENDING) {
                  edges {
                    node {
                      id
                      amount
                      timestamp
                      sourceType
                      student {
                        firstName
                        avatar
                      }
                    }
                  }
                }
              }
              totalStudentsInClass
            }
            id
          }
        }
      }
      ... on Node {
        __isNode: __typename
        id
      }
      ... on Teacher {
        id
      }
    }
    id
  }
}

fragment NavbarPopover_NavbarElement_user on User {
  points {
    current
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endOfMonth"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startOfMonth"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasUnlockedTokyoStreetsBackground",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasUnlockedCairoEgyptBackground",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasUnlockedBeijingChinaBackground",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endOfMonth"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startOfMonth"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Gamification",
  "kind": "LinkedField",
  "name": "gamification",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": (v6/*: any*/),
      "concreteType": "PointsEarned",
      "kind": "LinkedField",
      "name": "points",
      "plural": false,
      "selections": [
        (v7/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "ClassGamification",
  "kind": "LinkedField",
  "name": "gamification",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": (v6/*: any*/),
      "concreteType": "ClassPointsEarned",
      "kind": "LinkedField",
      "name": "points",
      "plural": false,
      "selections": [
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contributingStudents",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 100
            },
            {
              "kind": "Literal",
              "name": "orderBy",
              "value": "TIMESTAMP_DESCENDING"
            }
          ],
          "concreteType": "PointsTransactionConnection",
          "kind": "LinkedField",
          "name": "transactions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PointsTransactionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PointsTransaction",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v11/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "timestamp",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sourceType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StudentBasicInfo",
                      "kind": "LinkedField",
                      "name": "student",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "firstName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "avatar",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "transactions(first:100,orderBy:\"TIMESTAMP_DESCENDING\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalStudentsInClass",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = [
  (v11/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MonthlyExpeditionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudentPreference",
                    "kind": "LinkedField",
                    "name": "studentPreference",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LeaderboardClass",
                    "kind": "LinkedField",
                    "name": "leaderboardClass",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Class",
                        "kind": "LinkedField",
                        "name": "class",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Student",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NavbarPopover_NavbarElement_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MonthlyExpeditionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PointsObject",
            "kind": "LinkedField",
            "name": "points",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "current",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudentPreference",
                    "kind": "LinkedField",
                    "name": "studentPreference",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LeaderboardClass",
                    "kind": "LinkedField",
                    "name": "leaderboardClass",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Class",
                        "kind": "LinkedField",
                        "name": "class",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v12/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Student",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v13/*: any*/),
                "type": "Node",
                "abstractKey": "__isNode"
              },
              {
                "kind": "InlineFragment",
                "selections": (v13/*: any*/),
                "type": "Teacher",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "844f6417ae8cb6964f9c4dbcdb0b16b7",
    "id": null,
    "metadata": {},
    "name": "MonthlyExpeditionQuery",
    "operationKind": "query",
    "text": "query MonthlyExpeditionQuery(\n  $startOfMonth: DateTime!\n  $endOfMonth: DateTime!\n) {\n  viewer {\n    ...NavbarPopover_NavbarElement_user\n    profile {\n      __typename\n      ... on Student {\n        studentPreference {\n          hasUnlockedTokyoStreetsBackground\n          hasUnlockedCairoEgyptBackground\n          hasUnlockedBeijingChinaBackground\n          id\n        }\n        gamification {\n          points(startDate: $startOfMonth, endDate: $endOfMonth) {\n            total\n          }\n        }\n        leaderboardClass {\n          class {\n            displayName\n            title\n            gamification {\n              points(startDate: $startOfMonth, endDate: $endOfMonth) {\n                total\n                contributingStudents\n                transactions(first: 100, orderBy: TIMESTAMP_DESCENDING) {\n                  edges {\n                    node {\n                      id\n                      amount\n                      timestamp\n                      sourceType\n                      student {\n                        firstName\n                        avatar\n                      }\n                    }\n                  }\n                }\n              }\n              totalStudentsInClass\n            }\n            id\n          }\n        }\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n      ... on Teacher {\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment NavbarPopover_NavbarElement_user on User {\n  points {\n    current\n  }\n}\n"
  }
};
})();
(node as any).hash = '30e786f8f7214a179c50c107a2958e47';
export default node;
