// DO NOT MODIFY THIS FILE
// Generated by bin/generate_feature_flags_v2.py
import { graphql } from 'react-relay';

graphql`
  fragment featureFlagsV2 on FeatureFlagsV2 @relay(mask: false) {
    canPreviewProblemEventDataInSkills
    catfaEnableSecondarySaveAsTemplate
    checkinsEnableDiscoveryCheckinCapGrades
    devSkillsReportEnableTimeFilter
    enableAiOpenPrompt
    enableAiPremiumTrial
    gamificationEnableChallengesDaily
    gamificationEnableCustomisation
    gamificationEnableLeaderboardClassSelectionPrompt
    gamificationEnableLeaderboardDock
    gamificationEnableLeaderboardModalV2
    gamificationEnableLeaderboardNotification
    gamificationEnableLeaderboardTab
    gamificationEnableLiveModePromote
    gamificationEnableMonthlyExpedition
    gamificationEnablePointsTaskComplete
    gamificationEnableQuestionSuccessBannerV2
    gamificationEnableTeacherStickers
    gamificationEnableTugOfWar
    gamificationHighlightStreaksInWorkbookHeader
    globalSearchEnableMVP
    graphqlEnableNodeResolver
    hasPreviewProblemEventDataInSkills
    perfUpdateIndirectMasteryAsync
    preTopicTestCheckInCanAccess
    reportsEnableRecommendTasks
    showMaintenanceBanner
    skillsActivityReportEnableReport
    skillsEnableCurriculumClassAverage
    skillsReportsEnableStudentSkillsUpdate
    templatesEnableSortLastAssigned
    workbookEnableDesmosCalculators
    workbookEnableIncorrectStepPrompt
    workbookEnableSpanishSupport
    workbookEnableTTSSupport
    workbookHideSkipStepExplanation
  }
`;
export const FEATURE_FLAG_V2_NAMES_FOR_TEST_MOCKS = [
  'canPreviewProblemEventDataInSkills',
  'catfaEnableSecondarySaveAsTemplate',
  'checkinsEnableDiscoveryCheckinCapGrades',
  'devSkillsReportEnableTimeFilter',
  'enableAiOpenPrompt',
  'enableAiPremiumTrial',
  'gamificationEnableChallengesDaily',
  'gamificationEnableCustomisation',
  'gamificationEnableLeaderboardClassSelectionPrompt',
  'gamificationEnableLeaderboardDock',
  'gamificationEnableLeaderboardModalV2',
  'gamificationEnableLeaderboardNotification',
  'gamificationEnableLeaderboardTab',
  'gamificationEnableLiveModePromote',
  'gamificationEnableMonthlyExpedition',
  'gamificationEnablePointsTaskComplete',
  'gamificationEnableQuestionSuccessBannerV2',
  'gamificationEnableTeacherStickers',
  'gamificationEnableTugOfWar',
  'gamificationHighlightStreaksInWorkbookHeader',
  'globalSearchEnableMVP',
  'graphqlEnableNodeResolver',
  'hasPreviewProblemEventDataInSkills',
  'perfUpdateIndirectMasteryAsync',
  'preTopicTestCheckInCanAccess',
  'reportsEnableRecommendTasks',
  'showMaintenanceBanner',
  'skillsActivityReportEnableReport',
  'skillsEnableCurriculumClassAverage',
  'skillsReportsEnableStudentSkillsUpdate',
  'templatesEnableSortLastAssigned',
  'workbookEnableDesmosCalculators',
  'workbookEnableIncorrectStepPrompt',
  'workbookEnableSpanishSupport',
  'workbookEnableTTSSupport',
  'workbookHideSkipStepExplanation',
] as const;
