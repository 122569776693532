import { useState } from 'react';

import { useSnowplow } from 'ms-helpers/Snowplow';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import type {
  SubtopicSelectorProps,
  TextbookAndSearchTabSyncProps,
} from 'ms-pages/Teacher/components/CreateTask/flows/components/SidebarAndPreview';
import { FLOATING_FOOTER_HEIGHT } from 'ms-pages/Teacher/components/Flyout/components/FloatingFooter';
import {
  BookTypeTabsLeft,
  useTextbookTypeTabsContext,
} from 'ms-pages/Textbooks/components/TextbookTypesLayout/TextbookTypeTabs';
import TopicSidebar from 'ms-pages/Textbooks/components/TopicSidebar';
import { colors } from 'ms-styles/colors';
import { VStack, HStack } from 'ms-ui-primitives/Stack';
import { setRecentlyUsedTextbooksForUser } from 'ms-utils/localStorageDb/syncRecentlyUsedTextbooks';
import { noop } from 'ms-utils/misc';

export function SubtopicSelectorSidebar({
  activeSubtopic,
  activeTopicId = null,
  setActiveSubtopic,
  selectedSubtopics,
  setSelectedSubtopics = noop,
  subtopicFilter = () => true,
  enableMultipleSelection = false,
  onSearchTabSearchStringUpdate,
  setBookTypeTab,
}: SubtopicSelectorProps & TextbookAndSearchTabSyncProps) {
  const { userId } = useViewer();

  const [searchString, setSearchString] = useState('');

  const { currentSyllabusId, setSyllabusId } = useTextbookTypeTabsContext();

  const { trackStructEvent } = useSnowplow();

  return (
    <HStack>
      <BookTypeTabsLeft />
      <VStack
        style={{
          width: 312,
          borderRight: `solid 1px ${colors.ironLight}`,
          flexShrink: 0,
          position: 'relative',
          isolation: 'isolate',
        }}
      >
        <TopicSidebar
          activeTopicId={activeTopicId}
          activeSubtopicId={activeSubtopic?.id ?? null}
          isInCATFA
          eventHandlers={{
            onSelectSyllabus: syllabusId => {
              setSyllabusId(syllabusId);
              setRecentlyUsedTextbooksForUser(userId, syllabusId);
            },
            onChangeSearchString: value => {
              setSearchString(value);
            },
            onClickTopic: () => {},
            onClickSubtopic: ({ subtopic }) => {
              trackStructEvent({
                category: 'create_task_from_anywhere',
                action: 'textbook_page_view',
                label: currentSyllabusId,
                property: subtopic.id,
              });
              setActiveSubtopic(subtopic);
            },
            onSelectSubtopic: enableMultipleSelection
              ? subtopic => {
                  setSelectedSubtopics(prev => {
                    if (prev.find(s => s.id === subtopic.id)) {
                      return prev.filter(s => s.id !== subtopic.id);
                    }
                    return [...prev, subtopic];
                  });
                }
              : noop,
            onSetBookTypeTab: setBookTypeTab,
            onSearchTabSearchStringUpdate,
          }}
          searchString={searchString}
          subtopicsOnly
          syllabusId={currentSyllabusId}
          subtopicFilter={subtopicFilter}
          selectedSubtopicIds={
            selectedSubtopics == null ? null : selectedSubtopics.map(s => s.id)
          }
          enableMultipleSelection={enableMultipleSelection}
          popoverBottomEdgeBuffer={FLOATING_FOOTER_HEIGHT}
        />
      </VStack>
    </HStack>
  );
}
