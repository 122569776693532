/*
 * This component should be able to take the state outputted by CreateBulkAdaptiveTask/state and
 * render it into a list of subtopics with the relevant details.
 *
 * It should expose an `onEdit` prop that will be called with the relevant state. This will be used
 * to drive the edit form that may be rendered elsewhere
 *
 * State: (whatever is in ../state)
 * component: ({ state: State[] }) => React$Node
 * onEdit: State => void
 */

import SubtopicReviewCard from 'ms-pages/Teacher/components/CreateTask/CreateBulkAdaptiveTask/ReviewBulkAdaptiveTasks/SubtopicReviewCard';
import type { BulkAdaptiveTaskStateFactory } from 'ms-pages/Teacher/components/CreateTask/CreateBulkAdaptiveTask/state';
import type { SelectedSubtopic } from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelector';
import { useIsWithSkillCheckinStep } from 'ms-pages/Teacher/components/CreateTask/helpers';
import { useCreateTaskDispatch } from 'ms-pages/Teacher/components/CreateTask/state/useTaskState';

type Props = {
  subtopics: ReadonlyArray<SelectedSubtopic>;
  stateFactory: BulkAdaptiveTaskStateFactory;
  // a mapping from subtopicId to task id for rendering links
  subtopicSaveDictionary: Record<string, string>;
  // a mapping from subtopicId to an error message
  subtopicSaveErrorDictionary: Record<string, ReadonlyArray<Error>>;
};

export default function ReviewbulkAdaptiveTasks({
  stateFactory,
  subtopics,
  subtopicSaveDictionary,
  subtopicSaveErrorDictionary,
}: Props) {
  const dispatch = useCreateTaskDispatch();
  const withSkillCheckinStep = useIsWithSkillCheckinStep(subtopics);
  return (
    <>
      {subtopics.map(subtopic => {
        const state = stateFactory(subtopic.id, {
          taskName: subtopic.title,
          subtopicId: subtopic.id,
          edited: false,
        });
        if (subtopicSaveDictionary[subtopic.id] != null) return null;
        return (
          <SubtopicReviewCard
            errorMessages={subtopicSaveErrorDictionary[subtopic.id] || []}
            key={subtopic.id}
            onEdit={() => {
              dispatch({
                type: 'set_editing_id',
                id: subtopic.id,
                withSkillCheckinStep,
              });
            }}
            state={state}
          />
        );
      })}
    </>
  );
}
