import { css } from '@emotion/css';
import React from 'react';

import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import {
  Body,
  Cell,
  Header,
  HeaderCell,
  Row,
} from 'ms-pages/Teacher/components/Table';
import {
  TableEl,
  TableWrapper,
} from 'ms-pages/Teacher/components/Table/components/Table';

const HEADER_HEIGHT = 34;

const styles = {
  errorWrapper: css({
    width: '100%',
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};

const ModalTableWrapper = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string | undefined;
}) => (
  <TableWrapper scrollable style={{ flex: '1 1 0' }} className={className}>
    <TableEl>
      <Header height={HEADER_HEIGHT} sticky>
        <HeaderCell hasLeftPadding>Title</HeaderCell>
      </Header>
      <Body>{children}</Body>
    </TableEl>
  </TableWrapper>
);

const ModalTableLoading = () => (
  <ModalTableWrapper>
    <Row>
      <Cell hasBottomBorder={false}>
        <MinorSpinner />
      </Cell>
    </Row>
  </ModalTableWrapper>
);

const ModalTableErrorFallback = () => (
  <ModalTableWrapper>
    <div className={styles.errorWrapper}>
      <BodyM color="peachPink" bold>
        Could not load folders...
      </BodyM>
    </div>
  </ModalTableWrapper>
);

export { ModalTableWrapper, ModalTableLoading, ModalTableErrorFallback };
