/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LanternCheckInType = "ALL_STRANDS_DIAGNOSTIC_CHECKIN" | "HISTORIC_DIAGNOSTIC_CHECKIN" | "HISTORIC_GROWTH_CHECKIN" | "POST_TOPIC_TEST_CHECKIN" | "PRE_TOPIC_TEST_CHECKIN" | "SKILL_CHECKIN" | "STRAND_DIAGNOSTIC_CHECKIN" | "STRAND_GROWTH_CHECKIN" | "SUBSTRAND_GROWTH_CHECKIN";
export type DemoStudentWelcomeCheckinQueryVariables = {};
export type DemoStudentWelcomeCheckinQueryResponse = {
    readonly viewer: {
        readonly profile: {
            readonly lanternStudent?: {
                readonly learningFocus: {
                    readonly id: string;
                    readonly strand: {
                        readonly id: string;
                    };
                } | null;
                readonly checkIns: ReadonlyArray<{
                    readonly id: string;
                    readonly substrand: {
                        readonly id: string;
                        readonly strand: {
                            readonly id: string;
                        };
                    } | null;
                    readonly type: LanternCheckInType;
                    readonly endedAt: string | null;
                }>;
            } | undefined;
            readonly school?: {
                readonly defaultStrand: {
                    readonly id: string;
                    readonly title: string;
                    readonly substrands: ReadonlyArray<{
                        readonly id: string;
                        readonly strand: {
                            readonly id: string;
                        };
                    }>;
                    readonly curriculum: {
                        readonly id: string;
                    };
                } | null;
            } | null | undefined;
        };
    };
};
export type DemoStudentWelcomeCheckinQuery = {
    readonly response: DemoStudentWelcomeCheckinQueryResponse;
    readonly variables: DemoStudentWelcomeCheckinQueryVariables;
};



/*
query DemoStudentWelcomeCheckinQuery {
  viewer {
    profile {
      __typename
      ... on Student {
        lanternStudent {
          learningFocus {
            id
            strand {
              id
            }
          }
          checkIns {
            id
            substrand {
              id
              strand {
                id
              }
            }
            type
            endedAt
          }
          id
        }
        school {
          defaultStrand {
            id
            title
            substrands {
              id
              strand {
                id
              }
            }
            curriculum {
              id
            }
          }
          id
        }
      }
      ... on Node {
        __isNode: __typename
        id
      }
      ... on Teacher {
        id
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Strand",
    "kind": "LinkedField",
    "name": "strand",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Substrand",
  "kind": "LinkedField",
  "name": "learningFocus",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "CheckIn",
  "kind": "LinkedField",
  "name": "checkIns",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Substrand",
      "kind": "LinkedField",
      "name": "substrand",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endedAt",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Strand",
  "kind": "LinkedField",
  "name": "defaultStrand",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Substrand",
      "kind": "LinkedField",
      "name": "substrands",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LanternCurriculum",
      "kind": "LinkedField",
      "name": "curriculum",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DemoStudentWelcomeCheckinQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LanternStudent",
                    "kind": "LinkedField",
                    "name": "lanternStudent",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "School",
                    "kind": "LinkedField",
                    "name": "school",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Student",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DemoStudentWelcomeCheckinQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LanternStudent",
                    "kind": "LinkedField",
                    "name": "lanternStudent",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "School",
                    "kind": "LinkedField",
                    "name": "school",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Student",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v1/*: any*/),
                "type": "Node",
                "abstractKey": "__isNode"
              },
              {
                "kind": "InlineFragment",
                "selections": (v1/*: any*/),
                "type": "Teacher",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6d3807e26a4ce651bf07a612492e5bb2",
    "id": null,
    "metadata": {},
    "name": "DemoStudentWelcomeCheckinQuery",
    "operationKind": "query",
    "text": "query DemoStudentWelcomeCheckinQuery {\n  viewer {\n    profile {\n      __typename\n      ... on Student {\n        lanternStudent {\n          learningFocus {\n            id\n            strand {\n              id\n            }\n          }\n          checkIns {\n            id\n            substrand {\n              id\n              strand {\n                id\n              }\n            }\n            type\n            endedAt\n          }\n          id\n        }\n        school {\n          defaultStrand {\n            id\n            title\n            substrands {\n              id\n              strand {\n                id\n              }\n            }\n            curriculum {\n              id\n            }\n          }\n          id\n        }\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n      ... on Teacher {\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e25b9e59722fe6f1d62bf6fd236dd62f';
export default node;
