/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NavbarPopover_PopoverContent_user = {
    readonly points: {
        readonly current: number;
    } | null;
    readonly " $refType": "NavbarPopover_PopoverContent_user";
};
export type NavbarPopover_PopoverContent_user$data = NavbarPopover_PopoverContent_user;
export type NavbarPopover_PopoverContent_user$key = {
    readonly " $data"?: NavbarPopover_PopoverContent_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"NavbarPopover_PopoverContent_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavbarPopover_PopoverContent_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PointsObject",
      "kind": "LinkedField",
      "name": "points",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "current",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'c61ba717aa37a94fb5de63b011c74190';
export default node;
