export function PandaSvg({ style }: { style?: React.CSSProperties }) {
  return (
    <svg
      width="276"
      height="312"
      viewBox="0 0 276 312"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M243.977 242.887C245.546 271.098 194.421 302.195 133.421 305.589C72.4208 308.984 15.9866 279.687 23.0767 255.179C46.652 173.689 69.6846 201.348 130.684 197.954C191.684 194.56 213.172 172.06 243.977 242.887Z"
        fill="#3E3E4C"
      />
      <path
        d="M262.116 188.825C262.116 220.153 231.266 245.55 193.21 245.55C155.154 245.55 124.304 220.153 124.304 188.825C124.304 157.496 155.154 145.486 193.21 145.486C231.266 145.486 262.116 157.496 262.116 188.825Z"
        fill="#3E3E4C"
      />
      <path
        d="M3.39363 193.745C5.82597 221.19 33.7085 247.996 69.569 244.818C105.43 241.639 135.688 209.681 133.256 182.236C130.824 154.791 98.3478 149.6 62.4872 152.778C26.6267 155.956 1.92196 177.14 3.39363 193.745Z"
        fill="#3E3E4C"
      />
      <path
        d="M206.414 201.209C206.414 233.697 168.636 260.033 122.033 260.033C75.4312 260.033 37.6526 233.697 37.6526 201.209C37.6526 168.722 75.4312 142.385 122.033 142.385C168.636 142.385 206.414 168.722 206.414 201.209Z"
        fill="#EDEDED"
      />
      <path
        d="M15.7264 25.3299C6.49101 39.4291 11.2377 58.872 26.3283 68.7568C41.419 78.6416 61.1391 75.2251 70.3745 61.1259C79.6098 47.0267 74.8632 27.5838 59.7725 17.699C44.6819 7.81426 24.9617 11.2307 15.7264 25.3299Z"
        fill="#3E3E4C"
      />
      <path
        d="M198.391 11.1972C181.741 9.56427 166.828 22.6866 165.08 40.5068C163.332 58.3269 175.412 74.0968 192.062 75.7297C208.711 77.3627 223.625 64.2403 225.373 46.4202C227.12 28.6 215.04 12.8302 198.391 11.1972Z"
        fill="#3E3E4C"
      />
      <path
        d="M229.431 121.964C229.431 164.131 180.536 198.314 120.221 198.314C59.9061 198.314 11.011 164.131 11.011 121.964C11.011 79.7967 59.9061 15.899 120.221 15.899C180.536 15.899 229.431 79.7967 229.431 121.964Z"
        fill="#EDEDED"
      />
      <path
        d="M126.292 116.882C125.807 114.103 130.004 111.049 135.668 110.059C141.331 109.07 146.316 110.519 146.802 113.297C147.287 116.076 143.432 121.088 137.768 122.077C132.105 123.067 126.778 119.66 126.292 116.882Z"
        fill="#3E3E4C"
      />
      <path
        d="M194.29 115.97C187.491 122.772 173.449 119.758 162.926 109.239C152.404 98.7203 149.386 84.6791 156.185 77.8774C162.985 71.0757 181.818 69.2961 192.341 79.8151C202.863 90.3341 201.09 109.168 194.29 115.97Z"
        fill="#3E3E4C"
      />
      <path
        d="M179.91 103.197C180.033 105.751 178.063 107.921 175.509 108.045C172.955 108.168 170.785 106.197 170.662 103.644C170.539 101.09 172.509 98.9196 175.063 98.7964C177.617 98.6732 179.787 100.644 179.91 103.197Z"
        fill="#F0F0F0"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.409 131.683C152.18 132.988 153.368 136.292 152.063 139.064C148.11 147.458 141.172 151.417 133.899 151.725C126.993 152.017 120.167 149.025 115.555 144.457C113.379 142.301 113.362 138.789 115.518 136.613C117.673 134.437 121.185 134.42 123.361 136.576C125.987 139.176 129.886 140.792 133.43 140.642C136.608 140.508 139.845 138.974 142.028 134.338C143.333 131.566 146.637 130.378 149.409 131.683Z"
        fill="#3E3E4C"
      />
      <path
        d="M111.902 90.4878C118.029 98.8704 112.483 113.349 99.5145 122.827C86.5465 132.306 71.0671 133.194 64.9405 124.811C58.8138 116.429 60.0425 96.0425 73.0105 86.5644C85.9786 77.0863 105.775 82.1053 111.902 90.4878Z"
        fill="#3E3E4C"
      />
      <path
        d="M104.015 107.41C104.138 109.964 102.168 112.134 99.6142 112.257C97.0604 112.38 94.8903 110.41 94.767 107.856C94.6438 105.303 96.6142 103.132 99.168 103.009C101.722 102.886 103.892 104.856 104.015 107.41Z"
        fill="#F0F0F0"
      />
      <path
        d="M275.558 103.415C275.459 103.08 275.267 102.779 275.004 102.549C274.741 102.319 274.418 102.169 274.073 102.116C272.998 101.953 247.521 98.2052 234.092 111.653C227.51 118.242 225.05 127.758 226.751 139.93C224.83 142.801 223.129 145.459 221.699 147.641C221.567 147.843 221.475 148.069 221.43 148.306C221.385 148.543 221.387 148.787 221.436 149.023C221.485 149.259 221.581 149.484 221.716 149.683C221.852 149.883 222.026 150.054 222.228 150.186C222.43 150.318 222.656 150.41 222.893 150.455C223.13 150.5 223.374 150.498 223.61 150.449C223.846 150.4 224.071 150.305 224.27 150.169C224.47 150.033 224.641 149.859 224.773 149.657C226.07 147.679 229.422 142.729 229.471 142.644C236.574 132.394 246.951 119.491 258.996 115.79C259.034 115.779 259.085 115.761 259.126 115.747C248.016 120.341 239.191 133.127 233.089 143.722C235.738 144.254 238.42 144.612 241.116 144.794C248.156 145.215 253.827 144.034 258.282 141.243C266.894 135.85 269.031 125.511 270.749 117.203C271.804 112.091 272.803 107.263 275.011 105.31C275.273 105.078 275.463 104.777 275.56 104.442C275.657 104.106 275.656 103.75 275.558 103.415Z"
        fill="#8DC236"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.977 175.083C122.544 173.907 123.205 171.783 122.457 169.879C121.566 167.608 119.01 166.508 116.748 167.423C113.721 168.648 112.699 171.619 112.331 173.479C111.916 175.571 111.947 178.027 112.22 180.541C112.775 185.634 114.452 192.032 117.035 198.615C119.618 205.198 122.735 211.014 125.782 215.098C127.286 217.114 128.928 218.918 130.645 220.147C132.173 221.24 134.927 222.684 137.954 221.459C140.216 220.544 141.327 217.961 140.435 215.69C139.688 213.785 137.77 212.704 135.836 212.945C135.818 212.933 135.799 212.919 135.779 212.905C135.099 212.418 134.083 211.411 132.835 209.737C130.377 206.442 127.606 201.369 125.225 195.301C122.844 189.234 121.419 183.616 120.971 179.508C120.744 177.421 120.799 175.98 120.963 175.151C120.968 175.128 120.972 175.105 120.977 175.083ZM136.185 213.151C136.186 213.153 136.161 213.145 136.109 213.117C136.158 213.134 136.184 213.148 136.185 213.151ZM121.059 174.767C121.078 174.711 121.09 174.687 121.091 174.69C121.093 174.693 121.083 174.721 121.059 174.767Z"
        fill="#74A524"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.768 139.439C213.34 138.274 213.998 136.157 213.239 134.254C212.336 131.988 209.766 130.883 207.5 131.786C204.478 132.99 203.457 135.927 203.089 137.779C202.677 139.855 202.712 142.293 202.992 144.786C203.557 149.837 205.251 156.182 207.852 162.709C210.454 169.236 213.59 175.006 216.654 179.061C218.166 181.063 219.817 182.856 221.545 184.08C223.086 185.171 225.847 186.6 228.868 185.396C231.135 184.492 232.239 181.923 231.336 179.656C230.578 177.753 228.644 176.669 226.702 176.905C226.685 176.894 226.668 176.882 226.65 176.869C225.971 176.389 224.955 175.393 223.703 173.735C221.238 170.473 218.455 165.448 216.059 159.438C213.664 153.427 212.227 147.866 211.772 143.802C211.541 141.738 211.593 140.316 211.755 139.501C211.759 139.479 211.764 139.458 211.768 139.439ZM227.051 177.109C227.052 177.112 227.028 177.104 226.977 177.077C227.025 177.093 227.05 177.107 227.051 177.109ZM211.849 139.125C211.868 139.07 211.88 139.048 211.881 139.05C211.882 139.052 211.873 139.08 211.849 139.125Z"
        fill="#74A524"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M155.811 161.475C158.115 165.346 164.837 172.519 173.315 168.484C181.793 164.45 180.997 155.463 180.666 151.61L217.512 136.965L227.687 155.709L232.225 173.686L73.4809 236.754L58.768 200.033L155.811 161.475Z"
        fill="#8DC236"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.659 140.452C207.716 140.689 205.783 139.605 205.024 137.701C204.121 135.435 205.226 132.866 207.492 131.962C210.514 130.758 213.275 132.187 214.816 133.278C216.544 134.501 218.194 136.295 219.707 138.296C222.771 142.351 225.906 148.121 228.508 154.649C231.11 161.176 232.803 167.521 233.369 172.572C233.648 175.065 233.684 177.502 233.271 179.579C232.903 181.431 231.882 184.367 228.861 185.572C226.594 186.475 224.025 185.37 223.121 183.104C222.363 181.201 223.02 179.084 224.593 177.919C224.597 177.899 224.601 177.879 224.605 177.857C224.767 177.042 224.82 175.62 224.589 173.555C224.133 169.491 222.697 163.93 220.301 157.92C217.905 151.91 215.123 146.885 212.658 143.622C211.405 141.965 210.389 140.969 209.711 140.489C209.693 140.476 209.675 140.464 209.659 140.452ZM224.48 178.308C224.481 178.31 224.493 178.288 224.511 178.232C224.488 178.278 224.478 178.305 224.48 178.308ZM209.384 140.281C209.333 140.254 209.308 140.246 209.309 140.248C209.31 140.251 209.336 140.264 209.384 140.281Z"
        fill="#74A524"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.867 175.938C116.933 176.178 115.015 175.098 114.267 173.193C113.376 170.922 114.487 168.339 116.748 167.424C119.776 166.199 122.53 167.643 124.057 168.736C125.775 169.965 127.416 171.769 128.92 173.785C131.967 177.869 135.084 183.685 137.668 190.268C140.251 196.851 141.928 203.249 142.482 208.342C142.756 210.856 142.787 213.312 142.372 215.404C142.003 217.264 140.982 220.235 137.954 221.46C135.693 222.375 133.137 221.275 132.246 219.004C131.498 217.1 132.159 214.976 133.726 213.8C133.73 213.778 133.735 213.755 133.74 213.731C133.904 212.903 133.959 211.462 133.731 209.375C133.284 205.267 131.859 199.649 129.478 193.582C127.096 187.514 124.326 182.441 121.868 179.146C120.619 177.472 119.604 176.465 118.923 175.978C118.904 175.964 118.885 175.95 118.867 175.938ZM133.611 214.193C133.612 214.195 133.625 214.172 133.644 214.116C133.619 214.162 133.61 214.19 133.611 214.193ZM118.593 175.766C118.541 175.738 118.517 175.73 118.517 175.732C118.518 175.735 118.544 175.749 118.593 175.766Z"
        fill="#74A524"
      />
      <path
        d="M212.738 160.189C251.176 153.564 256.138 177.552 256.138 202.12C256.138 226.689 236.988 226.537 210.702 228.459C169.512 228.636 174.52 166.403 212.738 160.189Z"
        fill="#3E3E4C"
      />
      <path
        d="M26.8626 189.247C16.2214 205.493 21.6906 227.895 39.0784 239.285C56.4663 250.674 79.1883 246.738 89.8295 230.492C100.471 214.247 95.0014 191.844 77.6136 180.455C60.2258 169.065 37.5038 173.002 26.8626 189.247Z"
        fill="#3E3E4C"
      />
    </svg>
  );
}
