import type {
  BackgroundEnum,
  BackgroundType,
} from 'ms-components/DashboardCustomisationButton/__generated__/useUpdateDashboardBackgroundSelectionMutation.graphql';
import { PERMALINK_URL } from 'ms-utils/urls';

export type StudentDashboardTheme = 'light' | 'dark';
// Background release date are the first occurrences
// of the holidays starting from July 25th 2023
// If all release dates are in the past, we can remove release date logic from here and
// local_modules/ms-components/DashboardCustomisationButton/index.jsx:144
const holidayImages: {
  [key: string]: {
    fileName: string;
    name: BackgroundEnum;
    theme: StudentDashboardTheme;
    backgroundReleaseDate: string;
  };
} = {
  'lunar-new-year-dragon': {
    fileName: 'lunar_new_year',
    name: 'LUNAR_NEW_YEAR',
    theme: 'light',
    backgroundReleaseDate: '2024-01-25T00:00:00.000',
  },
  easter: {
    fileName: 'holiday_easter',
    name: 'HOLIDAY_EASTER',
    theme: 'dark',
    backgroundReleaseDate: '2024-03-31T00:00:00.000',
  },
  halloween: {
    fileName: 'holiday_halloween',
    name: 'HOLIDAY_HALLOWEEN',
    theme: 'dark',
    backgroundReleaseDate: '2023-10-31T00:00:00.000',
  },
  'saint-patricks-day': {
    fileName: 'holiday_saint-patricks-day',
    name: 'HOLIDAY_SAINT_PATRICKS_DAY',
    theme: 'light',
    backgroundReleaseDate: '2024-03-17T00:00:00.000',
  },
  'april-fools-day': {
    fileName: 'holiday_april-fools-day',
    name: 'HOLIDAY_APRIL_FOOLS_DAY',
    theme: 'light',
    backgroundReleaseDate: '2024-04-01T00:00:00.000',
  },
  'earth-day': {
    fileName: 'holiday_earth-day',
    name: 'HOLIDAY_EARTH_DAY',
    theme: 'light',
    backgroundReleaseDate: '2024-04-22T00:00:00.000',
  },
  ramadan: {
    fileName: 'holiday_ramadan',
    name: 'HOLIDAY_RAMADAN',
    theme: 'dark',
    backgroundReleaseDate: '2024-03-11T00:00:00.000',
  },
  'pi-day': {
    fileName: 'holiday_pi-day',
    name: 'HOLIDAY_PI_DAY',
    theme: 'light',
    backgroundReleaseDate: '2024-03-14T00:00:00.000',
  },
  'talk-like-a-pirate-day': {
    fileName: 'holiday_talk-like-a-pirate-day',
    name: 'HOLIDAY_TALK_LIKE_A_PIRATE_DAY',
    theme: 'light',
    backgroundReleaseDate: '2023-09-19T00:00:00.000',
  },
  hanukkah: {
    fileName: 'holiday_hanukkah',
    name: 'HOLIDAY_HANUKKAH',
    theme: 'light',
    backgroundReleaseDate: '2023-12-07T00:00:00.000',
  },
  thanksgiving: {
    fileName: 'holiday_thanksgiving',
    name: 'HOLIDAY_THANKSGIVING',
    theme: 'light',
    backgroundReleaseDate: '2023-11-23T00:00:00.000',
  },
  festive: {
    fileName: 'christmas_light',
    name: 'CHRISTMAS_LIGHT',
    theme: 'light',
    backgroundReleaseDate: '2023-12-01T00:00:00.000',
  },
  'eid-al-fitr': {
    fileName: 'holiday_eid-al-fitr',
    name: 'HOLIDAY_EID_AL_FITR',
    theme: 'dark',
    backgroundReleaseDate: '2024-04-10T00:00:00.000',
  },
  'youth-day': {
    fileName: 'holiday_youth-day',
    name: 'HOLIDAY_YOUTH_DAY',
    theme: 'light',
    backgroundReleaseDate: '2023-08-12T00:00:00.000',
  },
  'olympics-2024': {
    fileName: 'holiday_olympics-2024',
    name: 'HOLIDAY_OLYMPICS_2024',
    theme: 'dark',
    backgroundReleaseDate: '2024-07-25T00:00:00.000',
  },
};
type HolidayImageTypes = keyof typeof holidayImages | null;
type HolidayImageObjectType = {
  url: string;
  type: BackgroundType;
  name: BackgroundEnum;
  isUnlocked: boolean;
  howToUnlock?: undefined;
};
export const getHolidayImage = (
  holiday: HolidayImageTypes,
): HolidayImageObjectType | null => {
  if (holiday != null && holidayImages[holiday]) {
    return {
      url: `${PERMALINK_URL}/backgrounds/${holidayImages[holiday]?.fileName}.svg`,
      type: 'HOLIDAY',
      name: holidayImages[holiday]?.name as BackgroundEnum,
      isUnlocked: true,
    };
  }
  return null;
};
export const getIsBackgroundReleased = (
  holiday: HolidayImageTypes,
): boolean => {
  if (holiday != null) {
    const imageKey = Object.keys(holidayImages).find(
      key => holidayImages[key]?.name === holiday,
    );
    if (imageKey) {
      const holidayReleaseDate = new Date(
        holidayImages[imageKey]?.backgroundReleaseDate ?? '',
      );
      const currentDate = new Date();
      return currentDate >= holidayReleaseDate;
    }
  }
  return false;
};
export const getHolidayTheme = (
  holidayImageName: BackgroundEnum,
): StudentDashboardTheme => {
  const imageKey = Object.keys(holidayImages).find(
    key => holidayImages[key]?.name === holidayImageName,
  );
  if (imageKey) {
    return holidayImages[imageKey]?.theme as StudentDashboardTheme;
  }
  return 'light';
};
export const getHolidayImages = () => {
  const images: HolidayImageObjectType[] = [];
  Object.keys(holidayImages).forEach(holiday => {
    const image = getHolidayImage(holiday);
    if (image) images.push(image);
  });
  return images;
};
