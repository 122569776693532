/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type BackgroundEnum = "ABSTRACT_FORESTGREEN" | "ABSTRACT_LOCHMARA" | "ABSTRACT_LOCHMARADARK" | "ABSTRACT_MULTICOLOURDOTS" | "ABSTRACT_PINKBLOBS" | "ABSTRACT_PINKGREY" | "BACKGROUND_DEFAULT" | "BEIJING_CHINA" | "CAIRO_EGYPT" | "CHRISTMAS_LIGHT" | "FANTASY_CINDERELLA" | "GRADIENT_CLAY" | "GRADIENT_LIGHTBLUE" | "GRADIENT_PEACH" | "HOLIDAY_APRIL_FOOLS_DAY" | "HOLIDAY_EARTH_DAY" | "HOLIDAY_EASTER" | "HOLIDAY_EID_AL_FITR" | "HOLIDAY_HALLOWEEN" | "HOLIDAY_HANUKKAH" | "HOLIDAY_OLYMPICS_2024" | "HOLIDAY_PI_DAY" | "HOLIDAY_RAMADAN" | "HOLIDAY_SAINT_PATRICKS_DAY" | "HOLIDAY_TALK_LIKE_A_PIRATE_DAY" | "HOLIDAY_THANKSGIVING" | "HOLIDAY_YOUTH_DAY" | "INDOORS_LIBRARY" | "LANDSCAPE_BEACH" | "LANDSCAPE_BOAT" | "LUNAR_NEW_YEAR" | "SEASCAPE_SHARK" | "TOKYO_STREETS";
export type BackgroundType = "GRADIENT" | "GRAPHIC" | "HOLIDAY";
export type HowToUnlock = "COMPLETE_A_DISCOVERY_CHECKIN" | "COMPLETE_CLASS_MONTHLY_EXPEDITION_CHALLENGE_AND_COLLECT";
export type DashboardCustomisationButtonThemePickerQueryVariables = {};
export type DashboardCustomisationButtonThemePickerQueryResponse = {
    readonly viewer: {
        readonly profile: {
            readonly __typename: "Student";
            readonly studentPreference: {
                readonly availableDashboardBackgrounds: ReadonlyArray<{
                    readonly name: BackgroundEnum;
                    readonly url: string;
                    readonly type: BackgroundType;
                    readonly isUnlocked: boolean;
                    readonly howToUnlock: HowToUnlock | null;
                }>;
            };
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        };
    };
};
export type DashboardCustomisationButtonThemePickerQuery = {
    readonly response: DashboardCustomisationButtonThemePickerQueryResponse;
    readonly variables: DashboardCustomisationButtonThemePickerQueryVariables;
};



/*
query DashboardCustomisationButtonThemePickerQuery {
  viewer {
    profile {
      __typename
      ... on Student {
        studentPreference {
          availableDashboardBackgrounds {
            name
            url
            type
            isUnlocked
            howToUnlock
          }
          id
        }
      }
      ... on Node {
        __isNode: __typename
        id
      }
      ... on Teacher {
        id
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "DashboardBackground",
  "kind": "LinkedField",
  "name": "availableDashboardBackgrounds",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUnlocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "howToUnlock",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardCustomisationButtonThemePickerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudentPreference",
                    "kind": "LinkedField",
                    "name": "studentPreference",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Student",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardCustomisationButtonThemePickerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudentPreference",
                    "kind": "LinkedField",
                    "name": "studentPreference",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Student",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "Node",
                "abstractKey": "__isNode"
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "Teacher",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "02dce3550c7d28bcd70b94a4c4c167f2",
    "id": null,
    "metadata": {},
    "name": "DashboardCustomisationButtonThemePickerQuery",
    "operationKind": "query",
    "text": "query DashboardCustomisationButtonThemePickerQuery {\n  viewer {\n    profile {\n      __typename\n      ... on Student {\n        studentPreference {\n          availableDashboardBackgrounds {\n            name\n            url\n            type\n            isUnlocked\n            howToUnlock\n          }\n          id\n        }\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n      ... on Teacher {\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f7cd345b747ec8da3fe2aa027cdd475b';
export default node;
