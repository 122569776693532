import { useId } from 'react';

export function LightningBoltSvg({
  style,
  className,
}: {
  style?: React.CSSProperties;
  className?: string;
}) {
  const filterId = useId();
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        d="M14.1336 7.83003L7.63361 15.33C7.5862 15.384 7.52772 15.4271 7.46214 15.4565C7.39657 15.4858 7.32544 15.5007 7.25361 15.5C7.1867 15.4988 7.1206 15.4852 7.05861 15.46C6.96165 15.4193 6.87999 15.3491 6.82524 15.2594C6.77048 15.1696 6.74542 15.0649 6.75361 14.96L6.78861 14.58L7.20861 10H3.75361C3.67431 10.0032 3.5954 9.9875 3.52339 9.95415C3.45137 9.9208 3.38833 9.87079 3.33948 9.80826C3.29062 9.74572 3.25735 9.67245 3.24242 9.59451C3.22749 9.51657 3.23132 9.43619 3.25361 9.36003L5.75361 0.86003C5.78479 0.752809 5.85095 0.659111 5.94157 0.593867C6.03219 0.528623 6.14204 0.495589 6.25361 0.50003H12.2536C12.3373 0.500169 12.4196 0.521298 12.4929 0.561481C12.5663 0.601664 12.6284 0.659616 12.6736 0.73003C12.7229 0.802918 12.7521 0.887534 12.7583 0.975327C12.7644 1.06312 12.7473 1.15098 12.7086 1.23003L10.0336 7.00003H13.7536C13.8492 6.99979 13.9429 7.02699 14.0236 7.07839C14.1042 7.12979 14.1684 7.20324 14.2086 7.29003C14.2497 7.37764 14.2645 7.4753 14.2512 7.57115C14.2379 7.667 14.197 7.75693 14.1336 7.83003Z"
        fill="#F9AA37"
      />
      <g filter={`url(#${filterId})`}>
        <path
          d="M12.6336 7.83003L6.78861 14.575L7.20861 10H3.75361C3.67431 10.0032 3.5954 9.9875 3.52339 9.95415C3.45137 9.9208 3.38833 9.87079 3.33948 9.80826C3.29062 9.74572 3.25735 9.67245 3.24242 9.59451C3.22749 9.51657 3.23132 9.43619 3.25361 9.36003L5.75361 0.86003C5.78478 0.752809 5.85095 0.659111 5.94157 0.593867C6.03219 0.528623 6.14204 0.495589 6.25361 0.50003H10.7536C10.8373 0.500169 10.9196 0.521298 10.9929 0.561481C11.0663 0.601664 11.1284 0.659616 11.1736 0.73003C11.2229 0.802918 11.2521 0.887534 11.2583 0.975327C11.2644 1.06312 11.2473 1.15098 11.2086 1.23003L8.53361 7.00003H12.2536C12.3492 6.99979 12.4429 7.02699 12.5236 7.07839C12.6042 7.12979 12.6684 7.20324 12.7086 7.29003C12.7497 7.37764 12.7645 7.4753 12.7512 7.57115C12.7379 7.667 12.697 7.75693 12.6336 7.83003Z"
          fill="#F3BD01"
        />
      </g>
      <ellipse
        cx="6.7536"
        cy="2.00002"
        rx="0.571429"
        ry="0.571429"
        fill="white"
      />
      <defs>
        <filter
          id={filterId}
          x="3.23349"
          y="0.499634"
          width="9.52245"
          height="18.0754"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.36 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_101_4335"
          />
        </filter>
      </defs>
    </svg>
  );
}
