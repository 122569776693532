/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LanternCheckInType = "ALL_STRANDS_DIAGNOSTIC_CHECKIN" | "HISTORIC_DIAGNOSTIC_CHECKIN" | "HISTORIC_GROWTH_CHECKIN" | "POST_TOPIC_TEST_CHECKIN" | "PRE_TOPIC_TEST_CHECKIN" | "SKILL_CHECKIN" | "STRAND_DIAGNOSTIC_CHECKIN" | "STRAND_GROWTH_CHECKIN" | "SUBSTRAND_GROWTH_CHECKIN";
export type StartSkillCheckInQueryVariables = {
    skillId: string;
};
export type StartSkillCheckInQueryResponse = {
    readonly lantern: {
        readonly skill: {
            readonly title: string;
        } | null;
        readonly viewer: ({
            readonly __typename: "LanternStudent";
            readonly checkIns: ReadonlyArray<{
                readonly id: string;
                readonly type: LanternCheckInType;
            }>;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        }) | null;
    };
};
export type StartSkillCheckInQuery = {
    readonly response: StartSkillCheckInQueryResponse;
    readonly variables: StartSkillCheckInQueryVariables;
};



/*
query StartSkillCheckInQuery(
  $skillId: ID!
) {
  lantern {
    skill(id: $skillId) {
      title
      id
    }
    viewer {
      __typename
      ... on LanternStudent {
        checkIns {
          id
          type
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skillId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "skillId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckIn",
      "kind": "LinkedField",
      "name": "checkIns",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LanternStudent",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StartSkillCheckInQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Skill",
            "kind": "LinkedField",
            "name": "skill",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StartSkillCheckInQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Skill",
            "kind": "LinkedField",
            "name": "skill",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c35305ada117c30c64c60710c621d7f5",
    "id": null,
    "metadata": {},
    "name": "StartSkillCheckInQuery",
    "operationKind": "query",
    "text": "query StartSkillCheckInQuery(\n  $skillId: ID!\n) {\n  lantern {\n    skill(id: $skillId) {\n      title\n      id\n    }\n    viewer {\n      __typename\n      ... on LanternStudent {\n        checkIns {\n          id\n          type\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5eac7670cecf118fde8d53b228f75143';
export default node;
