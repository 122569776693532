import { css } from '@emotion/css';
import { useEffect, useMemo } from 'react';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import ArrowRight from 'ms-components/icons/ArrowRight';
import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import TemplateFolderTitle from 'ms-pages/Teacher/TaskTemplates/views/TemplatesMain/TemplateFolderTitle';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import { Cell, Row } from 'ms-pages/Teacher/components/Table';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import Stack from 'ms-ui-primitives/Stack';
import extractNode from 'ms-utils/relay/extractNode';
import type { SetState } from 'ms-utils/typescript-utils';

import type { AddOrMoveTemplateToFolderModal_school$data } from '../AddOrMoveTemplateToFolderModal/__generated__/AddOrMoveTemplateToFolderModal_school.graphql';
import { ModalTableWrapper } from '../ModalTableComponents';
import type { MoveFolderToFolderModal_school$data } from '../MoveFolderToFolderModal/__generated__/MoveFolderToFolderModal_school.graphql';
import type { SelectAFolderModal_school$data } from '../SelectAFolderModal/__generated__/SelectAFolderModal_school.graphql';
import type {
  Folder,
  NullableFolderId,
  ParentGroupId,
} from '../SelectAFolderModal/types';

const TABLE_WRAPPER_CLASSNAME = 'SELECT-A-FOLDER-TABLE-WRAPPER';

const styles = {
  arrowButtonWrapper: css({
    marginLeft: 'auto',
    marginRight: 8,
  }),
};

type Props = {
  data:
    | SelectAFolderModal_school$data
    | AddOrMoveTemplateToFolderModal_school$data
    | MoveFolderToFolderModal_school$data
    | undefined;
  searchString: string;
  selectedFolderId: NullableFolderId;
  unselectableFolderId?: string | undefined;
  hiddenFolderId?: string | undefined;
  handleFolderClick: (p: Folder) => void;
  onParentGroupChange: (id: ParentGroupId) => void;
  hasNext: boolean;
  nextRef: (element: Element | null) => void;
} & (
  | {
      newlyCreatedFolderId?: never;
      setNewlyCreatedFolderId?: never;
    }
  | {
      newlyCreatedFolderId: NullableFolderId;
      setNewlyCreatedFolderId: SetState<NullableFolderId>;
    }
);

const ModalSelectAFolderTable = ({
  data: school,
  searchString,
  selectedFolderId,
  unselectableFolderId,
  hiddenFolderId,
  handleFolderClick,
  onParentGroupChange,
  hasNext,
  nextRef,
  newlyCreatedFolderId,
  setNewlyCreatedFolderId,
}: Props) => {
  const templateGroups = useMemo(() => {
    return school?.taskTemplatesAndGroups != null
      ? extractNode(school.taskTemplatesAndGroups)
      : [];
  }, [school?.taskTemplatesAndGroups]);

  const filteredTemplateGroups = useMemo(() => {
    return hiddenFolderId != null && hiddenFolderId !== ''
      ? templateGroups.filter(group => {
          if (group.__typename !== 'TaskTemplateGroup') {
            return false;
          }
          const { id, ancestorGroups } = group;
          // We are filtering out the hidden folder and its descendant folders
          const ancestorIds = ancestorGroups.map(({ id }) => id);
          return (
            id !== hiddenFolderId &&
            // If a folder has the hidden folder as an ancestor,
            // then it's a descendant of the hidden folder
            !ancestorIds.includes(hiddenFolderId)
          );
        })
      : templateGroups;
  }, [hiddenFolderId, templateGroups]);

  const hasSearchTerm = searchString !== '';
  const showEmptyState = filteredTemplateGroups.length === 0;
  const noSearchResults = showEmptyState && hasSearchTerm;

  // This selects the newly created folder and scrolls to it if it's out of view
  // after it's been retrieved from the server
  useEffect(() => {
    if (newlyCreatedFolderId == null) return;

    const newlyCreatedFolder = filteredTemplateGroups.find(group => {
      if (group.__typename !== 'TaskTemplateGroup') return false;
      return group.id === newlyCreatedFolderId;
    });

    if (newlyCreatedFolder?.__typename === 'TaskTemplateGroup') {
      const { id, title, ancestorGroups } = newlyCreatedFolder;
      const ancestorIds = ancestorGroups.map(({ id }) => id);
      const ancestorTitles = ancestorGroups.map(({ title }) => title);
      handleFolderClick({
        id,
        title,
        ancestorIds,
        ancestorTitles,
      });
      const row = document.querySelector(`.${getRowClassName(id)}`);
      const tableWrapper = document.querySelector(
        `.${TABLE_WRAPPER_CLASSNAME}`,
      );

      if (row != null && tableWrapper != null) {
        scrollIntoView(row, {
          behavior: 'smooth',
          scrollMode: 'if-needed',
          block: 'center',
          inline: 'center',
          // This is to make sure that we only scroll the table wrapper
          boundary: tableWrapper,
        });
      }

      // We reset the newly created folder id after it's been selected
      setNewlyCreatedFolderId(null);
    }
  }, [
    filteredTemplateGroups,
    handleFolderClick,
    newlyCreatedFolderId,
    setNewlyCreatedFolderId,
  ]);

  return (
    <ModalTableWrapper className={TABLE_WRAPPER_CLASSNAME}>
      {filteredTemplateGroups.length > 0 && (
        <>
          {filteredTemplateGroups.map(group => {
            if (group.__typename !== 'TaskTemplateGroup') {
              return null;
            }
            const {
              id,
              title,
              taskTemplateCount,
              taskTemplateGroupCount,
              isDistrictShared,
              owningSchool,
              ancestorGroups,
            } = group;

            const ancestorIds = ancestorGroups.map(({ id }) => id);
            const ancestorTitles = ancestorGroups.map(({ title }) => title);

            return (
              <Row
                key={id}
                className={getRowClassName(id)}
                height={48}
                onClick={() => {
                  // We show the current folder but as unselectable
                  // User can only navigate to subfolders
                  if (unselectableFolderId !== id) {
                    handleFolderClick({
                      id,
                      title,
                      ancestorIds,
                      ancestorTitles,
                    });
                  }
                }}
                backgroundColor={
                  id === selectedFolderId ? colors.eggplant10 : undefined
                }
              >
                <Cell hasLeftPadding>
                  <Stack.H center style={{ width: '100%' }}>
                    <TemplateFolderTitle
                      id={id}
                      isArchived={false}
                      title={title}
                      templateCount={taskTemplateCount}
                      templateGroupCount={taskTemplateGroupCount}
                      isContentOnly
                      isDistrictShared={isDistrictShared}
                      owningSchoolId={owningSchool?.id}
                      isCurrentFolder={unselectableFolderId === id}
                    />
                    {taskTemplateGroupCount > 0 && (
                      <div className={styles.arrowButtonWrapper}>
                        <Button
                          type="secondary"
                          color="grey90"
                          height={30}
                          isSquare
                          onClick={e => {
                            e.stopPropagation();
                            onParentGroupChange(id);
                          }}
                        >
                          <ArrowRight size={22} />
                        </Button>
                      </div>
                    )}
                  </Stack.H>
                </Cell>
              </Row>
            );
          })}
          {hasNext && (
            <Row>
              <Cell hasBottomBorder={false}>
                <div ref={nextRef} />
                <MinorSpinner />
              </Cell>
            </Row>
          )}
        </>
      )}
      {showEmptyState && (
        <Stack.V center>
          <Stack.Spacer.V height={20} />
          <BodyM bold color="grey10">
            {noSearchResults
              ? `No folders found for "${searchString}"`
              : 'No folders'}
          </BodyM>
          <Stack.Spacer.V height={20} />
        </Stack.V>
      )}
    </ModalTableWrapper>
  );
};

const getRowClassName = (folderId: string) => `FOLDER-ROW-${folderId}`;

export default ModalSelectAFolderTable;
