import styled from '@emotion/styled';
import { useState, useRef, lazy } from 'react';

import { HEADER_SIZE } from 'ms-components/Header';
import { PAGE_PADDING } from 'ms-components/Layout/styles';
import ToolboxModal from 'ms-components/StudentNavbar/components/SunflowerUserMenu/ToolboxModal';
import StudyNotesModal from 'ms-components/StudyNotesModal';
import AccessibilityModeLabel from 'ms-components/accessibility/AccessibilityModeLabel';
import AccessibilityModeWarningModal from 'ms-components/accessibility/AccessibilityModeWarningModal';
import {
  CalculatorPopover,
  type AngleUnit,
  type CalculatorKind,
  type CalculatorPopoverImperativeHandle,
} from 'ms-components/calculators/CalculatorPopover';
import { CrossBold, Hamburger, Toolbox } from 'ms-components/icons';
import Logo from 'ms-components/logos/Logo';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import {
  getStudentSkillsUrl,
  studentSkillsPath,
} from 'ms-pages/Lantern/utils/urls';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import Separator from 'ms-ui-primitives/Separator';
import { HSpacer, HStack } from 'ms-ui-primitives/Stack';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import { useCanAccessNotes } from 'ms-utils/hooks/useCanAccessToolbox';
import {
  getStudentTasksUrl,
  studentTasksPath,
  getStudentDashboardUrl,
  textbooksBasePath,
} from 'ms-utils/urls';

import NavElement from './NavElement';
import NavbarPopover from './NavbarPopover';
import SunflowerUserMenu from './SunflowerUserMenu';

const LOGO_SIZE = 32;

const Navbar = styled.nav({
  display: 'flex',
  height: HEADER_SIZE,
  paddingLeft: PAGE_PADDING / 2,
  justifyContent: 'space-between',
  alignItems: 'stretch',
  borderBottom: `2px solid ${colors.ironLight}`,
});

const LeftHalf = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: 0,
  flex: 1,
});

const RightHalf = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  margin: 0,
});

const GlossaryFlyoutLazy = lazy(() => import('ms-components/GlossaryFlyout'));

type Props = {
  pathname: string;
  userAvatar: string;
  hasDashboard: boolean;
  hasSkills: boolean;
  hasTasks: boolean;
  hasTextbook: boolean;
  hasAccessibilityModeLabel: boolean;
  isOnboarding?: boolean | undefined;
  isMobile?: boolean | undefined;
};

export default function StudentNavBar({
  pathname,
  userAvatar,
  hasDashboard,
  hasSkills,
  hasTasks,
  hasTextbook,
  hasAccessibilityModeLabel,
  isOnboarding = false,
  isMobile = false,
}: Props) {
  const glossaryFlyout = useBoolean();
  const toolboxModal = useBoolean();
  const timeSpentPopoverVisible = useBoolean();
  const pointsPopoverVisible = useBoolean();
  const skillsCollectedPopoverVisible = useBoolean();
  const canAccessNotes = useCanAccessNotes();
  const studyNotesModal = useBoolean();
  const accessibilityModeWarningModal = useBoolean();
  const [calculatorKind, setCalculatorKind] = useState<CalculatorKind | null>(
    null,
  );
  const calculatorPopover = useRef<CalculatorPopoverImperativeHandle>(null);
  const [selectedAngleUnit, setSelectedAngleUnit] =
    useState<AngleUnit>('degree');

  const {
    featureFlags: { hasStudentAppSpa },
  } = useViewer();
  const { trackStructEvent, withTrackStructEvent } = useSnowplow();

  return (
    <Navbar>
      <LeftHalf>
        {!isMobile && (
          <>
            <NavElement
              isLink={hasStudentAppSpa}
              href={getStudentDashboardUrl()}
              selected={false}
              label="Dashboard"
            >
              <Logo width={LOGO_SIZE} />
            </NavElement>

            <Separator size={2} />
          </>
        )}
        <NavLinks
          onToolboxOpen={toolboxModal.setTrue}
          isMobile={isMobile}
          pathname={pathname}
          hasDashboard={hasDashboard}
          hasSkills={hasSkills}
          hasTasks={hasTasks}
          hasTextbook={hasTextbook}
        />
      </LeftHalf>

      <RightHalf>
        {hasAccessibilityModeLabel && !isMobile && (
          <NavElement
            onClick={accessibilityModeWarningModal.setTrue}
            active={accessibilityModeWarningModal.value}
          >
            <AccessibilityModeLabel label="accessibility mode" />
          </NavElement>
        )}

        {!isOnboarding && (
          <>
            <AccessibilityModeWarningModal
              isOpen={accessibilityModeWarningModal.value}
              onClose={accessibilityModeWarningModal.setFalse}
            />
            <NavElement
              onClick={() => {
                timeSpentPopoverVisible.setTrue();
                if (!timeSpentPopoverVisible.value) {
                  trackStructEvent({
                    category: 'student_nav_stats',
                    action: 'clicked_activity_stat',
                  });
                }
              }}
              active={timeSpentPopoverVisible.value}
            >
              <NavbarPopover
                isOpen={timeSpentPopoverVisible.value}
                onClose={timeSpentPopoverVisible.setFalse}
                type="ACTIVITY"
              />
            </NavElement>

            <NavElement
              onClick={() => {
                pointsPopoverVisible.setTrue();
                trackStructEvent({
                  category: 'gamification',
                  action: 'clicked_points_widget',
                });
              }}
            >
              <NavbarPopover
                isOpen={pointsPopoverVisible.value}
                onClose={pointsPopoverVisible.setFalse}
                type="POINTS"
              />
            </NavElement>

            <NavElement
              onClick={withTrackStructEvent(
                skillsCollectedPopoverVisible.setTrue,
                {
                  category: 'student_nav_stats',
                  action: 'clicked_skills_stat',
                },
              )}
              active={skillsCollectedPopoverVisible.value}
            >
              <NavbarPopover
                isOpen={skillsCollectedPopoverVisible.value}
                onClose={skillsCollectedPopoverVisible.setFalse}
                type="SKILLS"
              />
            </NavElement>
            {glossaryFlyout.value && (
              <GlossaryFlyoutLazy onClose={glossaryFlyout.setFalse} />
            )}
            {calculatorKind !== null && (
              <CalculatorPopover
                key={calculatorKind}
                ref={calculatorPopover}
                kind={calculatorKind}
                angleUnit={selectedAngleUnit}
                onChangeCalculator={setCalculatorKind}
                onChangeAngleUnit={setSelectedAngleUnit}
                onClose={() => {
                  setCalculatorKind(null);
                }}
              />
            )}
            {canAccessNotes && (
              <StudyNotesModal
                isOpen={studyNotesModal.value}
                onClose={studyNotesModal.setFalse}
                subtopicId={null}
                jsxContentType={null}
                locale={null}
              />
            )}
          </>
        )}
        <SunflowerUserMenu
          renderMode={isMobile ? 'fullscreen' : 'popover'}
          avatar={userAvatar}
          isOnboarding={isOnboarding}
          onToolboxClick={toolboxModal.setTrue}
        />
      </RightHalf>
      <ToolboxModal
        isOpen={toolboxModal.value}
        selectedCalculator={calculatorKind}
        onClose={toolboxModal.setFalse}
        onGlossaryOpen={glossaryFlyout.setTrue}
        onCalculatorOpen={_calculatorKind => {
          if (_calculatorKind === calculatorKind) {
            const popover = calculatorPopover.current;
            if (popover !== null) popover.resetLayout();
          } else {
            setCalculatorKind(_calculatorKind);
          }
        }}
        onStudyNotesOpen={studyNotesModal.setTrue}
      />
    </Navbar>
  );
}

/**
 * Will render the navigation links or the hamburger menu that opens the navigation links
 * based on the `isMobile` prop
 */
function NavLinks({
  isMobile,
  pathname,
  hasDashboard,
  hasSkills,
  hasTasks,
  hasTextbook,
  onToolboxOpen,
}: {
  isMobile: boolean;
  pathname: string;
  hasDashboard: boolean;
  hasSkills: boolean;
  hasTasks: boolean;
  hasTextbook: boolean;
  onToolboxOpen: () => void;
}) {
  const {
    featureFlags: { hasStudentAppSpa },
  } = useViewer();

  const elements = (
    <>
      {hasDashboard && (
        <NavElement
          selected={pathname === '/student/'}
          href={getStudentDashboardUrl()}
          isLink={hasStudentAppSpa}
          activeStyle={isMobile ? 'highlight' : 'overline'}
          activeColor={colors.eggplant}
        >
          Dashboard
        </NavElement>
      )}

      {hasTasks && (
        <NavElement
          selected={pathname.includes(studentTasksPath)}
          href={getStudentTasksUrl()}
          isLink={hasStudentAppSpa}
          activeStyle={isMobile ? 'highlight' : 'overline'}
          activeColor={colors.eggplant}
        >
          Assigned
        </NavElement>
      )}

      {hasSkills && (
        <NavElement
          selected={pathname.includes(studentSkillsPath)}
          href={getStudentSkillsUrl({})}
          isLink={hasStudentAppSpa}
          activeStyle={isMobile ? 'highlight' : 'overline'}
          activeColor={colors.eggplant}
        >
          Skills
        </NavElement>
      )}

      {hasTextbook && (
        <NavElement
          href={textbooksBasePath}
          isLink={hasStudentAppSpa}
          selected={pathname.includes('textbooks')}
          activeStyle={isMobile ? 'highlight' : 'overline'}
          activeColor={colors.eggplant}
        >
          Textbook
        </NavElement>
      )}
      {isMobile && (
        <NavElement
          activeStyle={isMobile ? 'highlight' : 'overline'}
          activeColor={colors.eggplant}
          onClick={() => {
            onToolboxOpen();
            menuOpen.setFalse();
          }}
        >
          <Toolbox />
          <HSpacer width={8} />
          Toolbox
        </NavElement>
      )}
    </>
  );

  const menuOpen = useBoolean();

  if (isMobile) {
    return (
      <>
        <Button
          color="grey90"
          onClick={menuOpen.toggle}
          label="navigation links"
        >
          <Hamburger size={24} />
        </Button>
        {menuOpen.value && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'white',
              zIndex: 20,
              padding: 24,
            }}
          >
            <HStack justify="end">
              <Button
                onClick={menuOpen.setFalse}
                color="grey90"
                label="close navigation links"
              >
                Close
                <HSpacer width={8} />
                <CrossBold size={14} />
              </Button>
            </HStack>
            <div>{elements}</div>
          </div>
        )}
      </>
    );
  } else {
    return elements;
  }
}
