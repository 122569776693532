import { css, cx, keyframes } from '@emotion/css';
import { memo } from 'react';

import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';

const WIDTH = 50 * BASE_UNIT;
const WIDTH_COMPACT = 88.4;
const HEIGHT = 5 * BASE_UNIT;
const ANIMATION_DURATION = 3000; // milliseconds

const opacityAnimation = keyframes`
  0% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
`;

const styles = {
  root: css({
    width: '100%',
    height: '100%',
    padding: BASE_UNIT * 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  noPadding: css({
    padding: 0,
  }),
  dot: css({
    animationName: opacityAnimation,
    animationDuration: `${ANIMATION_DURATION}ms`,
    animationIterationCount: 'infinite',
  }),
  firstDot: css({
    animationDelay: '500ms',
  }),
  secondDot: css({
    animationDelay: '700ms',
  }),
  thirdDot: css({
    animationDelay: '900ms',
  }),
};

type Props = {
  scale?: number;
  color?: string;
  noPadding?: boolean;
  isCompact?: boolean;
  className?: string;
};

const MinorSpinner = ({
  scale = 1,
  color = colors.grayChateau,
  noPadding = false,
  // The legacy path is wider than necessary,
  // even if you don't assign any paddings,
  // it has a lot of empty space around it.
  // That makes it harder to use this spinner in a small spaces.
  // The new path is trimmed down version in width to the minimum necessary.
  // Enable noPadding and isCompact props
  // to get a spinner without any paddings or empty space around it.
  isCompact = false,
  className,
}: Props) => {
  const width = isCompact ? WIDTH_COMPACT : WIDTH;

  return (
    <div
      className={cx(styles.root, noPadding && styles.noPadding, className)}
      style={{ color }}
      aria-hidden="true"
    >
      <svg
        width={width * scale}
        height={HEIGHT * scale}
        viewBox={`0 0 ${width} ${HEIGHT}`}
      >
        <circle
          className={cx(styles.dot, styles.firstDot)}
          fill="currentColor"
          style={{ opacity: 0.1 }}
          r="8"
          cx={isCompact ? 12.2 : 68}
          cy="10"
        />
        <circle
          className={cx(styles.dot, styles.secondDot)}
          fill="currentColor"
          style={{ opacity: 0.1 }}
          r="8"
          cx={isCompact ? 44.2 : 100}
          cy="10"
        />
        <circle
          className={cx(styles.dot, styles.thirdDot)}
          fill="currentColor"
          style={{ opacity: 0.1 }}
          r="8"
          cx={isCompact ? 76.2 : 132}
          cy="10"
        />
      </svg>
    </div>
  );
};

export default memo(MinorSpinner);
